import { TimeOffTemplateItem } from '@services/timeoff.services'
import dayjs from 'dayjs'
import { Direction } from './common.constants'

export const TIMEOFF_MINIMUM_MINUTE = 30
export const TIMEOFF_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm'

export enum TIME_OFF_TEMPLATE_PAY_STATUS {
  PRE_PAID = 'pre_paid',
  POST_PAID = 'post_paid',
  NON_PAID = 'non_paid',
}

export enum TIME_OFF_TEMPLATE_RANGE_TYPE {
  TIME = 'time',
  HALF_DAY_AM = 'half_day_am',
  HALF_DAY_PM = 'half_day_pm',
  DAY = 'day',
}

export const TimeOffTemplatePayStatuses: {
  id: string
  name: string
}[] = [
  {
    id: TIME_OFF_TEMPLATE_PAY_STATUS.PRE_PAID,
    name: 'TIMEOFF_TEMPLATE.pay_status_pre',
  },
  {
    id: TIME_OFF_TEMPLATE_PAY_STATUS.POST_PAID,
    name: 'TIMEOFF_TEMPLATE.pay_status_post',
  },
  {
    id: TIME_OFF_TEMPLATE_PAY_STATUS.NON_PAID,
    name: 'TIMEOFF_TEMPLATE.pay_status_non_paid',
  },
]

export const TIME_OFF_TEMPLATE_PAY_STATUS_LABELS: any = {
  [`${TIME_OFF_TEMPLATE_PAY_STATUS.PRE_PAID}`]: {
    color: 'warning',
    label: 'TIMEOFF_TEMPLATE.pay_status_pre',
  },
  [`${TIME_OFF_TEMPLATE_PAY_STATUS.POST_PAID}`]: {
    color: 'info',
    label: 'TIMEOFF_TEMPLATE.pay_status_post',
  },
  [`${TIME_OFF_TEMPLATE_PAY_STATUS.NON_PAID}`]: {
    color: 'error',
    label: 'TIMEOFF_TEMPLATE.pay_status_non_paid',
  },
}

export const TimeOffTemplateRangeTypes: {
  id: string
  name: string
}[] = [
  {
    id: TIME_OFF_TEMPLATE_RANGE_TYPE.TIME,
    name: 'TIMEOFF_TEMPLATE.range_type_time',
  },
  {
    id: TIME_OFF_TEMPLATE_RANGE_TYPE.HALF_DAY_AM,
    name: 'TIMEOFF_TEMPLATE.range_type_am',
  },
  {
    id: TIME_OFF_TEMPLATE_RANGE_TYPE.HALF_DAY_PM,
    name: 'TIMEOFF_TEMPLATE.range_type_pm',
  },
  {
    id: TIME_OFF_TEMPLATE_RANGE_TYPE.DAY,
    name: 'TIMEOFF_TEMPLATE.range_type_day',
  },
]

export enum TIME_OFF_REQUEST_TYPE {
  PRE = 'pre',
  POST = 'post',
}

export enum TIME_OFF_REQUEST_STATUS {
  PENDING = 'pending',
  REJECTED = 'rejected',
  ACCEPTED = 'accepted',
  DELETED = 'deleted',
}

export const TIME_OFF_REQUEST_STATUS_LABELS: any = {
  [`${TIME_OFF_REQUEST_STATUS.PENDING}`]: {
    color: 'warning',
    dotColor: 'warning',
    label: 'TIMEOFF_REQUEST.status_pending',
  },
  [`${TIME_OFF_REQUEST_STATUS.REJECTED}`]: {
    color: 'error',
    dotColor: 'error',
    label: 'TIMEOFF_REQUEST.status_rejected',
  },
  [`${TIME_OFF_REQUEST_STATUS.ACCEPTED}`]: {
    color: 'success',
    dotColor: 'success',
    label: 'TIMEOFF_REQUEST.status_accepted',
  },
  [`${TIME_OFF_REQUEST_STATUS.DELETED}`]: {
    color: 'default',
    dotColor: 'grey',
    label: 'TIMEOFF_REQUEST.status_deleted',
  },
}

export const timeOffRequestStatuses: {
  id: string
  name: string
}[] = [
  {
    id: TIME_OFF_REQUEST_STATUS.PENDING,
    name: 'TIMEOFF_REQUEST.status_pending',
  },
  {
    id: TIME_OFF_REQUEST_STATUS.REJECTED,
    name: 'TIMEOFF_REQUEST.status_rejected',
  },
  {
    id: TIME_OFF_REQUEST_STATUS.ACCEPTED,
    name: 'TIMEOFF_REQUEST.status_accepted',
  },
  {
    id: TIME_OFF_REQUEST_STATUS.DELETED,
    name: 'TIMEOFF_REQUEST.status_deleted',
  },
]

export enum TIME_OFF_IDENTIFIER {
  MY_TIME = 'my_time',
  OTHER = 'other',
  HOLIDAY_WORK = 'holiday_work', //Амралтын өдөр ажиллах (Хагас, бүтэнсайн өдөр)
  WORKATION = 'workation', //Ээлжийн амралттай байх үедээ ажиллах
  COMPENSATORY_REQUEST = 'compensatory_request', //Нөхөн амрах хүсэлт
  SPECIAL = 'special',
  WORK_START_MIN = 'work_start_min',
  WORK_START_MAX = 'work_start_max',
  MAX_MY_TIME_DURATION = 'max_my_time_duration',
}

export const timeOffIdentifiers: {
  id: string
  name: string
}[] = [
  {
    id: TIME_OFF_IDENTIFIER.OTHER,
    name: 'TIMEOFF_TEMPLATE.identifier_other',
  },
  {
    id: TIME_OFF_IDENTIFIER.MY_TIME,
    name: 'TIMEOFF_TEMPLATE.identifier_mytime',
  },
  {
    id: TIME_OFF_IDENTIFIER.HOLIDAY_WORK,
    name: 'TIMEOFF_TEMPLATE.identifier_holidaywork',
  },
  {
    id: TIME_OFF_IDENTIFIER.WORKATION,
    name: 'TIMEOFF_TEMPLATE.identifier_workation',
  },
  {
    id: TIME_OFF_IDENTIFIER.COMPENSATORY_REQUEST,
    name: 'TIMEOFF_TEMPLATE.identifier_compensatory_request',
  },
  {
    id: TIME_OFF_IDENTIFIER.SPECIAL,
    name: 'TIMEOFF_TEMPLATE.special',
  },
]

export const TimeOffListDefaultFilter = {
  keyword: '',
  order_by: '',
  direction: Direction.DESC,
  per_page: '10',
  page: '1',
  timeoff_templates: [],
  action_by_me: false,
  status: [
    TIME_OFF_REQUEST_STATUS.PENDING,
    TIME_OFF_REQUEST_STATUS.ACCEPTED,
    TIME_OFF_REQUEST_STATUS.REJECTED,
  ],
  start_date: null,
  end_date: null,
}

export const TimeOffApproveListDefaultFilter = {
  ...TimeOffListDefaultFilter,
  status: [TIME_OFF_REQUEST_STATUS.PENDING as string],
}

export type TimeOffListFilter = {
  keyword: string
  order_by: string
  direction: string
  per_page: string
  page: string
  timeoff_templates: TimeOffTemplateItem[]
  status: string[]
  start_date: string
  end_date: string
  action_by_me: boolean
}

export const TIMESHEET_DAY_LABELS: any = {
  0: { label: 'TIMESHEET.sunday' },
  1: { label: 'TIMESHEET.monday' },
  2: { label: 'TIMESHEET.tuesday' },
  3: { label: 'TIMESHEET.wednesday' },
  4: { label: 'TIMESHEET.thursday' },
  5: { label: 'TIMESHEET.friday' },
  6: { label: 'TIMESHEET.saturday' },
}

export const QuitUserReasonDefaultFilter = {
  keyword: '',
  order_by: '',
  direction: Direction.ASC,
  per_page: '10',
  page: '1',
  start_date: dayjs(new Date()).subtract(1, 'month'),
  end_date: dayjs(new Date()),
}

export const InfoLogDefaultFilter = {
  start_date: dayjs(new Date()).subtract(1, 'month'),
  end_date: dayjs(new Date()),
  keyword: '',
  order_by: '',
  direction: Direction.ASC,
  per_page: '10',
  page: '1',
}

export type InfoLogParams = {
  start_date?: string
  end_date?: string
  keyword: string
  order_by: string
  direction: Direction
  per_page: string
  page: string
}

export const TimeOffDefaultFilter = {
  keyword: '',
  order_by: '',
  direction: Direction.ASC,
  per_page: '10',
  page: '1',
  start_at: dayjs(new Date()).subtract(1, 'month').startOf('month'),
  end_at: dayjs(new Date()),
}
