import { Box } from '@mui/material'
import { CellTemplates, ReactGrid, Row } from '@silevis/reactgrid'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { RemoteOfficeAttributes } from '@services/time.services'
import { useRect } from '@reach/rect'
import { truncateDecimals } from '@utils/helper/common.helper'
import { PercentReportCellTemplate } from './Cell/PercentReportCellTemplate'
import { SkillEmployeeCellTemplate } from './Cell/SkillEmployeeCellTemplate'
import _ from 'lodash'
export const TIME_REPORT_GRID = {
  rowHeight: 28,
  headerHeight: 70,
  employeeWidth: 300,
  columnWidth: 100,
  defualtWidth: 140,
}

interface TimeReportDataGrid {
  remoteReportList: RemoteOfficeAttributes[]
  totalDays: number
}

const contentRef = React.createRef<HTMLDivElement>()

const RemoteOfficeDataGrid: React.FC<TimeReportDataGrid> = ({
  remoteReportList,
  totalDays,
}) => {
  const { t } = useTranslation()

  const calculatePercentage = (count: number): string => {
    if (totalDays === 0) return '0%'
    const percent = (count * 100) / totalDays
    return `${truncateDecimals(percent, 0)}%`
  }
  const contentRect = useRect(contentRef)
  const totalWidth =
    contentRect && contentRect?.width
      ? contentRect.width - 3
      : contentRect?.width

  const fixedTotalWidth = TIME_REPORT_GRID.columnWidth * 7
  const colWidth =
    totalWidth && totalWidth > fixedTotalWidth
      ? Math.round((totalWidth - TIME_REPORT_GRID.employeeWidth) / 6)
      : TIME_REPORT_GRID.defualtWidth

  const headerRow: Row = {
    rowId: 'header',
    height: TIME_REPORT_GRID.headerHeight,
    cells: [
      {
        type: 'header',
        text: `${t('TIME_REPORT.employee')}`,
        className: 'time-col',
      },
      {
        type: 'header',
        text: `${t('TIME_REPORT.remote_work')}`,
        className: 'time-col',
      },
      {
        type: 'header',
        text: `${t('TIME_REPORT.office_work')}`,
        className: 'time-col',
      },
      {
        type: 'header',
        text: `${t('TIME_REPORT.timeOff')}`,
        className: 'time-col',
      },
      {
        type: 'header',
        text: `${t('TIME_REPORT.days_off_work')}`,
        className: 'time-col',
      },
      {
        type: 'header',
        text: `${t('TIME_REPORT.remote_without_approve')}`,
        className: 'time-col',
      },
      {
        type: 'header',
        text: `${t('TIME_REPORT.insufficient_office')}`,
        className: 'time-col',
      },
    ],
  }

  const totalRow: Row = {
    rowId: 'total-row',
    ...(remoteReportList && {
      cells: [
        {
          type: 'text',
          className: 'time-col',
          text: `${t('TIME_REPORT.total')}`,
        },
        {
          type: 'text',
          className: 'time-col',
          text: String(_.sumBy(remoteReportList, 'attributes.remote_count')),
        },
        {
          type: 'text',
          className: 'time-col',
          text: String(_.sumBy(remoteReportList, 'attributes.office_count')),
        },
        {
          type: 'text',
          className: 'time-col',
          text: String(_.sumBy(remoteReportList, 'attributes.timeoff_days')),
        },
        {
          type: 'text',
          className: 'time-col',
          text: String(
            _.sumBy(remoteReportList, 'attributes.unauthorized_absence')
          ),
        },
        {
          type: 'text',
          className: 'time-col',
          text: String(
            _.sumBy(remoteReportList, 'attributes.remote_without_approve')
          ),
        },
        {
          type: 'text',
          className: 'time-col',
          text: String(
            _.sumBy(remoteReportList, 'attributes.insufficient_office_count')
          ),
        },
      ],
    }),
  }
  const getRows = (): Row[] => [
    headerRow,
    ...remoteReportList.map<Row>((remoteOfficeItem, index) => ({
      rowId: index,
      height: TIME_REPORT_GRID.rowHeight,
      cells: [
        {
          type: 'info',
          className: 'employee-cell',
          text: {
            firstname_en:
              remoteOfficeItem.attributes.user.attributes.firstname_en,
            lastname_en:
              remoteOfficeItem.attributes.user.attributes.lastname_en,
            user_code: remoteOfficeItem.attributes.user.attributes.user_code,
            id: remoteOfficeItem.attributes.user.id,
            position: {
              id: remoteOfficeItem.attributes.user.attributes.position.data.id,
              name: remoteOfficeItem.attributes.user.attributes.position.data
                .attributes.name,
              short_name:
                remoteOfficeItem.attributes.user.attributes.position.data
                  .attributes?.short_name,
              display_order:
                remoteOfficeItem.attributes.user.attributes.position.data
                  .attributes?.display_order,
            },
          },
          tooltip: '',
        },
        {
          type: 'countData',
          className: 'time-col',
          text: {
            count: String(remoteOfficeItem.attributes.remote_count),
            percent: calculatePercentage(
              remoteOfficeItem.attributes.remote_count
            ),
          },
        },
        {
          type: 'countData',
          className: 'time-col',
          text: {
            count: String(remoteOfficeItem.attributes.office_count),
            percent: calculatePercentage(
              remoteOfficeItem.attributes.office_count
            ),
          },
        },
        {
          type: 'countData',
          className: 'time-col',
          text: {
            count: String(remoteOfficeItem.attributes.timeoff_days),
            percent: calculatePercentage(
              remoteOfficeItem.attributes.timeoff_days
            ),
          },
        },
        {
          type: 'countData',
          className: 'time-col',
          text: {
            count: String(remoteOfficeItem.attributes.unauthorized_absence),
            percent: calculatePercentage(
              remoteOfficeItem.attributes.unauthorized_absence
            ),
          },
        },
        {
          type: 'header',
          className: 'time-col',
          text: String(remoteOfficeItem.attributes.remote_without_approve),
        },
        {
          type: 'header',
          className: 'time-col',
          text: String(remoteOfficeItem.attributes.insufficient_office_count),
        },
      ] as any,
    })),
    totalRow,
  ]

  const columns = [
    { columnId: 'employee', width: TIME_REPORT_GRID.employeeWidth },
    { columnId: 'remote-work', width: colWidth },
    { columnId: 'office-work', width: colWidth },
    { columnId: 'time-off', width: colWidth },
    { columnId: 'unauthorized_absense', width: colWidth },
    { columnId: 'remote-difference', width: colWidth },
    {
      columnId: 'insufficient-office-count',
      width: colWidth,
    },
  ] as any

  const rows = getRows()
  const myCellTemplates: CellTemplates = {
    countData: new PercentReportCellTemplate(),
    info: new SkillEmployeeCellTemplate(),
  }
  return (
    <Box sx={{ position: 'relative' }} ref={contentRef} id="my-grid">
      <ReactGrid
        columns={columns}
        rows={rows}
        stickyLeftColumns={1}
        stickyTopRows={1}
        stickyBottomRows={1}
        enableRowSelection
        customCellTemplates={myCellTemplates}
        horizontalStickyBreakpoint={0}
      />
    </Box>
  )
}

export default RemoteOfficeDataGrid
