import TableLayout from '@components/@material-extend/TableLayout'
import SearchFilter, { SearchParams } from '@components/SearchFilter'
import ListSettings from '@components/SearchFilter/ListSettings'
import {
  DefaultPaging,
  FilterParams,
  ListName,
} from '@constants/common.constants'
import { MODULES } from '@constants/modules.constants'
import useTimeOffRequest from '@containers/@modules/TimeOffRequest/useTimeOffRequest'
import PermissionWrapper from '@containers/PermissionWrapper'
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DetailPageLayout from '@layouts/ContentLayout/DetailPageLayout'
import { InfoOutlined, Settings } from '@mui/icons-material'
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Pagination,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import { UserCompensatoryItem } from '@services/timeoff.services'
import { formatDate, getShortName } from '@utils/helper/common.helper'
import { usePageSettings } from '@utils/hooks/usePageSettings'
import _ from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import UserCompensatoryEditForm from './UserCompensatoryEditForm'
import dayjs from 'dayjs'
import HelpContent from '@components/HelpContent'
import { HelpPage, lang } from '@constants/help.constants'
import i18next from 'i18next'

const CompensatoryDaysPage = () => {
  const { t } = useTranslation()
  const breadcrumbs = [
    {
      title: t('SYSCOMMON.dashboard'),
      to: '/',
    },
    {
      title: t('COMPENSATORY_DAYS.title'),
    },
  ]
  const { filter, setFilter } = usePageSettings(ListName.UserCompensatory)
  const [panel, setOpenPanel] = useState<boolean>(false)
  const isFirstMount = useRef(true)
  const [detail, setDetail] = useState<UserCompensatoryItem | null>(null)
  const [init, setInit] = useState<boolean>(false)

  const {
    fetchUserCompensatoryDays,
    userCompensatoryList,
    userCompensatoryListMeta,
    userCompensatoryListPaging,
    updateCompensatoryDaysMeta,
    updateUserCompensatory,
  } = useTimeOffRequest()

  useEffect(() => {
    if (isFirstMount.current) {
      isFirstMount.current = false // Set to false for subsequent updates
      setFilter({ ...filter, page: DefaultPaging.page })
    } else {
      fetchUserCompensatoryDays(filter)
        .unwrap()
        .then(() => {
          setInit(true)
        })
        .catch(() => {
          setInit(true)
        })
    }
  }, [filter])

  const paginationHandler = (
    _event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setFilter({ ...filter, page: page.toString() })
  }

  const handleSettings = (data: Omit<FilterParams, 'keyword'>) => {
    setFilter({ ...data, keyword: filter.keyword, page: DefaultPaging.page })
  }

  const renderRow = (item: UserCompensatoryItem, isDisabled: boolean) => {
    const attr = item.attributes.user.data.attributes
    return (
      <TableRow
        key={item.id}
        selected={item.id === detail?.id}
        sx={{ '&:hover': { backgroundColor: 'rgba(71,82,89,.07)' } }}
      >
        <TableCell
          align="left"
          sx={{
            pr: '4px !important',
            '&:hover': {
              cursor: 'pointer',
              color: (theme) => theme.palette.secondary.main,
            },
          }}
        >
          <Avatar
            data-test-id="user-profile"
            imgProps={{ loading: 'lazy', width: 150, height: 150 }}
            alt={`${attr.firstname_en} ${attr.lastname_en}`}
            src={
              _.get(
                attr,
                'avatar_thumbnail',
                '/images/default-150x150.png'
              ) as any
            }
            sx={{
              width: 45,
              height: 45,
              border: '2px solid #fff',
              boxShadow: 2,
              background: '#eee',
            }}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{
            px: '8px !important',
            '&:hover': {
              cursor: 'pointer',
              color: (theme) => theme.palette.secondary.main,
            },
          }}
        >
          <Typography sx={{ maxWidth: '170px' }} noWrap>
            {getShortName(_.get(item, 'attributes.user.data'))}
          </Typography>
        </TableCell>
        <TableCell
          align="center"
          sx={{
            px: '8px !important',
            '&:hover': {
              cursor: 'pointer',
              color: (theme) => theme.palette.secondary.main,
            },
          }}
        >
          {item.attributes.earned_day}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            px: '8px !important',
            '&:hover': {
              cursor: 'pointer',
              color: (theme) => theme.palette.secondary.main,
            },
          }}
        >
          {item.attributes.remaining_day}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            px: '8px !important',
            '&:hover': {
              cursor: 'pointer',
              color: (theme) => theme.palette.secondary.main,
            },
          }}
        >
          {formatDate(item.attributes.created_at)}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            px: '8px !important',
            '&:hover': {
              cursor: 'pointer',
              color: (theme) => theme.palette.secondary.main,
            },
          }}
        >
          {formatDate(item.attributes.expire_at)}
        </TableCell>
        <TableCell
          align="left"
          sx={{
            px: '8px !important',
            '&:hover': {
              cursor: 'pointer',
              color: (theme) => theme.palette.secondary.main,
            },
          }}
        >
          {item.attributes.timeoff_name}
        </TableCell>
        <TableCell align="center">
          <ButtonGroup color="secondary" size="medium" variant="text">
            <Button
              data-test-id="edit"
              onClick={() => {
                setDetail(item)
              }}
            >
              {isDisabled ? (
                <InfoOutlined />
              ) : (
                <FontAwesomeIcon size={'lg'} icon={faPenToSquare} />
              )}
            </Button>
          </ButtonGroup>
        </TableCell>
      </TableRow>
    )
  }

  const onSubmitUpdate = (values: any) => {
    if (values && detail) {
      updateUserCompensatory({
        id: detail.id,
        params: {
          expire_at: dayjs(values.expire_at).toDate(),
          comment: values.comment,
        },
      })
        .unwrap()
        .then(() => {
          fetchUserCompensatoryDays(filter)
          setDetail(null)
        })
        .catch(() => null)
    }
  }

  return (
    <PermissionWrapper
      module={MODULES.TIME_SETTINGS}
      readBehavior="disabled"
      render={({ isDisabled }) => (
        <DetailPageLayout
          breadcrumbs={breadcrumbs}
          title={t('COMPENSATORY_DAYS.title')}
          total={userCompensatoryListPaging?.total_count}
          sideContent={{
            title: t('SYSCOMMON.details'),
            content: (
              <UserCompensatoryEditForm
                meta={updateCompensatoryDaysMeta}
                onSubmitUpdate={onSubmitUpdate}
                item={detail}
                isDisabled={isDisabled}
              />
            ),
          }}
          helpContent={
            <HelpContent
              id={HelpPage.compensatory_days[`${i18next.language as lang}`]}
            />
          }
          side={detail ? true : false}
          onClose={() => setDetail(null)}
        >
          <TableLayout
            isLoading={userCompensatoryListMeta.pending}
            data={userCompensatoryList}
            minWidth={300}
            renderRow={(data) => renderRow(data, isDisabled)}
            pagination={
              init &&
              userCompensatoryListPaging && (
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Pagination
                    page={userCompensatoryListPaging?.current_page}
                    count={userCompensatoryListPaging?.total_pages}
                    onChange={paginationHandler}
                  />
                  <Box>
                    <IconButton
                      data-test-id="settings-button"
                      onClick={() => setOpenPanel(true)}
                    >
                      <Settings />
                    </IconButton>
                    <ListSettings
                      filter={filter}
                      orderParams={[]}
                      open={panel}
                      onChangeSettings={handleSettings}
                      handleClose={() => setOpenPanel(false)}
                    />
                  </Box>
                </Stack>
              )
            }
            filter={
              <SearchFilter
                filter={filter}
                onFilter={(params: SearchParams) => {
                  setFilter({
                    ...filter,
                    keyword: params.keyword,
                    page: DefaultPaging.page,
                  })
                }}
              />
            }
            dataLoading={{
              isSearchResult: !_.isEmpty(filter.keyword),
              isLoading: false,
            }}
            head={
              <TableRow>
                <TableCell
                  width={40}
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  align="left"
                ></TableCell>
                <TableCell
                  width={80}
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  align="left"
                >
                  {t('TIMESHEET.employee')}
                </TableCell>
                <TableCell
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  align="center"
                >
                  {t('COMPENSATORY_DAYS.earned_days')}
                </TableCell>
                <TableCell
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  align="center"
                >
                  {t('COMPENSATORY_DAYS.remaining_days')}
                </TableCell>
                <TableCell
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  align="center"
                >
                  {t('COMPENSATORY_DAYS.created_date')}
                </TableCell>
                <TableCell
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  align="center"
                >
                  {t('COMPENSATORY_DAYS.expire_date')}
                </TableCell>
                <TableCell
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  align="left"
                >
                  {t('SYSCOMMON.time_off')}
                </TableCell>
                <TableCell
                  width={96}
                  sx={{
                    whiteSpace: 'noWrap',
                  }}
                  align="center"
                >
                  {t('SYSCOMMON.actions')}
                </TableCell>
              </TableRow>
            }
          />
        </DetailPageLayout>
      )}
    />
  )
}
export default CompensatoryDaysPage
