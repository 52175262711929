// Монгол
export default {
  SYSCOMMON: {
    number_no: '№',
    yes: 'Тийм',
    no: 'Үгүй',
    apply: 'Тохируулах',
    confirm: 'OK',
    cancel: 'Болих',
    save: 'Хадгалах',
    dashboard: 'Хянах самбар',
    project_management: 'Төслийн менежмент',
    task_management: 'Task Management',
    projects: 'Төсөл',
    gantt_chart: 'Gantt chart',
    sprint: 'Sprint',
    tasks: 'Tasks',
    kanban_board: 'Kanban Board',
    time_management: 'Цагийн менежмент',
    time_requests: 'Time requests',
    human_resource: 'Хүний нөөц',
    resource_management: 'Human resource',
    skill_management: 'Skill Management',
    welcome: 'Тавтай морилно уу!',
    milestone: 'Milestone',
    timesheet: 'Цаг бүртгэл',
    teamwork: 'Team Work',
    members: 'Гишүүд',
    employees: 'Ажилчид',
    notifications: 'Мэдэгдэл',
    view_all: 'Бүгдийг харах',
    im_not_robot: 'Би робот биш',
    partner_company: 'Түнш компани',
    create: 'Үүсгэх',
    name: 'Нэр',
    description: 'Тайлбар',
    back: 'Буцах',
    search: 'Хайх',
    delete: 'Устгах',
    add: 'Нэмэх',
    edit: 'Засах',
    manage: 'Удирдах',
    project_list: 'Төслийн жагсаалт',
    start_date: 'Эхлэх огноо',
    end_date: 'Дуусах огноо',
    image: 'зураг',
    role: 'Хандах эрх',
    member: 'Гишүүд',
    settings: 'Тохиргоо',
    update: 'Өөрчлөх',
    actions: 'Үйлдлүүд',
    delete_modal_title: 'Итгэлтэй байна уу?',
    write: 'Бичих',
    read: 'Харах',
    no_data: 'Хоосон байна',
    something_went_wrong: 'Something went wrong',
    additional_confirm_label:
      'Энэ үйлдлийг баталгаажуулахын тулд {{confirm}} гэж бичнэ үү.',
    search_keyword: 'Хайх',
    filter_confirm: 'Сонгох',
    id: 'ID',
    back_to_list: 'Буцах',
    no_resources: '{{resources}} байхгүй ',
    no_matches: 'Таарахгүй байна',
    loading_resource: 'Ачаалж байна',
    data: 'өгөгдөл',
    clear: 'Арилгах',
    cant_find: 'Тохирох үр дүн олдсонгүй',
    copyright: 'Copyright TeamSync® ©',
    copy: 'Хуулах',
    copied: 'Хуулсан',
    date: 'Огноо',
    company: 'Компани',
    gender_male: 'Эр',
    gender_female: 'Эм',
    gender_other: 'Бусад',
    back_confirm_title: 'Хадгалахаас өмнө гарвал таны өөрчлөлт устах болно.',
    back_confirm_desc: 'Хадгалахаас өмнө гарвал таны өөрчлөлт устах болно.',
    not_found_code: '404',
    user_data:
      ' Хэрэглэгчийн мэдээлэл эвдэрсэн байна. Тусламж авахын тулд админтай холбогдоно уу.',
    contact_admin: 'Админд хандана уу',
    failed_to_init_app: 'Программыг эхлүүлж чадсангүй',
    sign_out_to_home: 'Системээс гараад эхлэл рүү буцах',
    permission_denied_code: '403',
    denied_title: 'Эрхгүй байна',
    denied_desc: 'Та энэ хуудсанд хандах эрхгүй байна',
    not_found_title: 'Хуудас олдсонгүй',
    not_found_desc: ' Таны хайж буй хуудас байхгүй эсвэл зөөсөн байна.',
    not_available_code: '503',
    not_available_title: 'Service is currently not available',
    go_to_home: 'Эхлэл рүү буцах',
    details: 'Дэлгэрэнгүй',
    calendar: 'Календар',
    events: 'Үйл явдал',
    list: 'Жагсаалт',
    categories: 'Категори',
    event: 'Үйл явдал',
    event_list: 'Үйл явдлын жагсаалт',
    download: 'Татах',
    home: 'Эхлэл',
    skill_report: 'Ур чадвар',
    order_direction: 'Жагсаах чиглэл',
    order_by: 'Жагсаах',
    order_by_desc: 'Багана сонгох замаар жагсаалтын дарааллыг өөрчлөх.',
    created_date: 'Үүсгэсэн огноо',
    confirmed_date: 'Баталсан огноо',
    status: 'Төлөв',
    division_lead: 'Хэлтсийн дарга',
    filter: 'Шүүх',
    reports: 'Тайлан',
    skill_list: 'Ур чадвар',
    skill_categories: 'У/Ч-н ангилал',
    page_size: 'Хуудсын хэмжээ',
    order_asc: 'Өсөхөөр',
    order_desc: 'Буурахаар',
    default: 'Анхны утга',
    change_order_page_title: 'Дарааллыг өөрчлөх',
    change_order_breadcrumb_title: 'Дараалал өөрчлөх',
    update_order_modal_title:
      'Та дарааллыг өөрчлөх гэж байгаадаа итгэлтэй байна уу?',
    update_order_modal_description: 'Энэ үйлдлийг буцаах боломжгүй.',
    select_file: 'Файл сонгох',
    reset: 'Сэргээх',
    back_to_login: 'Нэвтрэх рүү буцах',
    day: '',
    other: 'Бусад',
    send: 'Илгээх',
    duration_day: ' өдөр',
    duration_hour: ' цаг',
    duration_days: ' өдөр',
    duration_hours: ' цаг',
    duration_min: ' минут',
    duration_mins: ' минут',
    duration_am: 'Үдээс өмнө',
    duration_pm: 'Үдээс хойш',
    next: 'Дараах',
    template_settings: 'Загварын тохиргоо',
    time_off: 'Амралт чөлөө',
    hr_related: 'ХН хэсэг',
    configuration: 'Тохиргоо',
    me: 'Me',
    more: '',
    remote_work: 'Зайнаас ажиллах',
    reason: 'Шалтгаан',
    close: 'Хаах',
    tag: 'Ажлын төрөл',
  },
  MODULES: {
    partner_company: 'Түнш компани',
    projects: 'Төсөл',
    report: 'Тайлан',
    resource: 'Хүний нөөц',
    settings: 'Тохиргоо',
    email: 'Имэйл',
    time: 'Амралт чөлөө',
    time_settings: 'Амралт чөлөөний тохиргоо',
    remote: 'Зайнаас ажиллах',
    resource_planning: 'Нөөцийн төлөвлөлт',
  },
  HOME: {
    home: 'Эхлэл',
    features: 'Онцлогууд',
    tel: 'Утас',
    faq: 'Түгээмэл асуултууд',
    pricing: 'Үнэ',
    contact: 'Холбоо барих',
    sign_in: 'Нэвтрэх',
    go_to_dashboard: 'Хянах самбар луу буцах',
    additional_content: 'Зарим нэмэлт контент',
    intro_title: 'Цаг бол алт, ухаалгаар зохицуул',
    intro_subtitle: 'Teamsync ашиглан багийн бүтээмжээ нэмэгдүүлээрэй',
    intro_description1:
      'Ажлын цаг, төсөл, хүний нөөц болон амралтын хүсэлтийг нэг дороос удирдах боломжтой, орчин үеийн технологи дээр суурилсан, 10 гаруй жил туршигдсан, бүх төхөөрөмж дээр ашиглах боломжтой вэб аппликейшн',
    book_now: 'Захиалах',
    learn_more: 'Илүү ихийг',
    why: 'Яагаад биднийг сонгох гэж',
    try_now: 'Туршиж үзэх',
    why_choose_us_title:
      'Яагаад Teamsync? Учир нь бид цаг хугацааг боломж гэж хардаг.',
    why_choose_us_description: `Манай вэб платформ бол таны бизнесийн өдөр тутмын үйл ажиллагааг хялбарчлах төгс шийдэл юм. Ажилтнуудын цаг бүртгэл, төслийн удирдлага, хүний нөөцийн менежмент, амралтын хүсэлт болон өдөр тутмын тайлан зэрэг олон чухал үйлдлүүдийг нэг л газраас удирдах боломжтой.

Цаг бүртгэлийн систем маань оффис болон зайнаас ажиллах хоёр төрлийн горимыг дэмждэг. Оффист ажиллах үед TOTP токен болон байршлын мэдээлэл ашиглан ажилтны цаг бүртгэлийг баталгаажуулдаг бол зайнаас ажиллах үед уян хатан бүртгэлийн горимтой.

Энэ бүх үйлдлүүдийг ямар нэг нэмэлт төхөөрөмжгүйгээр, гар утас эсвэл компьютер дээрээс хийх боломжтой. Бидний вэбсайт нь бүх төрлийн дэлгэцэнд зориулагдсан учраас та хаанаас ч ажиллах боломжтой. Бид өөрсдийн компани дээр 10 гаруй жил туршиж, сайжруулсан учраас таны бизнесийн бүх хэрэгцээг хангах болно.
`,
    features_title: 'Бүх онцлогууд',
    hr: 'Х/Н',
    employee: 'ажилтан',
    privacy_policy: 'Нууцлалын бодлого',
    terms_of_condition: 'Үйлчилгээний нөхцөл',
    support: 'Тусламж',
    about: 'Бидний тухай',
    contact_us: 'Холбоо барих',
    email: 'И-мэйл',
    teamsync: 'Teamsync Платформ',
    help: 'Тусламж',
    more: 'Дэлгэрэнгүй',
  },
  DASHBOARD: {
    hr: 'Захиргаа хүний нөөц',
    se: 'Программ хангамжийн инженер',
    operator: 'Оператор',
    intern: 'Дадлагжигч',
    developer: 'Хөгжүүлэгч',
    ceo: 'Гүйцэтгэх захирал',
    spg: 'Ахлах хөгжүүлэгч',
    designer: 'Дизайнер',
    total: 'Нийт',
    employees: 'Ажилтан',
    projects: 'Төсөл',
    company_overview: 'Компанийн тойм',
    upcoming_events: 'Удахгүй болох үйл явдлууд',
    events_btn: 'Үйл явдал',
    no_events: 'Удахгүй болох үйл явдал байхгүй...',
    vacation_status: 'Амралтын мэдээлэл',
    employees_status: 'Ажилчдын төлөв',
    daily_report_overview: 'Цаг зарцуулалт',
    non_time_spent: 'Ажлаагүй төслүүд',
    no_data: 'Мэдээлэл алга',
    from_office: 'Оффисоос',
    from_remote: 'Зайнаас',
  },
  LOGIN: {
    title: 'Нэвтрэх',
    slogan: 'Танай баг болон төслүүдэд зориулсан бүх шийдэл',
    email: 'Цахим шуудан',
    password: 'Нууц үг',
    remember_me: 'Намайг сана',
    forgot_password: 'Нууц үгээ мартсан уу?',
    login: 'Нэвтрэх',
    or_sign_in_with: 'Өөр хэлбэрээр нэвтрэх',
    ERROR: {
      E000001: 'Нэвтрэлт амжилтгүй боллоо',
    },
  },
  REQUIRED_CHANGE_PASSWORD: {
    title: 'Богино хугацааны нууц үг солих',
    description:
      'Манай платформд тавтай морил! Бүртгэлийнхээ аюулгүй байдлыг хангахын тулд богино хугацааны нууц үгээ өөрчлөх шаардлагатай. Дараах зааврыг дагана уу:',
  },
  CALENDAR: {
    allDay: 'Бүх өдөр',
    prev: 'Өмнөх',
    next: 'Дараах',
    today: 'Өнөөдөр',
    month: 'Сар',
    week: 'Долоо хоног',
    day: 'Өдөр',
    agenda: 'Жагсаалт',
    no_events: 'Тэмдэглэлт үйл явдал байхгүй байна.',
  },
  EVENT: {
    holiday: 'Амралтын өдөр',
    event: 'Үйл ажиллагаа',
    birthday: 'Төрсөн өдөр',
    happy_birthday: 'Төрсөн өдрийн мэнд',
    name_placeholder: 'Үйл явдлын нэр',
    description_placeholder: 'Үйл явдлын тайлбар',
    start: 'Эхлэх огноо',
    end: 'Дуусах огноо',
    details: 'Дэлгэрэнгүй',
    today: 'Өнөөдөр',
    remove: 'Үйл явдал устгах',
    delete_modal_title: 'Үйл явдал устгах',
    delete_modal_description: 'Та энэ үйл явдлыг устгахдаа итгэлтэй байна уу',
    create: 'Үүсгэх',
    create_success_title: 'Шинэ үйл явдал үүслээ',
    create_success_desc:
      'ID: {{id}} болон {{name}} нэртэй шинэ үйл явдал нэмэгдсэн',
    show_holiday: 'Бүх амралтын өдрийг харах',
  },
  FORGOT_PASSWORD: {
    title: 'Нууц үгээ мартсан уу?',
    description: 'Цахим шууданд ирсэн кодыг оруулна уу.',
    email: 'Цахим шуудан',
    back: 'Нэвтрэх хэсэг рүү буцах',
    send: 'Илгээх',
  },
  VERIFY_CODE: {
    title: 'Баталгаажуулалт',
    description: 'Цахим шуудангаа шалгаж, кодыг оруулна уу.',
    no_code_received: 'Код ирээгүй байна уу?',
    resend: 'Дахин илгээх',
    back: 'Нэвтрэх хэсэг рүү буцах',
    send: 'Илгээх',
    code: 'Код',
  },
  RESET_PASSWORD: {
    title: 'Нууц үг тохируулах',
    description:
      'Таны шинэ нууц үг өмнө нь ашигласан нууц үгээс өөр байх ёстой',
    new_password: 'Шинэ нууц үг',
    confirm_new_password: 'Шинэ нууц үг баталгаажуулах',
    reset_password: 'Шинэчлэх',
    password: 'Нууц үг',
  },
  USER_MENU: {
    skill: 'Миний ур чадвар',
    profile: 'Профайл',
    logout: 'Гарах',
  },
  PROFILE: {
    fullname: 'Овог нэр',
    position: 'Албан тушаал',
    department: 'Department',
    my_phone_number: 'Утасны дугаар',
    phone_number: 'Утас',
    relation: 'Хамаарал',
    email: 'Цахим шуудан',
    birthday: 'Төрсөн өдөр',
    edit_profile: 'Профайл засах',
    projects_tab: 'Төслүүд',
    skills_tab: 'Ур чадварууд',
    project_history_tab: 'Ажлын түүх',
    settings_tab: 'Тохиргоо',
    picture: 'Профайл зураг',
    additional_phone_numbers: 'Нэмэлт утасны дугаарууд',
    add_phone: 'Утас нэмэх',
    user_code: 'Ажилтны код',
    no_projects: 'Төсөл байхгүй',
    total_projects: 'Нийт төсөл: ',
    total_cost: 'Нийт өртөг: ',
    home_address: 'Гэрийн хаяг',
    system_role: 'Системийн эрх',
  },
  SETTINGS: {
    password: {
      title: 'Нууц үг',
      change_password: 'Нууц үг солих',
      old_password: 'Одоогийн нууц үг',
      new_password: 'Шинэ нууц үг',
      confirm_password: 'Шинэ нууц үг баталгаажуулах',
      confirmation_dialog_title: 'Та итгэлтэй байна уу?',
      confirmation_dialog_description: 'Таны нууц үгийг өөрчлөх гэж байна.',
      confirmation_dialog_confirmation_text: 'Тийм',
      confirmation_dialog_cancellation_text: 'ҮГүй',
    },
    clear: {
      confirmation_dialog_title:
        'Та бүх шүүлтүүрийг арилгахдаа итгэлтэй байна уу?',
      confirmation_dialog_description:
        'Та жагсаалтын бүх хуудсан дээрх шүүлтүүрийн тохиргоог анхны утга руу нь буцаана',
    },
    filter_options: 'Шүүлтүүрийн тохиргоо',
    filter_desc:
      'Бүх шүүлтүүрийг дахин тохируулах товчийг дарснаар та жагсаалтын бүх хуудсан дээрх шүүлтүүрийн тохиргоог үндсэн утга руу нь буцаана. Энэ үйлдэл нь эрэмбэлэх сонголт, хуудасны хэмжээ зэрэг шүүлтүүрийн сонголтуудад хийсэн бүх өөрчлөлтийг арилгах болно. ',
    clear_filters: 'Бүх шүүлтүүрийг дахин тохируулах',
    allow_location_title: 'Байршилын мэдээлэл зөвшөөрөх',
    my_location: 'Миний байршилын координатууд нь:',
    latitude: 'Өргөрөг: ',
    longitude: 'Уртраг: ',
    denied_instruction_first:
      'Өмнө нь та байршилын мэдээлэл тогтоохыг зөвшөөрөөгүй байна. Та интернет хөтчийн эрхийг дахин тохируулна уу.',
    denied_instruction_second:
      "Таны хөтөч дараагийн удаа зөвшөөрөл хүсэх үед 'Allow on every visit or Allow' дээр дарна уу.",
    undefined_instruction_first:
      "Зурагт үзүүлсний дагуу баруун дээд буланд байрлах icon дээр дарна уу. Дараа нь зөвшөөрөл олгохын тулд 'Allow' дээр дарна уу.",
    undefined_instruction_second:
      "Таны хөтөч дараагийн удаа зөвшөөрөл хүсэх үед 'Allow on every visit or Allow' дээр дарна уу.",
    permission_instruction:
      'Таны байршлын мэдээллийг бид өгөгдөлийн санд хадгалахгүй, зөвхөн оффисоос ажилласан аль эсвэл зайнаас гэж ялгахад ашиглаж байгаа.',
  },
  CONFIRM: {
    logout_desc: 'Та гарахдаа итгэлтэй байна уу?',
    logout_title: 'Гарахыг оролдож байна',
  },
  COMPANY: {
    companies: 'Түнш компани',
    create_company: 'Үүсгэх',
    name_placeholder: 'Компаний нэр',
    description_placeholder: 'Компаны тайлбар',
    list_page_title: 'Түнш компанийн жагсаалт',
    create: 'Шинэ түнш компани',
    title: 'Түнш компани',
    no_column: '№',
    name_column: 'Нэр',
    description_column: 'Тайлбар',
    divisions_column: 'Хэлтэс',
    projects_column: 'Төсөл',
    actions_column: 'Үйлдүүд',
    delete_modal_title: 'Та итгэлтэй байна уу?',
    delete_modal_description:
      'Та сонгосон түнш компанийг устгахыг хүсч байна уу? Энэ үйлдлийг буцаах боломжгүй.',
    overview_page_title: 'Тойм',
    you_have: 'Тус компанид',
    divisions: 'хэлтэс',
    projects: 'төсөл',
    create_success_title: 'Шинэ компани нэмэгдсэн',
    create_success_desc: '{{name}} нэртэй шинэ компани нэмэгдсэн',
    no_division: 'Энэ компани одоогоор хэлтэсгүй байна.',
    save_confirm_title:
      'Компанийн дэлгэрэнгүй мэдээллийг хадгалахыг баталгаажуулна уу',
    save_confirm_desc:
      'Та үргэлжлүүлэхийг хүсч байна уу? Хадгалахаасаа өмнө бүх мэдээлэл үнэн зөв эсэхийг шалгаарай.',
  },
  PARTNER_DIVISION: {
    list_page_title: 'Түнш хэлтсийн жагсаалт',
    name_column: 'Нэр',
    no_column: 'No',
    projects_column: 'Төслүүд',
    description_column: 'Тайлбар',
    actions_column: 'Үйлдлүүд',
    lead_column: 'Дарга',
    division_name: 'Хэлтсийн нэр',
    delete_modal_title: 'Та итгэлтэй байна уу?',
    delete_modal_description:
      'Та сонгосон хэсгийг устгахыг хүсэж байна уу? Энэ үйлдлийг буцаах боломжгүй.',
    company_name_column: 'Компаны нэр',
    division_create: 'Үүсгэх',
    lead: 'Хэлтсийн дарга',
    company_name: 'Компаны нэр',
    divisions: 'Түнш хэлтэс',
    divisions_edit: 'Хэлтэс засах',
    name_placeholder: 'Хэлтсийн нэр',
    description_placeholder: 'Хэлтсийн тайлбар',
    lead_placeholder: 'Хэлтсийн нэр',
    save_confirm_title:
      'Хэлтсийн дэлгэрэнгүй мэдээллийг хадгалахыг баталгаажуулна уу',
    save_confirm_desc:
      'Та үргэлжлүүлэхийг хүсч байна уу? Хадгалахаасаа өмнө бүх мэдээлэл үнэн зөв эсэхийг шалгаарай.',
    display_order: 'Дараалал',
    order: '№',
    create_success_title: 'Шинэ түнш хэлтэс нэмэгдсэн',
    create_success_desc:
      'Шинэ түнш хэлтэс нэмэгдсэн id: {{id}} болон нэр: {{name}}',
  },
  PROJECT: {
    title: 'Төслүүд',
    resource: 'Төсөл',
    list_title: 'Төслийн жагсаалт',
    project_name: 'Төслийн нэр',
    description_placeholder: 'Төслийн тодорхойлолт',
    description: 'Тодорхойлолт',
    members: 'Гишүүд',
    member_management: 'Гишүүдийн менежмент',
    add_member: 'Гишүүн нэмэх',
    manage_access: 'Хандалтыг удирдах',
    status: 'Төлөв',
    project_status: 'Төслийн төлөв',
    member_remove: 'Гишүүн хасах',
    cost_start: 'Өртөг эхлэх',
    cost_end: 'Өртөг дуусах',
    partner_company: 'Түнш компани',
    partner_division: 'Түнш хэлтэс',
    cost: 'Өртөг',
    expire: 'Дуусах',
    created_at: 'Үүсгэсэн',
    search_with_name_email: 'Нэр эсвэл цахим шуудангаар хайх',
    member_delete_title: 'Төслийн гишүүн хасагдлаа',
    member_delete_desc: 'Та энэ гишүүнийг устгахдаа итгэлтэй байна уу?',
    member_add_title: 'Гишүүн нэмэгдлээ',
    member_add_desc: '{{name}} нэмэгдлээ',
    edit_member: 'Edit member',
    report_title: 'Өртгийн тайлан',
    cost_report_title: 'Өртөг',
    role: 'Хандах эрх',
    edit_help_title: 'Төслийн засвар',
    member_help_title: 'Төслийн засвар',
    report_help_title: 'Тайлан',
    featured_image: 'Зураг',
    settings: 'Төслийн тохиргоо',
    delete: 'Төсөл устгах',
    delete_modal_title: 'Та энэ төслийг устгахдаа итгэлтэй байна уу?',
    delete_modal_description:
      'Та үргэлжлүүлэхийг хүсч байна уу? Энэ үйлдлийг буцаах боломжгүй.',
    cost_plan: 'Өртгийн төлөвлөгөө',
    overview: 'Ерөнхий',
    no_description:
      'Энэхүү төсөлд тавтай морил! Одоогоор дэлгэрэнгүй тайлбар байхгүй байж магадгүй',
    total: 'Нийт: ',
    total_members: 'Нийт гишүүн:',
    project: 'Төсөл',
    total_cost: 'Нийт өртөг',
    count: 'Тоо',
    create_success_title: 'Шинэ төсөл үүслээ',
    create_success_desc:
      'ID: {{id}} болон {{name}} нэртэй шинэ төсөл нэмэгдсэн.',
    owner: 'Эзэмшигч',
    role_member: 'Гишүүн',
    create_title: 'Төсөл үүсгэх',
    role_manager: 'Менежер',
    history: 'Түүх',
    quit_status: 'Гарсан',
    project_type: 'Төслийн төрөл',
    updated_at: 'Өөрчилсөн огноо',
    status_on_hold: 'Хүлээгдэж буй',
    status_new: 'Шинэ',
    status_in_progress: 'Ажиллаж  байна',
    status_completed: 'Дууссан',
    type_research: 'Судалгаа шинжилгээ',
    type_new_project: 'Шинэ төсөл',
    type_maintenance: 'Арчилгаа',
    type_renewal: 'Шинэчлэл',
    read_owner_warning_title: 'Засах боломжгүй',
    read_owner_warning_msg:
      'Хэдийгээр та төслийн эзэмшигч боловч өөрийн зөвшөөрлөөр аливаа зүйлийг засах боломжгүй.',
    read_owner_warning_desc:
      'Зөвшөөрлийн шинэчлэлтийг авахын тулд системийн админтай холбогдоно уу.',
    member_add_pre_dialog_title: 'Нэмэх мэдээлэл',
    member_update_pre_dialog_title: 'Өөрчлөгдөх мэдээлэл',
    member_upsert_dialog: {
      project_name: 'Төслийн нэр:',
      name: 'Нэр:',
      role: 'Хандах эрх:',
      position: 'Албан тушаал:',
      cost: 'Өртөг:',
      cost_start_date: 'Өртөг эхлэх өдөр:',
      expire_date: 'Дуусах өдөр:',
      start_date: 'Эхлэх өдөр:',
      end_date: 'Дуусах өдөр:',
    },
    save_confirm_title:
      'Төслийн дэлгэрэнгүй мэдээллийг хадгалахыг баталгаажуулна уу',
    save_confirm_desc:
      'Та үргэлжлүүлэхийг хүсч байна уу? Хадгалахаасаа өмнө бүх мэдээлэл үнэн зөв эсэхийг шалгаарай.',
    joined: 'Нэгдсэн',
    cost_changed: 'Өртөг ',
    role_changed: 'Хандах эрх өөрчлөгдлөө',
    left: 'Гарсан',
    project_started: 'Төсөл эхэлсэн',
    cost_started: 'Өртөг эхэлсэн',
    cost_ended: 'Өртөг дууссан',
    project_ended: 'Төсөл дууссан',
    project_created: 'Төсөл үүсгэсэн',
    cost_start_at_changed: 'Өртөг эхлэх хугацаа',
    project_name_changed: 'Төслийн нэр өөрчлөгдсөн',
    history_dialog_title: 'Өртгийн түүхийг арилгахыг баталгаажуулна уу',
    history_dialog_desc:
      'Хүсээгүй өртгийн түүхийг арилгах. Үргэлжлүүлэхийн өмнө баталгаажуулна уу',
    partner_purchase_order: 'Түнш хэлтсийн захиалга',
    filter_project_dialog_title: 'Төслүүдийг шүүх',
    employee_filter: 'Ажилчид',
    division_filter: 'Түнш хэлтэс',
    still_active: 'Идэвхитэй',
    start_at_changed: 'Эхлэх хугацаа өөрчлөгдсөн',
    member_start_at: 'Эхлэх огноо',
    member_expire_at: 'Дуусах огноо',
  },
  PROJECT_ACTIVITY: {
    joined: '{{role}} хандах эрхээр нэгдсэн',
    something_changed: '{{what}} {{from}} -с {{to}} болж өөрчлөгдсөн',
    left: 'төслөө орхисон.',
    project_started: 'Төсөл эхлэх огноо',
    cost_started: 'Өртөг эхлэх огноо',
    cost_ended: 'Өртөг дуусах огноо',
    project_ended: 'Төсөл дуусах огноо',
    project_created: 'Төсөл үүссэн',
    cost_changed: 'гишүүний өртөг',
    performed_by: 'Гүйцэтгэсэн',
    role_changed: 'Хандах эрх',
    cost_start_at_changed: 'Өртөг эхлэх огноо',
    unspecified: 'Тодорхойлоогүй',
    project_name_changed: 'Төслийн нэр',
    start_at_changed: 'Эхлэх хугацаа өөрчлөгдсөн',
    member_filter_placeholder: 'Гишүүнээр шүүх',
    action_filter_placeholder: 'Үйлдлээр шүүх',
  },
  PROJECT_PARTNER_PURCHASE_ORDER: {
    title: 'Түнш хэлтсийн захиалга',
    add_title: 'Түнш хэлтсийн захиалга нэмэх',
    position: 'Албан тушаал',
    create_success_title: 'Түнш хэлтсийн захиалга нэмэгдлээ.',
    create_success_desc:
      'Түнш хэлтсийн захиалга {{position}} албан тушаалтай хийгдлээ.',
    save_confirm_title: 'Түнш хэлтсийн захиалгыг хадгалахыг баталгаажуулна уу',
    save_confirm_desc:
      'Та үргэлжлүүлэхийг хүсч байна уу? Хадгалахаасаа өмнө бүх мэдээлэл үнэн зөв эсэхийг шалгаарай.',
    delete_modal_title: 'Түнш хэлтсийн захиалгыг устгахдаа итгэлтэй байна уу?',
    delete_modal_description:
      'Та сонгосон албан тушаалыг устгахыг хүсэж байна уу? Энэ үйлдлийг буцаах боломжгүй',
  },
  POSITION: {
    menu_title: 'Албан тушаал',
    page_title: 'Албан тушаалууд',
    list_title: 'Албан тушаалын жагсаалт',
    position_name: 'Албан тушаалын нэр',
    no_column: '№',
    name_column: 'Нэр',
    visibility_column: 'Харагдац',
    hidden: 'Нууна',
    shown: 'Харуулна',
    members: 'Гишүүд',
    create_title: 'Үүсгэх',
    delete_modal_title: 'Та энэ албан тушаалыг устгахдаа итгэлтэй байна уу?',
    delete_modal_description:
      'Та сонгосон албан тушаалыг устгахыг хүсэж байна уу? Энэ үйлдлийг буцаах боломжгүй',
    create_success_title: 'Шинэ албан тушаал үүслээ',
    create_success_desc:
      'ID: {{id}}, {{name}} нэртэй шинэ албан тушаал нэмэгдсэн',
    save_confirm_title:
      'Албан тушаалын дэлгэрэнгүй мэдээллийг хадгалахыг баталгаажуулна уу',
    save_confirm_desc:
      'Та үргэлжлүүлэхийг хүсч байна уу? Хадгалахаасаа өмнө бүх мэдээлэл үнэн зөв эсэхийг шалгаарай.',
    change_order: 'Дараалал өөрчлөх',
    order_title: 'Дараалал өөрчлөх',
    display_order: 'Харуулах дараалал',
    order: '№',
    short_name: 'Богино нэр',
    is_default_filter: 'Нөөцийн төлөвлөлтөөс хасах',
    is_default_filter_desc:
      'Хэрэв энэ нүдийг сонговол энэ албан тушаалтай хэрэглэгчид нөөцийн төлөвлөлтийн жагсаалтад автоматаар орохгүй',
  },
  ROLE: {
    menu_title: 'Хандах эрх',
    list: 'Хандах эрхийн жагсаалт',
    create_role: 'Үүсгэх',
    no_column: 'Дугаар',
    name_column: 'Нэр',
    actions_column: 'Үйлдлүүд',
    delete_modal_title: 'Та энэ хандах эрхийг устгахдаа итгэлтэй байна уу?',
    delete_modal_description:
      'Та сонгосон хандах эрхийг устгахыг хүсэж байна уу? Энэ үйлдлийг буцаах боломжгүй.',
    name_placeholder: 'Хандах эрхийн нэр',
    resource_management: 'Resource Managment',
    time_management: 'Time Managment',
    division: 'Түнш хэлтэс',
    project_management: 'Project Managment',
    task_management: 'Task Managment',
    create_success_title: 'Шинэ хандах эрх нэмэгдлээ',
    create_success_desc:
      'ID: {{id}} болон {{name} нэртэй шинэ хандах эрх нэмэгдсэн',
    create_duplicate_title:
      'Та энэ хандах эрхийг үүсгэхээсээ өмнө хуулбарлахыг хүсч байна уу?',
    create_duplicate_desc:
      'Бүх хандах эрхийн зөвшөөрлийг үүсгэхээс өмнө хуулах болно.',
    copy_of: 'Энэ бол {{name}} -н хуулбар юм',
    copy_text: 'Энэ бол хуулбар юм ',
    save_confirm_title:
      'Хандах эрхийн дэлгэрэнгүй мэдээллийг хадгалахыг баталгаажуулна уу',
    save_confirm_desc:
      'Та үргэлжлүүлэхийг хүсч байна уу? Хадгалахаасаа өмнө бүх мэдээлэл үнэн зөв эсэхийг шалгаарай.',
    write: 'Бичих',
    read: 'Унших',
    admin: 'Админ',
  },
  EMPLOYEE: {
    list: 'Ажилчид',
    create_page_title: 'Үүсгэх',
    edit_page_title: 'Ажилтны мэдэээлэл засах',
    firstname: 'Нэр',
    firstname_placeholder: 'Нэр',
    firstname_en: 'Нэр англиар',
    firstname_en_placeholder: 'Нэр англиар',
    lastname: 'Овог',
    lastname_placeholder: 'Овог',
    lastname_en: 'Овог англиар',
    lastname_en_placeholder: 'Овог англиар',
    employee_name: 'Ажилтны нэр',
    email: 'Цахим шуудан',
    email_placeholder: 'Ажилтны цахим шуудан',
    user_code: 'Ажилтны код',
    user_code_placeholder: 'Ажилтны код',
    gender: 'Хүйс',
    position: 'Албан тушаал',
    department: 'Хэлтэс',
    name_placeholder: 'Ажилтны нэр',
    phone_placeholder: 'Ажилтны утас',
    create_success_title: 'Шинэ ажилтан нэмэгдлээ',
    create_success_desc: 'Шинэ ажилтан нэмэгдлээ: {{name}}',
    edit_success_title: 'Ажилтны мэдээлэл шинэчлэгдсэн',
    edit_success_desc: 'Ажилтан дараах нэрээр шинэчлэгдсэн: {{name}}',
    resource: 'Ажилчид',
    name_column: 'Нэр',
    user_code_column: 'Ажилтны код',
    phone_column: 'Утас',
    gender_column: 'Хүйс',
    status_column: 'Төлөв',
    role_column: 'Хандах эрх',
    position_column: 'Албан тушаал',
    department_column: 'Хэлтэс',
    actions_column: 'Үйлдлүүд',
    status: 'Төлөв',
    status_deleted: 'Устсан',
    status_active: 'Идэвхитэй',
    status_maternity_leave: 'Жирэмсны амралттай',
    status_quit: 'Гарсан',
    status_superadmin: 'Админ',
    role: 'Хандах эрх',
    relation: 'Хамаарал',
    delete_modal_title: 'Та итгэлтэй байна уу?',
    delete_modal_description:
      'Та сонгосон ажилтныг устгахыг хүсч байна уу? Энэ үйлдлийг буцаах боломжгүй.',
    skills_tab: 'Ур чадвар',
    project_history_tab: 'Ажлын түүх',
    project_history_empty: `Ямар нэг төсөлд хамрагдаагүй байна.`,
    projects_tab: 'Төслүүд',
    ndsh_months: 'НДШ төлсөн сар',
    hired_date: 'Ажилд орсон огноо',
    quit_date: 'Гарсан огноо',
    filter_employees_dialog_title: 'Ажилчдыг шүүх',
    quit_modal_title: 'Та гарах маягтыг илгээхийг хүсэж байна уу? ',
    quit_modal_desc:
      'Та гарах огноог өөрчлөх эсвэл дараа нь идэвхтэй хэрэглэгч рүү буцах боломжтой.',
    personal_details: 'Хувийн мэдээлэл',
    company_related: 'Компанитай холбоотой',
    contact_details: 'Холбоо барих мэдээлэл',
    status_settings_dialog_title: 'Status settings',
    ndsh_total: 'Нийт НДШ төлсөн сар',
    ndsh_placeholder: 'Нийт сар',
    save_confirm_title: 'Ажилтны мэдээллийг хадгалахыг баталгаажуулна уу',
    save_confirm_desc:
      'Та үргэлжлүүлэхийг хүсч байна уу? Хадгалахаасаа өмнө бүх мэдээлэл үнэн зөв эсэхийг шалгаарай.',
    home_address: 'Гэрийн хаяг',
    home_address_placeholder: 'Ажилтаны гэрийн хаяг',
    quit_reason: 'Ажлаас гарах шалтгаан',
    current_status_deleted: 'Устсан',
    current_status_active: 'Идэвхитэй',
    current_status_maternity_leave: 'Жирэмсний амралттай',
    current_status_quit: 'Гарсан',
    current_status_long_term_leave: 'Урт хугацааны амралттай',
    current_status_day: 'Амралттай',
    current_status_day_am: 'Үдээс өмнө амралттай',
    current_status_day_pm: 'Үдээс хойш амралттай',
    current_status_compensatory_day: 'Нөхөн амралттай',
    current_status_holiday_work: 'Амралтын өдрийн ажил',
    current_status_workation: 'Working during timeoff',
    current_status_compensatory_request: 'Нөхөн амралттай',
    current_status_special: 'Онцгой амралттай',
    back_to_work_from_long_term_leave: 'Урт хугацааны амралт дуусгах',
    back_to_work_date: 'Ажилдаа орох өдөр',
    long_term_start_date: 'Урт хугацааны амралт эхлэх: ',
    impairment: 'Хөгжлийн бэрхшээлтэй эсэх?',
    impaired_desc: 'Хөгжлийн бэрхшээлтэй бол дээрхийг чягтална',
    time_required: 'Цаг бүртгэл шаардлагатай',
    import: 'Оруулах',
    template: 'Загвар',
    row_errors: '{{error}}-р мөрийн дараах багануудад алдаа илэрсэн.',
    column_errors: 'Баганын алдаа',
    success: 'Нэмсэн бүх хэрэглэгчийн анхны нууц үг нь Teamsync@123 байна.',
  },
  DEPARTMENT: {
    menu_title: 'Хэлтэс',
    title: 'Хэлтэс',
    list_title: 'Хэлтсийн жагсаалт',
    name_hint: 'Нэрийг оруулна уу',
    name_column: 'Хэлтсийн нэр',
    lead_column: 'Хэлтсийн дарга',
    delete_modal_title: 'Та итгэлтэй байна уу?',
    delete_modal_description:
      'Та сонгосон хэлтсийг устгахыг хүсэж байна уу? Энэ үйлдлийг буцаах боломжгүй.',
    create_success_title: 'Шинэ хэлтэс нэмэгдсэн',
    create_success_desc: '{{name}} нэртэй шинэ хэлтэс нэмэгдсэн',
    create_title: 'Үүсгэх',
    save_confirm_title:
      'Хадгалах хэлтсийн дэлгэрэнгүй мэдээллийг баталгаажуулна уу',
    save_confirm_desc:
      'Та үргэлжлүүлэхийг хүсч байна уу? Хадгалахаасаа өмнө бүх мэдээлэл үнэн зөв эсэхийг шалгаарай.',
    order: '№',
  },
  EMPLOYEE_CURRENT_DAY_STATUS: {
    current_not_started_work: 'Ажил эхлээгүй',
    current_working: 'Ажиллаж байна',
    current_work_done: 'Тарсан',
    current_vacation: 'Амралттай байгаа',
    current_holiday: 'Амралтын өдөр',
  },
  HOLIDAY: {
    list: 'Амралтын өдрийн жагсаалт',
    create_success_title: 'Шинэ амралтын өдөр нэмэгдлээ',
    create_success_desc: '{{name}} нэртэй шинэ амралтын өдөр нэмсэн',
    delete_modal_description:
      'Та сонгосон амралтын өдрийг устгахыг хүсэж байна уу? Энэ үйлдлийг буцаах боломжгүй.',
  },
  SKILL: {
    title: 'Ур чадвар',
    menu_title: 'Ур чадвар',
    create_success_title: 'Шинэ ур чадвар нэмэгдлээ',
    create_success_desc: '{{name}} амжилттай нэмэгдлээ',
    delete_modal_title: 'Та энэ ур чадварыг устгахдаа итгэлтэй байна уу?',
    delete_modal_description:
      'Та үргэлжлүүлэхийг хүсч байна уу? Энэ үйлдлийг буцаах боломжгүй',
    name_placeholder: 'Нэр',
    description_placeholder: 'Тайлбар',
    list_page_title: 'Ур чадварын жагсаалт',
    create: 'Үүсгэх',
    filter: 'Шүүх',
    skill_report: 'Ур чадварын тайлан',
    save_confirm_title:
      'Ур чадварын дэлгэрэнгүй мэдээллийг хадгалахыг баталгаажуулна уу',
    save_confirm_desc:
      'Та үргэлжлүүлэхийг хүсч байна уу? Хадгалахаасаа өмнө бүх мэдээлэл үнэн зөв эсэхийг шалгаарай.',
  },
  TAG: {
    title: 'Ажлын төрөл',
    menu_title: 'Ажлын төрөл',
    create_success_title: 'Шинэ ажлын төрөл нэмэгдлээ',
    create_success_desc: '{{name}} амжилттай нэмэгдлээ',
    delete_modal_title: 'Та энэ ажлын төрлийг устгахдаа итгэлтэй байна уу?',
    delete_modal_description:
      'Та үргэлжлүүлэхийг хүсч байна уу? Энэ үйлдлийг буцаах боломжгүй',
    name_placeholder: 'Нэр',
    description_placeholder: 'Тайлбар',
    list_page_title: 'Ажлын төрлийн жагсаалт',
    create: 'Үүсгэх',
    filter: 'Шүүх',
    save_confirm_title:
      'Ажлын төрлийн дэлгэрэнгүй мэдээллийг хадгалахыг баталгаажуулна уу',
    save_confirm_desc:
      'Та үргэлжлүүлэхийг хүсч байна уу? Хадгалахаасаа өмнө бүх мэдээлэл үнэн зөв эсэхийг шалгаарай.',
    is_all: 'Бүх албан тушаалыг сонгох',
  },
  SKILL_CATEGORY: {
    title: 'Ур чадварын ангилал',
    create_success_title: 'Шинэ ур чадварын ангилал нэмэгдсэн',
    create_success_desc: '{{name}} амжилттай нэмэгдлээ',
    delete_modal_title: 'Энэ ангиллын бүх ур чадвар мөн устах болно!',
    delete_modal_description:
      'Та категорийг устгахдаа итгэлтэй байна уу? Энэ үйлдлийг буцаах боломжгүй.',
    name_placeholder: 'Нэр',
    description_placeholder: 'Тайлбар',
    list_page_title: 'Ур чадварын ангилалын жагсаалт',
    create: 'Үүсгэх',
    save_order: 'Дараалал хадгалах',
    save_confirm_title:
      'Ур чадварын категорийн дэлгэрэнгүй мэдээллийг хадгалахыг баталгаажуулна уу',
    save_confirm_desc:
      'Та үргэлжлүүлэхийг хүсч байна уу? Хадгалахаасаа өмнө бүх мэдээлэл үнэн зөв эсэхийг шалгаарай.',
    display_order_column: 'Харуулах дараалал',
    name_column: 'Нэр',
    change_order: 'Дараалал өөрчлөх',
    order: '№',
    target_placeholder: 'Зорилтот ангилал',
    target_all: 'Бүгд',
    target_specific: 'Тодорхой албан тушаалд',
    target_positions: 'Зорилтот албан тушаалууд',
  },
  USER_SKILL: {
    title: 'Ур чадвараа нэмж эсвэл засаарай',
    add_button: 'Ур чадвар нэмэх',
    skill: 'УР чадвар',
    create_success_title: 'Шинэ ур чадвар нэмэгдсэн',
    create_success_desc: 'Таны ур чадварыг амжилттай нэмлээ.',
    confirm_edit_modal_title:
      'Та өөрчлөлтүүдийг хадгалахдаа итгэлтэй байна уу?',
    confirm_edit_modal_desc: 'Энэ үйлдлийг буцаах боломжгүй.',
    delete_modal_title: 'Та итгэлтэй байна уу?',
    delete_modal_desc: 'Энэ үйлдлийг буцаах боломжгүй.',
    add_title: 'Ур чадвараа нэмээрэй',
    skill_empty: 'Энэ хэрэглэгч хараахан ур чадвар нэмээгүй байна.',
    save_confirm_title:
      'Ур чадварын дэлгэрэнгүй мэдээллийг хадгалахыг баталгаажуулна уу',
    save_confirm_desc:
      'Та үргэлжлүүлэхийг хүсч байна уу? Хадгалахаасаа өмнө бүх мэдээлэл үнэн зөв эсэхийг шалгаарай.',
  },
  SKILL_REPORT: {
    position_filter_hint: 'Ажилчдыг албан тушаалаар шүүх',
  },
  RESOURCE_PLAN: {
    list_title: 'Хүний нөөцийн төлөвлөлтийн жагсаалт',
    title: 'Х/Н-н төлөвлөлт',
    menu_title: 'Хүний нөөцийн төлөвлөлт',
    name: 'Төлөвлөлтийн нэр',
    create: 'Үүсгэх',
    name_placeholder: 'Нэр',
    name_column: 'Нэр',
    confirmed_column: 'Зөвшөөрсөн',
    created_column: 'Үүсгэсэн',
    status_column: 'Төлөв',
    actions_column: 'Үйлдлүүд',
    create_success_title: 'Шинэ хүний нөөцийн төлөвлөлт үүслээ',
    create_success_desc: 'Таны хүний нөөцийн төлөвлөлт амжилттай нэмэгдлээ.',
    status_planning: 'Төлөвлөсөн',
    status_confirmed: 'Зөвшөөрсөн',
    confirm: 'Батлах',
    unconfirm: 'Цуцлах',
    total: 'Нийт',
    copy_modal_title: 'Та энэ төлөвлөлтийг хуулбарлахдаа итгэлтэй байна уу?',
    copy_modal_description:
      'Энэ процесс нь сонгосон төлөвлөлтийн хуулбарыг үүсгэх болно',
    copy_success_title: 'Амжилттай хуулбарлалаа',
    copy_success_description:
      'Таны хуулсан хүний нөөцийн төлөвлөлт амжилттай нэмэгдлээ.',
    delete_modal_title: 'Та итгэлтэй байна уу?',
    delete_modal_description: 'Энэ үйлдлийг буцаах боломжгүй.',
    confirm_plan_modal_title:
      'Та төлөвлөлтийг баталгаажуулахыг хүсэж байна уу?',
    confirm_plan_modal_description:
      'Баталгаажсан төлөвлөлтийг засах боломжгүй, гэхдээ дараа нь баталгаажуулалтыг цуцлах боломжтой.',
    unconfirm_modal_title: 'Та төлөвлөлтийг баталгаажуулахыг хүсэж байна уу?',
    unconfirm_modal_description: 'Та дараа баталгаажуулж болно.',
    filter_employees_dialog_title: 'Ажилтнаар шүүх',
    filter_employees_dialog_desc:
      'Жагсаалтаас харахыг хүсэхгүй байгаа албан тушаалуудыг сонгоно уу:',
    not_include: 'Ажилчдын нийт өртөг (Зарим албан тушаал ороогүй болно)',
    total_employee_cost: 'Ажилчдын нийт өртөг',
    target_month: 'Зорилтот сар',
    copy_dialog_title: ' Шинэ хуулбарынхаа зорилтот сарыг оруулна уу',
    source: 'Эх сурвалж: ',
    confirm_error_modal_title: 'Баталгаажуулж чадсангүй',
    empty_project_alert_title: 'Төслийн хоосон жагсаалт',
    empty_project_alert_desc:
      'Төслийн жагсаалт хоосон, сүлжээний өгөгдлийг харуулах боломжгүй.',
    error_alert_title: 'Алдаа',
    error_alert_desc:
      'Өгөгдлийн сүлжээний анхны өгөгдлийг ачаалахад алдаа гарлаа.',
    rename_title: 'Гарчгийн нэрийг өөрчлөх',
    calculate: 'тооцоолох',
    recalculate: 'дахин тооцоолох',
    calculate_modal_title: 'Та төлөвлөлтийг тооцоолохдоо итгэлтэй байна уу?',
    calculate_modal_description:
      'Тооцоолсон төлөвлөлтийг засах боломжгүй ч дараа нь өөрчлөх боломжтой.',
    new_employee_cell:
      'Шинэ ажилтан: 2-3 сар. Ойрын хугацаанд энэ ажилтны өртгийн талаар бодож үзээрэй.',
    modify_button: 'өөрчлөх',
    activity_log_button: 'Түүх',
    activity_history_title: 'Түүх',
    calculated_column: 'Тооцоолсон',
    confirm_add_user_title: 'Хүн нэмэх',
    confirm_add_user_desc: 'Та хүн нэмэхдээ итгэлтэй байна уу?',
    confirm_remove_user_title: 'Та эдгээр ажилчдыг хасахдаа итгэлтэй байна уу?',
    completed: 'дууссан',
  },
  RESOURCE_PLAN_DATAGRID: {
    employees: 'Employees',
    custom_position: 'Resource Position',
    total: 'Total',
  },
  RESOURCE_ACTIVITY: {
    created: 'Үүсгэсэн',
    rename: 'Гарчиг өөрчилсөн',
    confirm: 'Баталгаажуулсан',
    unconfirm: 'Цуцалсан',
    calculated: 'Тооцоолсон',
    updated: 'Шинэчлэсэн',
    unknown: '',
  },
  COST_REPORT: {
    filter_by_divisions: 'Түнш хэлтсээр шүүх',
    start_date: 'Эхлэх огноо',
    end_date: 'Дуусах огноо',
    month: 'Сар',
    date_range_label: 'Огноогоор',
    total_partner_division: 'Хэлтсийн нийт өртөг',
    purchased_count: 'Захиалсан тоо',
    position_by_division: 'Хэлтсийн албан тушаал',
    total_count: 'Нийт тоо',
    total_cost: 'Нийт өртөг',
    purchase_total_count: 'Төлөвлөсөн тоо',
    purchase_total_cost: 'Төлөвлөсөн өртөг',
    ordered_cost: 'Захиалсан Өртөг',
    partner_division: 'Түнш хэлтэс',
    division_name: 'Хэлтсийн нэр',
    actual_cost: 'Бодит өртөг',
    total_ordered_cost: 'Захиалсан өртөг',
    list_title: 'Өртөгийн тайлангууд',
  },
  ACTUAL_COST_REPORT: {
    title: 'Бодит өртөг',
    employee: 'Ажилтан',
    planned: 'Төлөвлөсөн',
    actual: 'Бодит',
    ordered: 'Захиалсан',
    positions: 'Албан тушаал',
    other: 'Бусад',
    total: 'Нийт',
    timeoff_days: 'Амарсан өдрүүд',
    work_dates: 'Ажлын өдрүүд: {{days}}',
    holidays: 'Амралтын өдрүүд: {{days}}',
    within_invoice: 'Нэхэмжлэлтэй төслийн тайлан',
    within_invoice_timeoff: 'Нэхэмжлэлтэй төслийн тайлан (амралт ороогүй)',
    actual_work_date: 'Ажлын тайлан',
    projects: 'Нийт төслийн тайлан (амралт ороогүй)',
    all_worked: 'Ажлын тайлан (амралт ороогүй)',
    detail_title: 'Бодит өртөгийн тайлангийн дэлгэрэнгүй',
  },
  MEETING_ROOM_ORDER: {
    title: 'Хурлын өрөө',
    side_content_title: 'Хурлын өрөө',
    start_time: 'Эхлэх цаг',
    end_time: 'Дуусах цаг',
    create_title: 'Хурлын өрөө захиалах',
    order_button: 'Цаг захиалах',
    delete: 'Устгах',
    delete_modal_title: 'Захиалга цуцлах',
    delete_modal_description: 'Та захиалгыг цуцлахдаа итгэлтэй байна уу?',
    subject: 'Сэдэв',
    meeting_room: 'Хурлын өрөө',
    create_success_title: 'Хурлын өрөө захиалга амжилттай',
    create_desc: '{{subject}} сэдвээр хурлын өрөө захиаллаа.',
    create_fail_title: 'Цаг давхцаж байна',
    create_fail_desc:
      'Энэ өрөөнд {{startTime}} - {{endTime}} хүртэл өөр уулзалт байна.',
    ordered_by: 'Үүсгэсэн',
    no_meeting_room: 'Хурлын өрөө олдсонгүй.',
    none: 'Давталтгүй',
    daily: 'Өдөр бүр',
    weekly: 'Долоо хоног бүр',
    monthly: 'Сар бүр',
    mon: 'Да',
    tue: 'Мя',
    wed: 'Лх',
    thu: 'Пү',
    fri: 'Ба',
    sat: 'Бя',
    sun: 'Ня',
    week: 'долоо хоног',
    month: 'сар',
    repeat_every: 'Давтах тоо',
    repeat_on: 'Давтах өдөр',
    days_of_month: 'Сарын өдрүүд',
    ends: 'Дуусах',
    expire_date: 'Дуусах огноо',
    frequency: 'Давтамж',
    is_recurring: 'Давтагдах',
    delete_all_title: 'Бүгдийг нь устгах уу?',
    delete_all_description: 'Хурлын өрөөний захиалгыг бүгдийг нь устгах уу?',
    change_all_title: 'Бүгдийг өөрчлөх үү?',
    change_all_description:
      'Хурлын өрөөний бүх захиалгыг өөрчлөх үү? Хэрэв та зөвхөн энэ захиалгын өөрчилвөл энэ өдрийн захиалга давтагдахгүй захиалга болно',
    only_this: ' Зөвхөн энийг',
    delete_all: 'Бүгдийг устгах',
    change_all: 'Бүгдийг өөрчлөх',
    help_title: 'Давтамж тохируулах заавар',
    help_text: '1. Давтах тоог оруулна\n2. Хугацааны нэгжийг сонгоно',
    example: 'Жишээ: 2 долоо хоногт нэг = Давтах тоо [2] Давтамж [Долоо хоног]',
    expire_required: 'Утга сонгоогүй үед: 1 жил',
    repeat_type: 'Давтамж',
    change_recurrence_title: 'Давтамжийн дүрмийг өөрчлөх',
    change_recurrence_description:
      'Хэрэв та энэ үйл явдлын давтамжийн дүрмийг өөрчилбөл, энэ болон дараагийн үйл явдлуудад нөлөөлөх болно.',
  },
  MEETING_ROOM: {
    meeting_room: 'Хурлын өрөө',
    list_title: 'Хурлын өрөөний жагсаалт',
    order: '№',
    name_column: 'Нэр',
    description_column: 'Тодорхойлолт',
    name_placeholder: 'Хурлын өрөөний нэр',
    description_placeholder: 'Хурлын өрөөний тодорхойлолт',
    change_order: 'Дараалал өөрчлөх',
    save_order: 'Дараалал хадгалах',
    delete_modal_title: 'Та итгэлтэй байна уу?',
    delete_modal_description: 'Энэ процессыг буцаах боломжгүй.',
    create_success_title: 'Шинэ хурлын өрөө нэмэгдсэн',
    create_success_desc: 'Шинэ хурлын өрөө нэмлээ',
    create_title: 'Хурлын өрөө нэмэх',
    save_confirm_title:
      'Хэлтсийн дэлгэрэнгүй мэдээллийг хадгалахыг баталгаажуулна уу',
    save_confirm_desc:
      'Та үргэлжлүүлэхийг хүсч байна уу? Хадгалахаасаа өмнө бүх мэдээлэл үнэн зөв эсэхийг шалгаарай.',
  },
  SYSTEM_SETTINGS: {
    system_settings: 'Системийн тохиргоо',
    name: 'Нэр',
    value: 'Утга',
    value_type: 'Утгын төрөл',
    number: 'Тоо',
    string: 'Үг',
    time: 'Цаг',
    date: 'Огноо',
    json: 'JSON',
    save_confirm_title: 'Системийн тохиргоог батлах',
    save_confirm_desc:
      'Та үргэлжлүүлэхийг хүсч байна уу? Хадгалахаасаа өмнө бүх мэдээлэл үнэн зөв эсэхийг шалгаарай.',
    cancel_confirm_title: 'Системийн тохиргоог цуцлах',
    cancel_confirm_desc: 'Хадгалахаас өмнө гарвал таны өөрчлөлт устах болно.',
  },
  APPROVAL_TEMPLATES: {
    title: 'Зөвшөөрлийн загварууд',
    name: 'Нэр',
    name_placeholder: 'Зөвшөөрлийн загварын нэр',
    status: 'Статус',
    active: 'Идэвхтэй',
    inactive: 'Идэвхгүй',
    details_title: 'Зөвшөөрлийн загварын дэлгэрэнгүй',
    change_step: 'Алхам солих',
    step: 'Алхам',
    is_manual: 'Гар ажиллагаатай',
    positions: 'Албан тушаалууд',
    order_title: 'Алхамын эрэмбэ солих',
    save_order: 'Эрэмбээ хадгалах',
    is_manual_placeholder: 'Гар ажиллагаатай эсэх',
    positions_placeholder: 'Албан тушаалууд',
    save_confirm_title: 'Зөвшөөрлийн загварын дэлгэрэнгүйг баталгаажуулах',
    create: 'Зөвшөөрлийн загвар үүсгэх',
    create_step: 'Зөвшөөрлийн загварын алхам үүсгэх',
    save_title: 'Зөвшөөрлийн загварыг баталгаажуулах',
    save_desc:
      'Үргэлжлүүлэх үү? Хадгалагдаж буй зөвшөөрлийн загварын нэр зөв байгаа эсэхийг шалгана уу.',
    save_confirm_desc:
      'Үргэлжлүүлэх үү? Бүх дэлгэрэнгүй нь зөв байгаа эсэхийг шалгана уу.',
    cancel_confirm_title: 'Та юу ч засварлаж байна гэж үзээгүй байна.',
    cancel_confirm_desc:
      'Хадгалахгүй орохын тулд өөрчлөлтийн өөрчлөлтүүд алга болно.',
    create_success_title: 'Шинэ зөвшөөрлийн загвар үүсгэгдлээ.',
    create_success_desc:
      'Нэр: {{name}} талбартай шинэ зөвшөөрлийн загвар үүсгэгдлээ.',
    create_detail_success_title: 'Шинэ алхам нэмэгдлээ',
    create_detail_success_desc:
      'ID: {{id}} болон алхам: {{step}} талбартай шинэ алхам нэмэгдлээ.',
    delete_modal_title: 'Зөвшөөрлийн загварыг устгах',
    delete_modal_description:
      'Энэ зөвшөөрлийн загварыг устгахдаа итгэлтэй байна уу?',
    allow_reason: 'Цуцлах шалтгаан',
  },
  VACATION_BALANCES: {
    title: 'Амралтын үлдэгдэл',
    employees: 'Ажилтан',
    hired_date: 'Ажилд орсон огноо',
    ndsh_before: 'Өмнөх НДШ',
    ndsh_total: 'Нийт НДШ',
    days: 'Нийт хоног',
    remaining_days: 'Үлдсэн хоног',
    used_days: 'Ашигласан хоног',
    create: 'Нэмэх',
    active: 'Идэвхитэй',
    status: 'Статус',
    inactive: 'Идэвхгүй',
    year_placeholder: 'Амралтын жил',
    user_placeholder: 'Ажилтан',
    days_placeholder: 'Нийт хоног',
    remaining_days_placeholder: 'Үлдсэн хоног',
    start_at_placeholder: 'Эхлэх огноо',
    end_at_placeholder: 'Дуусах огноо',
    create_success_title: 'Ажилтны амралтын мэдээлэл нэмэгдлээ',
    create_success_desc:
      'Ажилтны амралтын мэдээлэл нэмэгдлээ: {{year}} жилтэй, хоногтой: {{days}}',
    calculate: 'Тооцоо хийх',
    save_confirm_title: 'Ажилтны амралтыг хадгалахыг батлах',
    save_confirm_desc:
      'Цааш үргэлжлүүлэх үү? Хадгалахаас өмнө бүх мэдээлэл зөв эсэхийг шалгана уу.',
    add_user: 'Ажилтан нэмэх',
    dialog_title: 'Ажилтаны амралтын үлдэгдэл тооцох',
    all_employee: 'Бүх ажилтанд тооцох',
    new_employee: 'Энэ ажилтны амралтын үлдэгдэл тооцох ёстой.',
    remaining_days_total: 'Нийт үлдэгдэл хоног',
    user_history_dialog_title: '{{year}} оны амралтын дэлгэрэнгүй',
    start_date: 'Эхлэх огноо',
    end_date: 'Дуусах огноо',
    timeoff_type: 'Хүсэлтийн төрөл',
    duration: 'Хугацаа',
    total_vacation_days: 'Нийт хоног',
  },
  VACATION_REPORT: {
    report_title: 'Амралтын тайлан',
    report_summary: 'Нийт амралт',
    pre_paid: 'Олговроор',
    post_paid: 'Цалингаар',
    vacation_report: 'Ажилчдын амралтын тойм',
    employee: 'Ажилчид',
    remaining_days: 'Үлдсэн хоног',
    used_days: 'Ашигласан хоног',
    year: 'Жил',
    more: 'Дэлгэрэнгүй',
    type: 'Амралтын төрөл',
    nodata: 'Үр дүн олдсонгүй.',
  },
  VACATION_INFO: {
    show: 'Амралтын мэдээлэл харах',
    hide: 'Амралтын мэдээлэл нуух',
    employee: 'Ажилтны дугаар/Нэр:',
    hired_date: 'Ажилд орсон огноо:',
    ndsh_months_before: 'НДШ төлсөн сар /Ажилд орохоос өмнө/:',
    ndsh_months_total: 'НДШ төлсөн сар /Нийт/:',
    vacation_days: 'Амралтын эрх:',
    remaining_days: 'Боломжит үлдэгдэл:',
    info: 'Ажилтан хөдөлмөрийн хэвийн нөхцөлд ажилласан жилээс хамаараад үндсэн амралт дээр нь дараах нэмэгдэл хоног амрах эрх эдэлнэ. Үндсэн амралт - {{day}} өдөр.',
    yearly: '{{year}} болон түүнээс дээш жил: {{day}} өдөр',
    month: '{{month}} сар',
    days_in_year: '{{day}} хоног ({{year}}), ',
    compensatory_balance: 'Нөхөн амрах эрх:',
    year: 'Жил',
    table_vacation_days: 'Амрах эрх',
    table_remaining_days: 'Үлдэгдэл хоног',
  },
  TOASTS: {
    forgot_password_fulfilled: 'Баталгаажуулах кодыг таны имэйл рүү илгээсэн.',
    forgot_password_rejected: 'Хүсэлт илгээж чадсангүй.',
    password_reset_fulfilled: 'Таны нууц үгийг шинэчилсэн байна',
    password_reset_rejected: 'Нууц үгийг дахин тохируулж чадсангүй',
    change_password_fulfilled: 'Таны нууц үг амжилттай өөрчлөгдсөн байна.',
    change_password_rejected: 'Нууц үгийг өөрчилж чадсангүй',
    switch_lang_rejected:
      'Хэрэглэгчийн хэлний сонголтийг хадгалахад алдаа гарлаа',
    // Company
    create_company_rejected: 'Компани нэмж чадсангүй',
    update_company_fulfilled: 'Компани амжилттай шинэчлэгдсэн',
    update_company_rejected: 'Компанийг шинэчилж чадсангүй',
    delete_company_fulfilled: 'Компанийг амжилттай устгасан',
    delete_company_rejected: 'Компанийг устгаж чадсангүй',
    // Division
    update_division_fulfilled: 'Хэлтэс амжилттай шинэчлэгдсэн.',
    update_division_rejected: 'Хэлтсийг шинэчилж чадсангүй.',
    create_division_fulfilled: 'Хэлтэс амжилттай нэмэгдсэн.',
    create_division_rejected: 'Хэлтэс нэмж чадсангүй.',
    delete_division_fulfilled: 'Хэлтэс амжилттай устсан.',
    delete_division_rejected: 'Хэлтэс устгаж чадсангүй.',
    update_division_order_rejected: 'Дарааллыг шинэчилж чадсангүй',
    update_division_order_fulfilled: 'Хэлтсийн дараалал шинэчлэгдсэн',
    // Position
    update_position_fulfilled: 'Албан тушаал амжилттай шинэчлэгдсэн.',
    update_position_rejected: 'Албан тушаалыг шинэчилж чадсангүй.',
    delete_position_fulfilled: 'Албан тушаалыг амжилттай устгасан.',
    delete_position_rejected: 'Албан тушаалыг устгаж чадсангүй.',
    update_position_order_rejected: 'Дарааллыг шинэчилж чадсангүй',
    update_position_order_fulfilled: 'Албан тушаалын дараалал шинэчлэгдсэн',
    // Department
    create_department_fulfilled: 'Хэлтэс амжилттай нэмэгдсэн.',
    create_department_rejected: 'Хэлтэс нэмж чадсангүй.',
    update_department_fulfilled: 'Хэлтэс амжилттай шинэчлэгдсэн.',
    update_department_rejected: 'Хэлтсийг шинэчилж чадсангүй.',
    delete_department_fulfilled: 'Хэлтэс амжилттай устсан.',
    delete_department_rejected: 'Хэлтсийг устгаж чадсангүй.',
    // Project
    delete_project_fulfilled: 'Төслийг амжилттай устгасан.',
    delete_project_rejected: 'Төслийг устгаж чадсангүй.',
    create_user_rejected: 'Гишүүн нэмж чадсангүй.',
    create_user_fulfilled: 'Гишүүн амжилттай нэмэгдсэн.',
    update_user_rejected: 'Гишүүнийг шинэчилж чадсангүй.',
    update_user_fulfilled: 'Гишүүн амжилттай шинэчлэгдсэн.',
    delete_project_user_rejected: 'Төслийн гишүүнийг устгаж чадсангүй.',
    delete_project_user_fulfilled: 'Төслийн гишүүнийг амжилттай устгасан.',
    update_project_fulfilled: 'Төсөл амжилттай шинэчлэгдлээ',
    update_project_rejected: 'Төслийг шинэчилж чадсангүй.',
    update_project_image_fulfilled: 'Төслийн зургийг амжилттай байршууллаа.',
    update_project_image_rejected: 'Төслийн зургийг байршуулж чадсангүй.',
    //Partner purchase order
    create_partner_purchase_order_fulfilled:
      'Түншийн худалдан авалтын захиалгыг амжилттай нэмлээ.',
    create_partner_purchase_order_rejected:
      'Түншийн худалдан авалтын захиалгыг нэмж чадсангүй.',
    update_partner_purchase_order_fulfilled:
      'Түншийн худалдан авалтын захиалгыг амжилттай шинэчилсэн.',
    update_partner_purchase_order_rejected:
      'Түншийн худалдан авалтын захиалгыг шинэчилж чадсангүй.',
    delete_partner_purchase_order_fulfilled:
      'Түншийн худалдан авалтын захиалгыг амжилттай устгасан.',
    delete_partner_purchase_order_rejected:
      'Түншийн худалдан авалтын захиалгыг устгаж чадсангүй.',
    // Role
    delete_role_rejected: 'Хандах эрх устгаж чадсангүй.',
    delete_role_fulfilled: 'Хандах амжилттай устгасан.',
    update_role_rejected: 'Хандах эрх шинэчилж чадсангүй.',
    update_role_fulfilled: 'Хандах эрх амжилттай шинэчилсэн.',
    // Event
    event_update_fulfilled: 'Үйл явдал шинэчлэгдсэн',
    event_update_rejected: 'Шинэчилж чадсангүй',
    event_delete_rejected: 'Устгаж чадсангүй',
    event_delete_fulfilled: 'Үйл явдлыг устгасан',
    // Skill
    update_skill_rejected: 'Шинэчилж чадсангүй',
    update_skill_fulfilled: 'Ур чадвар шинэчлэгдсэн',
    delete_skill_rejected: 'Устгаж чадсангүй',
    delete_skill_fulfilled: 'Ур чадварыг устгасан',
    // Skill category
    update_skill_category_rejected: 'Шинэчилж чадсангүй',
    update_skill_category_fulfilled: 'Ур чадварын ангилал шинэчлэгдсэн',
    update_skill_category_order_rejected: 'Дарааллыг шинэчилж чадсангүй',
    update_skill_category_order_fulfilled:
      'Ур чадварын ангиллын дараалал шинэчлэгдсэн',
    delete_skill_category_rejected: 'Устгаж чадсангүй',
    delete_skill_category_fulfilled: 'Ур чадварын ангиллыг устгасан',
    // User skill
    delete_user_skill_by_id_rejected: 'Устгаж чадсангүй',
    delete_user_skill_by_id_fulfilled: 'Ур чадварыг устгасан',
    update_user_skills_rejected: 'Шинэчилж чадсангүй',
    update_user_skills_fulfilled: 'Ур чадвар шинэчлэгдэж байна',
    update_single_skill_rejected: 'Шинэчилж чадсангүй',
    update_single_skill_fulfilled: 'Ур чадвар шинэчлэгдсэн',
    // Resource Plan
    create_resource_plan_rejected: 'Шинэ төлөвлөлт үүсгэж чадсангүй',
    rename_resource_plan_fulfilled: 'Нэр амжилттай өөрчилсөн',
    rename_resource_plan_rejected: 'Төлөвлөлтийн нэрийг өөрчилж чадсангүй',
    confirm_resource_plan_fulfilled: 'Амжилттай баталлаа',
    confirm_resource_plan_rejected: 'Төлөвлөлтийг баталгаажуулж чадсангүй',
    refuse_resource_plan_fulfilled: 'Амжилттай цуцлалаа',
    refuse_resource_plan_rejected: 'Төлөвлөлтийг баталгаажуулж чадсангүй',
    delete_resource_plan_fulfilled: 'Амжилттай устгасан',
    delete_resource_plan_rejected: 'Төлөвлөлтийг устгаж чадсангүй',
    // Employee
    update_employee_rejected: 'Шинэчилж чадсангүй',
    update_employee_fulfilled: 'Ажилтан шинэчлэгдсэн',
    delete_employee_rejected: 'Устгаж чадсангүй',
    delete_employee_fulfilled: 'Ажилтныг устгасан',
    quit_employee_rejected: 'Гарах маягтыг илгээж чадсангүй',
    quit_employee_fulfilled: 'Гарах маягтыг илгээлээ.',
    cell_update_failed: 'Нүдний шинэчлэл амжилтгүй боллоо ',
    calculate_resouse_plan_fulfilled:
      'Хүний нөөцийн төлөвлөлтийг амжилттай тооцооллоо',
    update_quit_reason_rejected: 'Шинэчилж чадсангүй',
    update_quit_reason_fulfilled: 'Ажлаас гарсан шалтгаан шинэчлэгдсэн',

    reset_password_employee_fulfilled:
      'Ажилтны нууц үг амжилттай шинэчлэгдлээ.',
    reset_password_employee_rejected: 'Ажилтны нууц үгийг шинэчилж чадсангүй.',
    // Meeting room
    create_meeting_room_fulfilled: 'Хурлын өрөөг амжилттай нэмлээ.',
    create_meeting_room_rejected: 'Хурлын өрөө нэмж чадсангүй.',
    update_meeting_room_fulfilled: 'Хурлын өрөөг амжилттай шинэчилсэн.',
    update_meeting_room_rejected: 'Хурлын өрөөг шинэчилж чадсангүй.',
    delete_meeting_room_fulfilled: 'Хурлын өрөөг амжилттай устгасан.',
    delete_meeting_room_rejected: 'Хурлын өрөөг устгаж чадсангүй.',
    meeting_room_update_order_rejected: 'Дарааллыг шинэчилж чадсангүй',
    meeting_room_update_order_fulfilled: 'Хурлын өрөөний дараалал шинэчлэгдсэн',
    // Meeting room order
    create_meeting_room_order_rejected: 'Хурлын өрөө захиалж чадсангүй.',
    update_meeting_room_order_fulfilled:
      'Хурлын өрөө захиалгыг амжилттай шинэчилсэн.',
    update_meeting_room_order_rejected:
      'Хурлын өрөө захиалгыг шинэчилж чадсангүй.',
    delete_meeting_room_order_fulfilled:
      'Хурлын өрөө захиалгыг амжилттай устгасан.',
    delete_meeting_room_order_rejected:
      'Хурлын өрөө захиалгыг устгаж чадсангүй.',
    update_system_settings_fulfilled:
      'Системийн тохиргоог амжилттай шинэчилсэн.',
    update_system_settings_rejected: 'Системийн тохиргоог шинэчилж чадсангүй.',
    //Email campaign
    create_email_campaign_fulfilled: 'Имэйлийн campaign амжилттай нэмэгдсэн.',
    create_email_campaign_rejected: 'Имэйл campaign нэмж чадсангүй.',
    update_email_campaign_fulfilled:
      'Имэйлийн campaign амжилттай шинэчлэгдсэн.',
    update_email_campaign_rejected: 'Имэйлийн campaign шинэчилж чадсангүй.',
    delete_email_campaign_fulfilled: 'Имэйлийн campaign амжилттай устгасан.',
    delete_email_campaign_rejected: 'Имэйл campaign устгаж чадсангүй.',
    send_bulk_failed: 'Алдаа гарлаа',
    send_bulk_success: 'Амжилттай илгээлээ',
    //Email template
    create_email_template_fulfilled: 'Имэйлийн загварыг амжилттай нэмлээ.',
    create_email_template_rejected: 'Имэйлийн загвар нэмж чадсангүй.',
    update_email_template_fulfilled: 'Имэйлийн загварыг амжилттай шинэчилсэн.',
    update_email_template_rejected: 'Имэйлийн загварыг шинэчилж чадсангүй.',
    delete_email_template_fulfilled: 'Имэйлийн загварыг амжилттай устгалаа.',
    delete_email_template_rejected: 'Имэйлийн загварыг устгаж чадсангүй.',
    unknown_rejected: 'Something went wrong',
    //Timeoff template
    update_timeoff_template_rejected: 'Амралтын загварыг шинэчлэж чадсангүй.',
    update_timeoff_template_fulfilled:
      'Амралтын загварыг амжилттай шинэчлэлээ.',
    delete_timeoff_template_rejected: 'Амралтын загварыг устгаж чадсангүй.',
    delete_timeoff_template_fulfilled: 'Амралтын загварыг амжилттай устгав.',
    //Approval template
    update_approval_template_fulfilled:
      'Зөвшөөрлийн загвар амжилттай шинэчилсэн.',
    update_approval_template_rejected:
      'Зөвшөөрлийн загварыг шинэчилж чадсангүй.',
    delete_approval_template_fulfilled:
      'Зөвшөөрлийн загвар амжилттай устгагдлаа.',
    delete_approval_template_rejected: 'Зөвшөөрлийн загварыг устгаж чадсангүй.',
    //Approval template details
    update_approval_template_details_fulfilled:
      'Зөвшөөрлийн загварын дэлгэрэнгүй амжилттай шинэчилсэн.',
    update_approval_template_details_rejected:
      'Зөвшөөрлийн загварын дэлгэрэнгүйг шинэчилж чадсангүй.',
    delete_approval_template_details_fulfilled:
      'Зөвшөөрлийн загварын дэлгэрэнгүй амжилттай устгагдлаа.',
    delete_approval_template_details_rejected:
      'Зөвшөөрлийн загварын дэлгэрэнгүй устгаж чадсангүй.',
    update_approval_template_step_fulfilled:
      'Зөвшөөрлийн загварын алхамуудыг амжилттай шинэчиллээ.',
    update_approval_template_step_rejected:
      'Зөвшөөрлийн загварын алхамуудыг шинэчилж чадсангүй.',
    create_approval_template_step_rejected:
      'Зөвшөөрлийн загварын алхамуудыг үүсгэж чадсангүй.',

    // Timeoff request
    delete_timeoff_request_fulfilled:
      'Амралт чөлөөний хүсэлт амжиллтай устгагдлаа.',
    delete_timeoff_request_rejected:
      'Амралт чөлөөний хүсэлтийг устгаж чадсангүй.',
    time_off_request_failed: 'Амралт чөлөөний хүсэлт илгээж чадсангүй.',
    calculate_vacation_balance_fulfilled:
      'Амралтын мэдээлэл амжилттай тооцогдлоо',
    calculate_vacation_balance_rejected:
      'Амралтын мэдээллийг тооцоолж чадсангүй.',
    // daily report
    daily_report_update_fulfilled: 'Тайлан шинэчлэгдлээ',
    daily_report_update_rejected: 'Тайлан шинэчлэж чадсангүй',
    daily_report_create_rejected: 'Тайлан үүсгэж чадсангүй',
    time_correction_create_rejected: 'Хүсэлт амжилгүй',
    time_correction_update_rejected: 'Засах хүсэлт амжилтгүй',
    approve_time_correction_rejected: 'Цагийн засварыг баталж чадсангүй',
    deny_time_correction_rejected: 'Цагийн засварыг татгалзах амжилтгүй боллоо',
    time_correction_template_fulfulled:
      'Цаг засварын загвар амжилттай шинэчлэгдлээ',
    time_correction_template_rejected:
      'Цаг засварын загварыг шинэчилж чадсангүй',
    company_rules_update_fulfulled: 'Дотоод журмыг амжилттай шинэчиллээ',
    company_rules_update_rejected: 'Дотоод журмыг шинэчлэхэд амжилтгүй боллоо',
    workspace_verify_fulfulled: 'Ажлын байрыг амжилттай өөрчиллөө',
    workspace_verify_rejected: 'Ажлын байрыг өөрчилж чадсангүй',
    regenerate_workspace_token_fulfilled:
      'Ажлын байрны UID-г амжилттай шинэчиллээ',
    regenerate_workspace_token_rejected:
      'Ажлын байрны UID-г шинэчлэх амжилтгүй боллоо',
    update_workspace_fulfilled: 'Ажлын байрыг ажилттай шинэчиллээ',
    update_workspace_rejected: 'Ажлын байрыг шинэчилж чадсангүй',
    delete_workspace_fulfilled: 'Ажлын байрыг ажилттай устгалаа',
    update_remote_work_settings_fulfilled:
      'Зайнаас ажиллах тохиргоог амжилттай шинэчиллээ',
    update_remote_work_settings_rejected:
      'Зайнаас ажиллах тохиргоог шинэчлэхэд амжилтгүй боллоо',
    delete_remote_work_settings_fulfilled:
      'Зайнаас ажиллах тохиргоог амжилттай устгалаа',
    delete_remote_work_settings_rejected:
      'Зайнаас ажиллах тохиргоог устгахад амжилтгүй боллоо',
    timeqr_start_fulfilled: 'Successfully work started from office',
    timeqr_end_fulfilled: 'Successfully work ended from office',
    delete_remote_work_request_fulfilled:
      'Зайнаас ажиллах хүсэлтийг амжилттай устгалаа',
    delete_remote_work_request_rejected:
      'Зайнаас ажиллах хүсэлтийг устгахад амжилтгүй боллоо',
    update_remote_work_request_fulfilled:
      'Зайнаас ажиллах хүсэлтийг амжилттай шинэчиллээ',
    update_remote_work_request_rejected:
      'Зайнаас ажиллах хүсэлтийг шинэчлэх амжилтгүй боллоо',
    create_remote_work_settings_rejected:
      'Зайнаас ажиллах хүсэлтийг идэвхгүй болгож чадсангүй',
    update_user_compensatory_days_fulfilled:
      'Нөхөн амрах өдрийг амжилттай шинэчиллээ',
    update_user_compensatory_days_rejected:
      'Нөхөн амрах өдрийг шинэчилж чадсангүй',
    update_tag_rejected: 'Шинэчилж чадсангүй',
    update_tag_fulfilled: 'Ажлын төрөл  шинэчлэгдсэн',
    delete_tag_rejected: 'Устгаж чадсангүй',
    delete_tag_fulfilled: 'Ажлын төрлийг устгасан',
    create_tag_rejected: 'Ажлын төрөл үүсгэж чадсангүй',
  },
  UPLOAD: {
    title: 'Файл хуулах',
    upload: 'хуулах',
    file: 'Файл хуулах',
  },
  EMAIL: {
    templates: 'Загварууд',
    campaigns: 'Campaign',
    email_settings: 'Имэйлийн тохиргоо',
    edit_design: 'Дизайн засварлах',
    title: 'Имэйл',
    send_email: 'Имэйл илгээх',
    send_email_title: 'Эхлэхийн тулд загвар сонгоно',
    create_template: 'Загвар үүсгэх',
    use_template: 'Загвар ашиглах',
    template_name: 'Загварын нэр',
    subject: 'Имэйлийн сэдэв',
    template_type: 'Загварын төрөл',
    bulk: 'Бөөнөөр',
    salary: 'Цалин',
    recipient: 'Имэйл хүлээн авагч',
    content: 'Имэйлийн контент',
    send_warning_title: 'Илгээх боломжгүй',
    send_warning_msg: 'Танд имэйл илгээх зөвшөөрөл байхгүй байна.',
    unsaved_title: 'Танд хадгалагдаагүй өөрчлөлтүүд байна',
    unsaved_desc: 'Хадгалахаасаа өмнө буцахыг хүсэж байна уу?',
    back: 'Тохиргоо руу буцах',
    save: 'Дизайныг хадгалах',
    desktop: 'Компьютер',
    mobile: 'Гар утас',
    empty_content: 'Хоосон контент',
    step_one: 'Загвар сонгох',
    step_settings: 'Тохиргоог засах',
    step_import_data: 'Цалингийн мэдээллийг оруулах',
    step_sent: 'Дууссан',
    send_salary: 'Имэйл илгээх',
    desktop_version: 'Компьютерийн хувилбар',
    mobile_version: 'Гар утасны хувилбар',
    import_data_dialog: {
      row_span_config: 'Мөр хоорондын тохиргоо',
      col_span_config: 'Багана хоорондын тохиргоо',
      add_row_span_config: 'Мөр хоорондын тохиргоог нэмэх',
      add_col_span_config: 'Багана хоорондын тохиргоог нэмэх',
      row: 'Мөр',
      column: 'Багана',
      row_span: 'Мөрийн зай',
      col_span: 'Баганын зай',
      other: 'Бусад',
      other_than: 'Энэнээс бусад',
      data_index: 'Өгөгдлийн индекс',
      data_slice: 'Өгөгдлийн хуваалт',
      user_code_index: 'User Code индекс',
    },
    completed_title: 'Энэ campaign дууссан',
    completed_desc:
      'Хэрэв та дахин имэйл илгээхийг хүсвэл шинэ campaign үүсгэнэ үү',
    completed_create: 'Шинэ Campaign үүсгэх',
    recipients: 'Хүлээн авагчид',
    sent: 'Илгээх',
    failed: 'Амжилтгүй',
  },
  EMAIL_CAMPAIGN: {
    campaign_create_title: 'Campaign үүсгэх',
    display_order: 'Дараалал',
    status_draft: 'Ноорог',
    status_sent: 'Явуулсан',
    status_failed: 'Яваагүй',
    status_all: 'Бүгд',
    status_pending: 'Хүлээлт',
    name_column: 'Нэр',
    created_at_column: 'Үүсгэсэн',
    status_column: 'Төлөв',
    template_name_column: 'Загварын нэр',
    template_type: 'Загварын төрөл',
    delete: 'Email campaign устгах',
    delete_modal_title: 'Email campaign устгах',
    delete_modal_description:
      'Энэ Email campaign-г устгахдаа итгэлтэй байна уу?',
    save_confirm_title: 'Email Campaign нэрийг баталгаажуулна уу.',
    save_confirm_desc:
      'Цааш үргэлжлүүлэх үү? Хадгалахаас өмнө бүх мэдээлэл зөв эсэхийг шалгана уу.',
    create_success_title: 'Шинээр Email Campaign нэмэгдлээ',
    create_success_desc:
      'Шинээр Email Campaign нэмэгдлээ id: {{id}}, нэр: {{name}}',
    name_placeholder: 'Email Campaign нэр',
    send: 'Имейл илгээх',
    import_data: 'Дата оруулах',
    settings: 'Campaign тохиргоо',
    campaign_name: 'Campaign нэр',
    send_confirm_title: 'Та имэйл илгээхдээ итгэлтэй байна уу',
    send_confirm_desc:
      'Та үргэлжлүүлэхийг хүсэж байна уу? Нийт {{count}} хүлээн авагч руу илгээж байна. Илгээхийн өмнө бүх мэдээлэл үнэн зөв эсэхийг шалгаарай.',
    send_confirm_bulk_desc:
      'Та үргэлжлүүлэхийг хүсэж байна уу? {{recipient}} рүү илгээж байна. Бүх өгөгдөл зөв эсэхийг шалгаарай.',
    fail_log_title:
      'Campaign-д зориулсан хүлээн авагчийн бүртгэл амжилтгүй болсон',
    mark_as_complete: 'Дуусгах',
    complete_title: 'Дуусгаcан гэж баталгаажуулах',
    complete_desc:
      'Та энэ Email Campaign-ийг дуусгахад итгэлтэй байна уу? Энэ үйлдэл нь Email Campaign-ийг хааж, статусыг нь "Дууссан" болгож өөрчилнө. Дууссан гэж тэмдэглэсэн бол энэ Email Campaign-ийг ашиглан өөр үйлдэл хийх боломжгүй болно.',
  },
  EMAIL_TEMPLATE: {
    thumbnail_column: 'Зураг',
    name_column: 'Нэр',
    created_at_column: 'Үүсгэсэн',
    updated_at_column: 'Шинэчлэгдсэн',
    created_by_column: 'Үүсгэсэн хүн',
    delete: 'Имэйлийн загварыг устгах',
    delete_modal_title: 'Имэйлийн загварыг устгах',
    delete_modal_description:
      'Та энэ имэйлийн загварыг устгахдаа итгэлтэй байна уу?',
    save_confirm_title:
      'Имэйлийн загварын дэлгэрэнгүй мэдээллийг хадгалахыг баталгаажуулах',
    save_confirm_desc:
      'Та үргэлжлүүлэхийг хүсч байна уу? Хадгалахаасаа өмнө бүх мэдээлэл үнэн зөв эсэхийг шалгаарай.',
  },
  EMAIL_SETTINGS: {
    name: 'Нэр',
    edit_name_button: 'Нэр засах',
    template_name: 'Загварын нэр',
    template_type: 'Загварын төрөл',
    to: 'руу',
    add_recipient_button: 'Хүлээн авагч нэмэх',
    empty_recipient_text: 'Та энэ имэйлийг хэнд илгээж байна вэ?',
    recipients: 'Хүлээн авагчид',
    send_to_all: 'Бүх хүн рүү илгээх',
    type: 'Төрөл',
    edit_type_button: 'Төрөл засах',
    subject: 'Сэдэв',
    edit_subject_button: 'Сэдэв засах',
    not_value_text: 'Энэ имэйлийн гарчиг юу вэ?',
    subject_placeholder: 'Сэдвийг бичих',
    subject_word_warning: '9-өөс илүүгүй үг ашиглахыг хичээгээрэй',
    subject_char_warning: '60-аас дээш тэмдэгт ашиглахаас зайлсхийгээрэй',
    content: 'Контент',
    edit_content_button: 'Дизайныг засах',
    view_content_button: 'Дизайныг харах',
    content_placeholder: 'Өөрийн имэйлийн контентыг загварлах.',
    campaign_name: 'Campaign нэр',
    all_employee: 'Бүх ажилчид',
    position_select_all:
      'Бүлэг тус бүрийн бүх ажилчдыг сонгохын тулд та албан тушаалыг шалгаж болно.',
    thumbnail: 'Зураг',
    thumbnail_placeholder: 'Загварынхаа зургийг байршуулах.',
    view_thumbnail_button: 'Зураг харах',
    edit_thumbnail_button: 'Зураг засах',
    thumbnail_succesfully_changed: 'Загварын зураг амжилттай шинэчилсэн.',
    preview: 'Урьдчилан харах',
  },
  EMAIL_DESIGN: {
    content: 'Контент',
    dynamic_content: 'Динамик контент',
    salary_table: 'Цалингийн хүснэгт',
    layout: 'Бүдүүвч',
    save_confirm_title: 'Өгөгдөл хадгалагдсан',
    save_confirm_desc: 'Тохиргооны хуудас руу буцах уу?',
    confirmation_text: 'Үргэлжлүүлэх',
    cancellation_text: 'Үргэлжлүүлэн засварлах',
    mobile_edit_warning: 'Зөвхөн компьютероос орж засварлах боломжтой.',
  },
  TIMEOFF_TEMPLATE: {
    special: 'Онцгой амралт',
    menu_title: 'Амрах хүсэлтийн загварууд',
    list_title: 'Амрах хүсэлтийн загварууд',
    name: 'Нэр',
    create_title: 'Амрах хүсэлтийн загвар үүсгэх',
    approval_template: 'Зөвшөөрөл олгох загвар',
    description: 'Тайлбар',
    pay_status: 'Цалин олгох төлөв',
    range_type: 'Цагийн төрөл',
    identifier_label: 'Загварын төрөл',
    identifier_other: 'Үндсэн',
    identifier_mytime: 'My Time',
    identifier_holidaywork: 'Амралтын өдөр ажиллах',
    identifier_workation: 'Ээлжийн амралттай үед ажиллах',
    identifier_compensatory_request: 'Нөхөн амрах хүсэлт',
    is_request_send_later: 'Дараа хүсэлт явуулж болно',
    is_long_term_leave: 'Урт хугацааны амралт',
    is_deduct_from_vacation: 'Амралтын хоногоос хасах',
    is_attachment: 'Файл оруулж болно',
    is_reason_allow: 'Шалтгаан бичиж болно',
    pay_status_pre: 'Урьдчилсан',
    pay_status_post: 'Дараа',
    pay_status_non_paid: 'Цалингүй',
    range_type_time: 'Цагаар',
    range_type_am: 'Хагас өдөр үдээс өмнө',
    range_type_pm: 'Хагас өдөр үдээс хойш',
    range_type_day: 'Өдрөөр',
    delete_modal_title: 'Амрах хүсэлтийн загвар устгах',
    delete_modal_description:
      'Амрах хүсэлтийн загвар устгахдаа итгэлтэй байна уу?',
    create_success_title: 'Шинэ амрах хүсэлтийн загвар нэмэгдлээ',
    create_success_desc: '{{name}} нэртэй амрах хүсэлтийн загвар үүсгэлээ',
    save_confirm_title: 'Амрах хүсэлтийн загварыг баталгаажуулах',
    save_confirm_desc:
      'Үргэлжлүүлэх үү? Хадгалахаасаа өмнө бүх мэдээлэл зөв эсэхийг шалгана уу.',
    is_show_time_report: 'Цагийн тайланд оруулах',
    duration_max: 'Хамгийн их хугацаа',
    duration_min: 'Хамгийн бага хугацаа',
  },
  TIMEOFF_REQUEST: {
    current_status: 'Одоогийн төлөв : ',
    duration_hour: ' цаг',
    duration_min: ' минут',
    half_day_am_duration: 'Хагас өдөр үдээс өмнө',
    half_day_pm_duration: 'Хагас өдөр үдээс хойш',
    total_remaining_comp_balance: 'Нийт үлдэгдэл: ',
    total_remaining_comp_balance_desc:
      'Нийт үлдсэн нөхөн амрах хоног / Нийт үүссэн нөхөн амрах хоног',
    selected_day_info: 'Ашиглах гэж буй хоног: ',
    selected_day_info_desc:
      'Календараас сонгосон хоног / Жагсаалтнаас сонгосон хоног',
    estimated_remaining_compdays:
      'Үүний дараа танд үлдэх нөхөн амрах хоног: {{days}}',
    selected_compday_remainder: 'Жагсаалтнаас сонгосон хоног: {{days}}',
    not_specified: 'Тодорхойгүй',
    not_enough_balance: '(Үлдэгдэл хоног хүрэхгүй байна)',
    not_enough_compday:
      '(Үлдэгдэл хүрэхгүй байна. Жагсаалтнаас нэмэж сонгох эсвэл каледараас сонгосон өдрөө багасгана уу.)',
    special: 'Онцгой амралт',
    any_position: 'бүгд',
    remainder: 'Үлдэгдэл: ',
    compday_list_label:
      'Дараах жагсаалт нь таны амралтын өдөр ажилласан түүх юм. Алийг нь нөхөж амрахаа сонгоно уу.',
    step: 'Алхам',
    list_title: 'Амралт чөлөөний хүсэлтүүд',
    menu_title: 'Амралт чөлөө',
    choose_template: 'Амралт чөлөөний төрлийг сонгоно уу',
    create_title: 'Амралт чөлөөний хүсэлт үүсгэх',
    status: 'Төлөв',
    request_type: 'Хүсэлтийн хэлбэр',
    template_type: 'Хүсэлтийн төрөл',
    is_break_morning: 'Өглөө шууд авах',
    start_at: 'Эхлэх',
    end_at: 'Дуусах',
    current_step: 'Үе шат',
    approver: 'Зөвшөөрөл олгогч',
    reason: 'Шалтгаан',
    pay_status: 'Цалин олгох хэлбэр: ',
    range_type: 'Хугацааны төрөл: ',
    is_deduct_from_vacation: 'Ээлжийн амралтаас хасах: ',
    selected_duration: 'Нийт хугацаа:',
    duration: 'Хугацаа',
    no_template: 'Амралт чөлөөний төрлүүд олдсонгүй..',
    status_pending: 'Хүлээгдэж буй',
    status_rejected: 'Татгалзсан',
    status_accepted: 'Зөвшөөрсөн',
    status_deleted: 'Устсан',
    create_success_title: 'Шинэ амралт чөлөөний хүсэлт нэмэгдлээ',
    create_success_desc:
      '{{start_at}}-с {{end_at}} хүртэл авах амралт чөлөөний хүсэлт үүсгэлээ.',
    delete_modal_title: 'Хүсэлт устгах',
    delete_modal_description:
      'Энэ амралт чөлөөний хүсэлтийг усгахдаа итгэлтэй байна уу?',
    save_confirm_title:
      'Амралт чөлөөний хүсэлтийн дэлгэрэнгүй мэдээллийг хадгалахыг баталгаажуулна уу',
    save_confirm_desc:
      'Та үргэлжлүүлэхийг хүсч байна уу? Хадгалахаасаа өмнө бүх мэдээлэл үнэн зөв эсэхийг шалгаарай.',
    employee: 'Ажилтан',
    approve_list: 'Амралт чөлөөний хүсэлтүүд',
    approve_list_menu: 'Хүсэлт зөвшөөрөх',
    approve_modal_title: 'Хүсэлтийг зөвшөөрөх',
    approve_modal_description: 'Хүсэлтийг зөвшөөрөхдөө итгэлтэй байна уу?',
    approve_success_title: 'Зөвшөөрлөө',
    approve_success_description:
      '{{employee}}-с ирсэн хүсэлтийг амжилттай зөвшөөрлөө.',
    deny_modal_title: 'Хүсэлтийг татгалзах',
    deny_modal_description: 'Хүсэлтийг татгалзахдаа итгэлтэй байна уу?',
    deny_success_title: 'Татгалзлаа',
    deny_success_description:
      '{{employee}}-с ирсэн хүсэлтийг амжилттай татгалзлаа.',
    current_total_step: 'Одоогийн шат / Нийт шат',
    no_step_approver_data: '...',
    attachment: 'Файл хавсаргах',
    detail_btn: 'Дэлгэрэнгүй',
    delete_btn: 'Устгах',
    approve_btn: 'Зөвшөөрөх',
    deny_btn: 'Татгалзах',
    created_at: 'Үүсгэсэн огноо',
    compensatory_earned_days:
      'Танд {{day}} өдрийн нөхөн амрах эрх үүссэн байна.',
    compensatory_remaining_days:
      'Түүнээс {{day}} өдрийн нөхөн амрах эрх үлдсэн байна.',
    filter_title: 'Амралт чөлөөний хүсэлтийг шүүх',
    eligible_positions: 'Зөвшөөрөл хүсэх боломжтой албан тушаалууд: ',
    use_compday_alert:
      'Та {{day}} хоногийн нөхөн амрах эрх байна. Нөхөн амрах эрхээ эхэлж ашиглана уу.',
    approve: 'Зөвшөөрөх',
    approver_dialog_warning: 'Энэ үйлдлийг буцааж чадахгүй.',
    next_approver_placeholder: 'Дараагийн зөвшөөрөгч',
    next_approver_label: 'Энэ хүсэлтийн дараагийн зөвшөөрөгчийг сонгоно уу',
    detail_step_by: 'by ',
    your_approval: 'Тань дээр хүлээгдэж буй',
    year: 'Он',
    vacation_days: 'Амралтын эрх үүссэн хоног',
    remainig_days: 'Үлдсэн хоног',
    action_by_me: 'Миний хийсэн үйлдэл',
    comp_expire_at: 'Нөхөн амрах эрхийг ашиглах эцсийн хугацаа: ',
  },
  TIMEOFF_TOTAL_REPORT: {
    title: 'Сар бүрийн амралтын тайлан ',
    submit: 'ШҮҮХ',
    select_year: 'Жил сонгох',
    select_months: 'Сар сонгох',
    select_vacation: 'Амралтын төрлийг сонгох',
    month: 'Сар',
    year: 'Жил',
    vacations: 'Амралт',
  },
  DAILY_REPORT: {
    my_daily_report: 'Хувийн тайлан',
    team_daily_report: 'Багийн тайлан',
    all_daily_report: 'Бүх багийн тайлан',
    create: 'Тайлан нэмэх',
    menu_title: 'Өдрийн тайлан',
    page_title: 'Өдрийн тайлан',
    list_title: 'Өдрийн тайлангийн жагсаалт',
    no_daily_report: 'Тайлан хоосон байна',
    report: 'Тайлбар',
    report_placeholder: 'Ажлын тайлангаа бичнэ үү',
    project_label: 'Төсөл',
    project_placeholder: 'Төсөл сонгох',
    yesterday: 'Өчигдөр',
    today: 'Өнөөдөр',
    list_view: 'Жагсаалт',
    calendar_view: 'Цагаар',
    project: 'Төсөл',
    view_label: 'Харах төрөл',
    list_view_btn: 'Жагсаалт',
    calendar_view_btn: 'Цагаар',
    not_within_range: 'Ажлын цагийн дотор байх',
    other: 'Бусад',
    report_entries: 'Тайлангууд',
    projects: 'Төслүүд',
    in_progress: 'Ажиллаж байна',
    total_works_label: 'Нийт ажилласан цаг',
    not_within_rang: 'Ажлын цаг дотор байх',
    delete_modal_title: 'Тайлан устгах',
    delete_modal_description: 'Тайлан усгахдаа итгэлтэй байна уу?',
    no_time_card: 'Ажлын цаг бүртгэгдээгүй байна',
    entry_chart_desc:
      'Тайлан бүрийн ажлын төрөл болон зарцуулсан цагаар харуулж байна.',
    widget_title: 'Өдрийн тойм',
    end_at: 'Дуусах цаг',
    start_at: 'Эхлэх цаг',
    report_detail: 'Тайлангын дэлгэрэнгүй',
    select_project: 'Төсөл сонгох',
    page_title_team: 'Багийн тайлан',
    page_title_hr: 'Бүх төслийн өдөр дутмын тайлан',
    very_low: 'Хялбар',
    low: 'Бага',
    moderate: 'Дундаж',
    high: 'Өндөр',
    very_high: 'Маш өндөр',
    very_flexible: 'Яаруу биш',
    flexible: 'Уян хатан',
    firm: 'Шахуу',
    very_firm: 'Маш шахуу',
    complexity: 'Төвөгтэй байдал',
    deadline: 'Хугацаа',
    tags: 'Ажлын төрөл',
    tag_placeholder: 'Заавал сонгоно уу',
    help_complexity:
      'Төвөгтэй байдал: Хэр хэцүү ажлыг 1 ээс 5 хооронд тодорхойлно уу. Бага тоо нь амархан ажлууд, харин тоо ихэсвэл хэцүү ажлуудыг тодотгоно.',
    help_deadline:
      'Хугацаа: Ажлын хэр яаруу байгаа 1 ээс 5 хооронд тодорхойлно уу. Бага тоо нь яаруу биш ажлыг, харин тоо ихэсвэл хугацаа давчуу шахуу байгааг илэрхийлнэ.',
    may_include_break_time: 'Цайны цаг болон түр гаралтын цаг багтсан',
    team_widget_title: 'Долоо хоногийн ажлын хураангуй',
    work_load_stress_title: 'Ажлын ачаалал',
    low_stress: 'Стрессийн түвшин бага',
    moderate_stress: 'Стрессийн түвшин хэвийн',
    high_stress: 'Стрессийн түвшин өндөр',
    all_members: 'Бүх гишүүд',
    no_report: 'Тайлан байхгүй',
    stress_desc:
      'Энэ хэмжүүр нь зөвхөн ажлын ачаалал, анхаарал төвлөрөлт, төвөгтэй байдал зэрэг мэдээлэл дээр суурилсан бөгөөд хувь хүний сэтгэл зүй, стрессийг илтгэхгүй',
    time_spent: 'Зарцуулсан цаг',
    score: 'Оноо:',
    missing_title: 'Дутуу тайлангын мэдээлэл',
    go_to_date: 'Тайлан бичих',
    write: 'Тайлан оруулах',
    missing_limit: 'Дутуу тайлангийн хязгаар',
    editable_period: 'Засварлах хугацааны хязгаар',
    missing_limit_desc:
      'Хэрэв та {{number}}-аас дээш өдрийн  тайлангаа оруулаагүй бол дараагийн өдөр ажил эхлүүлэх боломжгүй болохыг анхаарна уу.',
    editable_period_desc:
      'Өдрийн тайлангийн мэдээллийг өнөөдрөөс өмнөх {{number}} ажлын хоногийн дотор засварлах боломжтой.',
    report_warning:
      'Дутуу өдрийн тайлангаа оруулахгүй бол ажлын цаг эхлүүлэх боломжгүй болохыг анхаарна уу. Баярлалаа',
    complete_desc: 'Бүх өдрийн тайлан бүртгэгдсэн байна. ',
  },
  TIME_RECORDS: {
    work_start: 'Ажил эхлэх',
    work_end: 'Ажил дуусгах',
    shift_start: 'Түр гарах',
    shift_end: 'Буцаж ирэх',
    time_off: 'Амралт чөлөө',
    work_time_info: 'Мэдээлэл',
    work_done: 'Ажил дууссан',
    end_confirm_title: 'Ажлаа дуусгах гэж байна',
    end_confirm_desc: 'Цагаа шалгана уу',
    break_start_confirm_title: 'Түр гарах эхлүүлэх',
    break_start_confirm_desc: 'Түр гарах гэж байна цагаа шалгана уу',
    break_end_confirm_title: 'Түр гарах дуусгах',
    break_end_confirm_desc: 'Түр гарах дуусгах гэж байна',
    attendance: 'Цаг бүртгэл',
    work_hours: 'Ажлын цаг',
    shift_hours: 'Түр гарсан бүртгэл',
    pending: 'Хүлээгдэж буй',
    change_date_fields: 'Цагаа өөрчлөнө үү',
    shift_limit: 'Хязгаар тулсан',
    permission_undefined: 'Байршил тогтоох эрх тодорхойгүй',
    permission_denied: 'Байршил тогтоох эрх татгалзсан',
    permission_allowed: 'Байршил тогтоох эрх зөвшөөрөгдсөн',
  },
  TIMESHEET: {
    menu_title: 'Цаг бүртгэл',
    date: 'Огноо',
    start_time: 'Ирсэн цаг',
    end_time: 'Явсан цаг',
    break_start: 'Түр гарсан',
    break_end: 'Буцаж ирсэн',
    worked_hours: 'Ажилласан цаг',
    overtime: 'Илүү цаг',
    early_leave: 'Эрт таралт',
    late_arrival: 'Хоцролт',
    absense: 'Таслалт, чөлөө',
    total_deduct: 'Нийт суутгах цаг',
    monday: 'Дав',
    tuesday: 'Мяг',
    wednesday: 'Лха',
    thursday: 'Пүр',
    friday: 'Баа',
    saturday: 'Бям',
    sunday: 'Ням',
    total: 'Нийт',
    dialog_title: '{{date}} цаг засварлах',
    timeoff: 'Амралт, чөлөө',
    reason: 'Шалтгаан',
    break_records: 'Түр гаралт',
    add_break_record: 'Түр гаралт нэмэх',
    work_start_placeholder: 'Ажил эхлэх',
    work_end_placeholder: 'Ажил дуусах',
    break_start_placeholder: 'Түр гаралт эхлэх',
    break_end_placeholder: 'Түр гаралт дуусах',
    save_confirm_title: 'Цаг засварын хүсэлтийг хадгалахыг баталгаажуулна уу.',
    save_confirm_desc:
      'Та үргэлжлүүлэхийг хүсч байна уу? Хадгалахаасаа өмнө бүх мэдээлэл үнэн зөв эсэхийг шалгаарай.',
    create_detail_success_title:
      'Цаг засварын хүсэлтийг амжилттай хадгалагдлаа.',
    create_detail_success_desc:
      '{{date}} -д цаг засварын хүсэлтийг амжилттай хадгалагдлаа',
    weekend: 'Бям/Ням',
    holiday: 'Амралтын өдөр',
    invalid_record: 'Бүртгэл дутуу',
    employee: 'Ажилтан',
    deduction_days: 'Суутгах хоног: {{days}} өдөр',
    holiday_work_days: 'Амралтын өдөр ажилласан хоног: {{days}} өдөр',
    deduction_hours: 'Нийт суутгах цаг: {{hours}} цаг',
    holiday_overtime: 'Амралтын өдрийн илүү цаг: {{hours}} цаг',
    delete_request: 'Цаг устгуулах хүсэлт',
    time_correction_disable_placeholder:
      'Энэ хүсэлтийн 1 ба түүнээс дээш алхмууд аль хэдийн батлагдсан байна. Миний цагийн засвараас дэлгэрэнгүй мэдээллийг харна уу.',
    delete_change: 'Цаг устгуулах хүсэлт',
    edit_request: 'Цаг засуулах хүсэлт',
    remote: 'Зайнаас',
    no_location: 'Байршил тодорхойгүй',
    workspace: 'Ажиллах төлөв',
    work_days: 'Ажлын өдрүүд: {{days}} өдөр',
  },
  TIME_REPORT: {
    menu_title: 'Цагийн тайлан',
    employee: 'Ажилтан',
    late_arrival: 'Хоцролт',
    early_leave: 'Эрт таралт',
    absence: 'Таслалт',
    time_off: 'Чөлөө',
    total_deduct: 'Нийт хасалт',
    worked_hours: 'Ажилласан цаг',
    overtime: 'Илүү цаг',
    overtime_holiday: 'Нийтээр амрах өдрийн илүү цаг',
    overtime_weekend: 'Амралтын өдрийн илүү цаг',
    actual_overtime: 'Бодит илүү цаг',
    employee_placeholder: 'Ажилтан сонгох',
    filter_placeholder: 'Илүү цагаар шүүх',
    year_month: 'Жил болон сар',
    total: 'Нийт',
    deduction_days: 'Суутгах хоног',
    lunch_count: 'Хоол авсан тоо',
    timeoff_report_title: 'Амралтын, чөлөөний тайлан',
    work_status_report: 'Ажилласан төлөвийн тайлан',
    remote_work: 'Зайнаас ажилласан',
    office_work: 'Оффисоос ажилласан',
    insufficient_office: 'Оффисоос ажиллах ёстой өдөр',
    insufficient_office_explanation:
      'Оффисоос ажиллах ёстой байсан өдөр буюу дутуу үлдсэн өдрийг илэрхийлнэ.',
    remote_without_approve: 'Зөвшөөрөлгүй зайнаас ажилласан өдөр',
    remote_without_approve_explanation:
      'Зайнаас ажиллах хүсэлтийг нь зөвшөөрөөгүй үед ажилласан өдрийг илэрхийлнэ ',
    timeOff: 'Амралт, чөлөө',
    report: 'Тайлан',
    days_off_work: 'Тасалсан өдрүүд',
    work_status_summary: 'Ажилчдын ажилласан төлөвийн тойм',
    accepted_remote_work: 'Зөвшөөрөлтэй зайнаас ажилласан өдөр',
    summary: 'Ажилчдын ажилласан байршлын тойм',
    total_work_days: 'Нийт ажлын өдөр',
    summary_report: 'Тойм',
    searched_date:
      '{{start}} -ээс {{end}} -ны хоорондох {{week}} долоо хоногийн датаг харуулж байна',
    unrecorded_day: 'Бүртгэгдээгүй өдөр',
    no_data: ' Мэдээлэл олдсонгүй',
  },
  TIME_CORRECTION: {
    menu_title: 'Цаг засварыг зөвшөөрөх',
    title: 'Цаг засварыг зөвшөөрөх жагсаалт',
    work_date: 'Ажлын огноо',
    status: 'Статус',
    reason: 'Шалтгаан',
    created_at: 'Үүсгэсэн огноо',
    pending: 'Хүлээгдэж байна',
    rejected: 'Татгалзсан',
    accepted: 'Зөвшөөрсөн',
    deleted: 'Устгагдсан',
    approve_modal_title: 'Хүсэлтийг зөвшөөрөх',
    approve_modal_description: 'Хүсэлтийг зөвшөөрөхдөө итгэлтэй байна уу?',
    approve_success_title: 'Зөвшөөрлөө',
    approve_success_description:
      '{{employee}}-с ирсэн хүсэлтийг амжилттай зөвшөөрлөө.',
    deny_modal_title: 'Хүсэлтийг татгалзах',
    deny_modal_description: 'Хүсэлтийг татгалзахдаа итгэлтэй байна уу?',
    deny_success_title: 'Татгалзлаа',
    deny_success_description:
      '{{employee}}-с ирсэн хүсэлтийг амжилттай татгалзлаа.',
    employee: 'Ажилтан',
    time_record: 'Цагийн бүртгэл',
    filter_title: 'Цагийн засварын хүсэлтүүдийг шүүх',
    my_list_title: 'Миний цаг засвар',
    delete_modal_title: 'Цагийн засварын хүсэлтийг устгах',
    delete_modal_description: 'Та энэ хүсэлтийг устгахдаа итгэлтэй байна уу?',
    delete_success_title: 'Устгагдлаа',
    delete_success_description: '{{date}}-д хүсэлтийг амжилттай устгалаа',
    user_code: 'Ажилтны код',
    current_status: 'Одоогийн төлөв',
  },
  TIME_CORRECTION_TEMPLATE: {
    title: 'Цаг засварын загвар',
    name: 'Нэр',
    template_name: 'Загварын нэр',
    approval_template: 'Зөвшөөрлийн загвар',
    save_confirm_title:
      'Цаг засварлах загварын дэлгэрэнгүй мэдээллийг хадгалахыг баталгаажуулах уу?',
    save_confirm_desc:
      'Үргэлжлүүлэх үү? Хадгалахын өмнө бүх мэдээллийг зөв болохыг баталгаажуулна уу.',
  },
  REMOTE_WORK_TEMPLATE: {
    title: 'Зайнаас ажиллах хүсэлтийн загвар',
    menu_title: 'Загварын тохиргоо',
    name: 'Нэр',
    approval_template: 'Зөвшөөрлийн загвар',
    template_name: 'Загварыг нэр',
    save_confirm_title: 'Та энэ загварыг хадгалахдаа итгэлтэй байна уу?',
    save_confirm_desc:
      'Үргэлжлүүлэх үү? Хадгалахын өмнө бүх мэдээллийг зөв болохыг баталгаажуулна уу.',
    limit_exit_template: 'Нэмэлт зөвшөөрлийн загвар',
  },
  REMOTE_WORK_REQUEST: {
    title: 'Зайнаас ажиллах хүсэлт',
    menu_title: 'Миний хүсэлтүүд',
    create_success_title: 'Зайнаас ажиллах хүсэлт үүслээ',
    description: 'Хийх зүйлс',
    work_date: 'Ажлын өдөр',
    create_title: 'Зайнаас ажиллах хүсэлт үүсгэх',
    add_date: 'Өдөр нэмэх',
    approve_title: 'Хүсэлт зөвшөөрөх',
    description_by_date: '{{date}} өдрийн хийх зүйлс',
    approve_all: 'Бүгдийг зөвшөөрөх',
    all_same_description: 'Бүх өдөр адилхан тайлбартай',
    limit: 'Хязгаар',
    delete_modal_title: 'Энэ зайнаас ажиллах хүсэлтийг устгах уу?',
    delete_modal_description: 'Энэ үйлдлийг буцаах боломжгүй. Үргэлжлүүлэх үү?',
    update_title: 'Зайнаас ажиллах хүсэлтийг шинэчлэх',
    remote_dates: 'Зайнаас ажиллах хүсэлтийн өдрүүд',
    filter_title: 'Зайнаас ажиллах хүсэлтийг шүүх',
    deleted_by: 'Устгасан',
    denied_by: 'Татгалзсан',
    possible_my_time: 'My time авах боломжит хугацаа',
    total_time: 'Нийт хугацаа',
    start_work: 'Ажлаа эхлүүлнэ үү?',
    started_work: 'Та аль хэдийн ажлаа эхлүүлсэн байна',
    reason_required_desc:
      'Зайнаас ажиллах хүсэлтийг татгалзах бол шалтгааныг бичнэ үү.',
  },
  REMOTE_WORK_SETTINGS: {
    title: 'Зайнаас ажиллахыг идэвхгүй болгох',
    menu_title: 'Идэвхгүй болгох',
    name: 'Ажилтны нэр',
    start_date: 'Эхлэх огноо',
    end_date: 'Дуусах огноо',
    reason: 'Шалтгаан',
    create_title: 'Зайнаас ажиллах хүсэлтийг идэвхгүй болгох',
    info: 'Та оруулсан огноогоор дараах өдрүүдэд зайнаас ажиллах хүсэлт үүсгэсэн байна.',
    force_create: 'Устгаад үүсгэх',
    force_update: 'Устгаад шинэчлэх',
    save_confirm_title: 'Зайнаас ажиллах идэвхгүй болгох',
    save_confirm_desc:
      'Үргэлжлүүлэх үү? Хадгалахаасаа өмнө бүх дэлгэрэнгүй мэдээлэл зөв эсэхийг шалгана уу.',
    delete_modal_title: 'Зайнаас ажиллахыг идэвхжүүлэх',
    delete_modal_description:
      'Зайнаас ажиллах хүсэлтийг идэвхжүүлэхийг хүсч байна уу?',

    create_success_title: 'Зайнаас ажиллах хүсэлт идэвхгүй боллоо',
    create_success_desc:
      '{{start}}-с {{end}} хүртэлх Зайнаас ажиллах хүсэлт идэвхгүй боллоо.',
    disabled_by: 'Идэвхгүй болгосон',
    disabled_date: 'Идэвхгүй болгосон өдөр',
    disabled_log_title: 'Идэвхгүй болгосон бүртгэл',
    disabled_log_menu: 'Идэвхгүй болгосон бүртгэл',
    filter_title: 'Зайнаас ажиллах боломжгүй хэрэглэгчдийг шүүх',
  },
  NOTIFICATIONS: {
    mark_all_as_read: 'Бүгдийг уншсан болгох',
    mark_as_read: 'Уншсан болгох',
  },
  MESSAGES: {
    NT000001: 'таныг {{name}} төсөлд нэмсэн.',
    NT000002: 'таныг {{name}} төслөөс хассан.',
    NT000003: '{{name}} төсөл дээр таны хандах эрхийг өөрчилсөн.',
    NT000004: '{{name}} хүсэлт гаргалаа.',
    NT000005: 'таны {{name}} хүсэлтийг зөвшөөрсөн.',
    NT000006: 'таны {{name}} хүсэлтийг татгалзсан.',
    NT000007: '{{date}} өдрийн цаг засварлах хүсэлт илгээсэн.',
    NT000008: '{{date}} өдрийн цаг засварлах хүсэлтийг зөвшөөрсөн.',
    NT000009: '{{date}} өдрийн цаг засварлах хүсэлтийг татгалзсан.',
    NT000010: 'Зайнаас ажиллах хүсэлт ирлээ',
    NT000011: 'Зайнаас ажиллах хүсэлтийг зөвшөөрсөн.',
    NT000012: 'Зайнаас ажиллах хүсэлтийг татгалзсан.',
    NT000013: 'Зайнаас ажиллах хүсэлт гаргах эрхийг {{date}} хооронд хаалаа.',
  },
  QUIT_USERS: {
    title: 'Гарсан ажилчид',
    employee_name: 'Нэр',
    quit_date: 'Өдөр',
    reason: 'Шалтгаан',
    start_date: 'Эхлэх огноо',
    end_date: 'Дуусах огноо',
    filter_title: 'Огноогоор шүүх',
    save_confirm_title: 'Ажлаас гарсан шалтгааныг шинэчлэх үү',
    save_confirm_desc:
      'Үргэлжлүүлэх үү? Шинэчлэхээсээ өмнө бүх дэлгэрэнгүй мэдээлэл зөв байгаагаа шалгана уу.',
  },
  INFO_LOG: {
    title: 'Ажилтны мэдээллийн өөрчлөлт',
    menu_title: 'Мэдээллийн өөрчлөлт',
    count: '{{number}} өөрчлөлт байна',
    firstname: 'овог',
    lastname: 'нэр',
    firstname_en: 'англи овог',
    lastname_en: 'англи нэр',
    birthday: 'төрсөн өдөр',
    gender: 'хүйс',
    home_address: 'гэрийн хаяг',
    phone: 'утасны дугаар',
    email: 'имэйл',
    phone_secondary: 'нэмэлт утасны дугаар',
    user_code: 'ажилтны код',
    role_id: 'хандах эрх',
    position_id: 'албан тушаал',
    division_id: 'хэлтэс',
    hired_date: 'ажилд орсон огноо',
    ndsh_months: 'НДШ төлсөн сар',
    ndsh_total: 'нийт НДШ төлсөн сар',
    status: 'Төлөв',
    empty: 'хоосон',
    deleted: 'устгасан',
    created: 'үүсгэсэн',
    employee_label: 'Ажилтан',
    something_changed: '{{what}} өөрчлөгдсөн байна.',
    performed_by: 'Гүйцэтгэсэн',
    performed_on: 'огноо',
  },
  COMPANY_RULE: {
    title: 'Дотоод журам',
    back_confirm_title: 'Хадгалахаас өмнө гарвал таны өөрчлөлт устах болно.',
    back_confirm_desc: 'Хадгалахаас өмнө гарвал таны өөрчлөлт устах болно.',
    updated_by: 'Сүүлд шинэчлэгдсэн огноо {{date}}, гүйцэтгэсэн {{employee}}',
    save_confirm_title: 'Дотоод журмыг хадгалахыг баталгаажуулах',
    save_confirm_desc:
      'Үргэлжлүүлэх үү? Хадгалахаасаа өмнө бүх дэлгэрэнгүй мэдээлэл зөв байгаагаа шалгана уу.',
  },
  WORKSPACE: {
    title: 'Ажлын байр',
    name: 'Нэр',
    description: 'Тайлбар',
    location: 'Байршил',
    ips: 'IPs',
    status: 'Статус',
    create_title: 'Ажлын байр үүсгэх',
    create_success_title: 'Шинэ ажлын байр нэмэгдлээ',
    create_success_desc:
      'ID: {{id}} болон нэр: {{name}} -тай шинэ ажлын байр нэмэгдлээ',
    save_confirm_title: 'Ажлын байр хадгалахыг баталгаажуулах',
    save_confirm_desc:
      'Үргэлжлүүлэх үү? Хадгалахаас өмнө бүх дэлгэрэнгүй мэдээлэл зөв байгааг шалгана уу.',
    delete_modal_title: 'Ажлын байр устгах',
    delete_modal_description: 'Энэ ажлын байрыг устгахыг хүсч байна уу?',
    change_status_modal_title: 'Ажлын байрыг {{action}}',
    change_status_modal_description:
      'Энэ ажлын байрыг {{action}}дээ итгэлтэй байна уу?',
    activate: 'Идэвхжүүлэх',
    deactivate: 'Идэвхгүй болгох',
    deactivated_date: 'Идэвхгүй болгосон огноо',
    active: 'Идэвхтэй',
    in_active: 'Идэвхгүй',
    map_breadcrumb: 'Ажлын байрын байршил',
    map_title: '{{workspace}}-ийн байршил',
    inactive_at: 'Идэвхгүй болсон огноо',
    ips_placeholder: '1.1.1.1, 2.2.2.2 гэх мэт',
    work_start_location: 'Ажил эхэлсэн байршил',
    work_end_location: 'Ажил дууссан байршил',
    remote_placeholder: 'Таныг зайнаас ажиллаж байна гэж бүртгэлээ',
    office_placeholder:
      'Таныг {{office}} ажлын байрнаас ажиллаж байна гэж бүртгэлээ',
    verification_code: 'Баталгаажуулах код',
    change_location: 'Байршил солих',
    uid: 'UID',
    no_location_placeholder:
      'Та байршлаа тодорхойлохыг зөвшөөрөөгүй тул таны байршлыг тодорхойлж чадсангүй.',
    no_camera: 'Камер төхөөрөмж байхгүй аль эсвэл зөвшөөрөлгүй байна',
    invalid_qr: 'Уншуулсан QR код буруу байна',
    qr_title: 'QR Scanner',
  },
  COMPENSATORY_DAYS: {
    title: 'Нөхөн амрах',
    created_date: 'Үүсгэсэн огноо',
    expire_date: 'Дуусах өдөр',
    earned_days: 'Нөхөн амрах хоног',
    remaining_days: 'Үлдсэн хонөг',
    comment: 'Тэмдэглэл',
  },
  DEMO: {
    notification: 'Та аппликейшны демо хувилбарыг ашиглаж байна',
    demo_users: 'Шууд нэвтрэх хэрэглэгчийг сонгоно уу:',
    demo_desc:
      'Манай аппликейшныг туршиж үзэхийн тулд доорх мэдээллийг ашиглан нэвтэрнэ үү:',
  },
  ERROR: {
    E000001: '{{field}} оруулах',
    E000002: 'Утга оруулах шаардлагатай',
    E000003: 'Оруулсан 2 нууц үг ижил байх ёстой',
    E000004: '{{field}} ирээдүй цаг дээр байх ёстой',
    E000005: 'Дуусах огноо их байх ёстой',
    E000006: 'Хүчингүй огноо',
    E000007: 'Эхлэх огноог багасгана уу',
    E000008: 'Хамгийн багадаа 30 минутын зөрүүтэй байх',
    E000009: 'Change to compensatory request',
    E000010: 'Өдрүүд нь ижил өдөрт байх ёстой',
    E000046: '{{field}} нь {{number}} тэмдэгт дотор байх ёстой',
    E000047: '{{field}} хамгийн багадаа {{number}} тэмдэгт байх ёстой',
    E000048: '{{field}} шаардлага хангах ёстой',
    E000049: '{{field}} буруу тэмдэгттэй байна',
    E000050: '{{field}} нь {{number}} тэмдэгт байх шаардлагатай',
    E000051: '{{field}} тоо агуулсан байх ёстой',
    E000052: '{{field}} нь жижиг үсэг агуулсан байх ёстой',
    E000053: '{{field}} нь том үсэг агуулсан байх ёстой',
    E000054:
      '{{field}} нь эдгээрийн дор хаяж нэгийг агуулсан байх ёстой !@#$%^&*()-+',
    E000055: 'Талбарын утга хоосон байна',
    E000056: 'Зөв утасны дугаар оруулна уу',
    E000057: '{{field}} must contain a letter',
    E000058: 'Хуучин нууц үгээс өөр нууц үг оруулна уу',
    E000060: 'Эхлэх огноог оруулах шаардлагатай',
    E000061: 'Эхлэх дуусах огноо нь нэг сар байх ёстой',
    E000062: 'Устгаж чадсангүй',
    E000063: 'Зөв тоон утга оруулна уу',
    E000064: 'Өртгийн эхлэх огноо дуусах хугацаанаас бага байх ёстой',
    E000065: 'Өмнөх сараас өөр огноо сонгох боломжгүй',
    E000066: 'Байршуулж чадсангүй',
    E000067: 'Дуусах цаг хойно байх ёстой',
    E000068: 'Эхлэх цаг ирээдүйд байх ёстой',
    E000069: '{{field}} нь 7:00 AM-ээс хойш байх ёстой',
    E000070: 'Дор хаяж нэг хүлээн авагч сонгогдсон байх ёстой',
    E000071: 'Тооцоолх жил нь энэ жил эсвэл дараа жил байх ёстой',
    E000072:
      'Эхлэх огноо болон дуусах огноо сонгосон жилтэй ижил жилд байх ёстой',
    E000073: 'Явсан цаг 15 минут хойно байх ёстой.',
    E000074: 'Түр гаралт нь ажлын цагт байх ёстой.',
    BE000001: 'Хоосон байж болохгүй',
    BE000002: 'Талбарыг оруулах шаардлагатай',
    BE000003: 'Талбарыг оруулах шаардлагатай',
    BE000021: 'Байхгүй',
    BE000022: 'Ашигласан утга байна',
    BE000046: 'Талбарын утга хэт урт байна',
    BE000047: 'Талбарын утга хэт богино байна',
    BE000048: 'Зөв огноо оруулна уу',
    BE000049: 'Зөв огноо оруулна уу',
    BE000050: 'Утга хязгаарт багтахгүй байна',
    BE000059: 'Өгөгдлийн төрөл таарахгүй байна',
    BE000060: 'Эхлэх цаг ирээдүйд байх ёстой',
    BE000061: 'Сонгосон өдрүүд нэг өдөр байх ёстой',
    BE000062: 'Цаг давхцаж байна.',
    BE000063: 'Үйлдлийг гүйцэтгэх боломжгүй. Ажил хараахан эхлээгүй байна.',
    BE000065: 'Эзэмшигч зөвхөн өөрийн бүртгэлийг устгах боломжтой',
    BE000066: 'Ажил аль хэдийн эхэлсэн байна',
    BE000067: 'Илгээхийн өмнө түр гаралтаа дуусга',
    BE000068: 'Эхлээд түр гаралтыг эхлүүл',
    BE000052: 'Тоо байх ёстой',
    BE000073: 'Түр гарах эрх дууссан байна',
    BE000078: 'Нөхөн амрах хоног хүрэхгүй байна',
    BE000079: 'MyTime эрх дууссан байна',
    BE000080: 'Амралтын үлдэгдэл хоног байхгүй байна',
    BE000081: 'Амралтын үлдэгдэл хоног хүрэхгүй байна',
    BE000082: 'IPs буруу байна',
    AE000001: 'Өөр дататай уялдаатай учир устгаж болохгүй',
    BE000051: 'Хоосон байж болохгүй',
    BE000054: 'Сонгосон сонголт буруу байна',
    BE000055: 'Текст байх ёстой',
    BE000056: 'Бүхэл тоо байх ёстой',
    BE000057: 'Зөв огноо байх ёстой',
    BE000058: 'Зөв JSON байх ёстой',
    BE000064: 'Заасан утгаас их буюу тэнцүү байх ёстой',
    BE000069: 'Аль нэг нь л шаардлагатай',
    BE000070: 'Энэ төсөл таных биш байна',
    BE000072: 'Дор хаяж нэг бичлэг байх ёстой',
    BE000074: 'Заасан утгатай тэнцүү байх ёстой',
    BE000075: 'Нөхөн амралтын өдрүүд аль хэдийн оруулагдсан байна',
    BE000076: 'Дараагийн интервал байх ёстой',
    BE000077: 'Тооцоолол хийх боломжгүй байна',
    BE000083: 'Зөв цаг байх ёстой',
    AE000002:
      '{{date}}-д аль хэдийн батлагдсан нэг төлөвлөгөө байгаа тул баталгаажуулах боломжгүй',
    AE000003:
      'Эзэмшигчийг устгах эсвэл засварлах боломжгүй. Төсөл дор хаяж нэг эзэмшигчтэй байна.',
    AE000004: 'Батлагдсан төлөвлөлтийг устгах боломжгүй.',
    AE000005: 'Хүний нөөцийн төлөвлөлтийн зорилтот сар буруу байна.',
    AE000006: 'Хүний нөөцийн төлөвлөлтийг баталгаажуулах ёстой.',
    AE000007:
      'Эхлэх огноо болон дуусах огнооны хооронд тухайн албан тушаалд зардал гардаг.',
    AE000008: 'Энэ өрөөнд уулзалт захиалсан байна',
    AE000009: 'Амралтын хоног хүрсэнгүй.',
    AE000010: 'Нөхөн амрах хоног байхгүй байна.',
    AE000011: 'MyTime авах эрх байхгүй байна.',
    AE000012: 'Зөвшөөрөл олгогч олдсонгүй.',
    AE000014:
      'Хүсэлт амжилтгүй. Хүсэлт амралтын хугацаанд хамаарахгүй эсвэл давхардсан хүсэлт байна. ',
    AE0000016: 'Ажилтны амралтын мэдээллийг аль хэдийн оруулсан байна.',
    AE000020: 'Энэ загварыг ашигласан хүсэлт хүлээгдэж байна.',
    AE000021: 'Зөвшөөрлийн загварыг шинэчилж чадсангүй.',
    AE000029: 'Өнгөрсөн цагт өдөр сонгож болохгүй.',
    AE000028: 'Урт хугацааны амралттай үед өөр амралт авч болохгүй.',
    AE100033:
      'Таны оруулсан нууц үг таны хуучин нууц үгтэй ижил байна. Өөр нууц үг оруулна уу.',
    AE000030: 'Цаг засварын хүсэлт илгээх хугацаа өнгөрсөн',
    AE000031: 'Баталгаажуулах код буруу байна. Дахин оролдоно уу',
    AE000032: 'Өнгөрсөн дахь захиалгыг устгаж болохгүй',
    AE000033: 'Шалтгаан хоосон байж болохгүй',
    AE000042: 'Missing params',
    AE000043: 'Workspace not found',
    AE000049: 'My time авах хугацааны дээд хэмжээ хэтэрсэн',
    AE000039: 'Зайнаас ажиллах өдөр давхцаж байна',
    AE000034: 'Зайнаас ажиллах хүсэлт байна',
    AE000035: 'Зайнаас ажиллах хүсэлтийг аль хэдийн хүлээн зөвшөөрсөн',
    AE000036: 'Зайнаас ажиллах загвар олдсонгүй',
    AE000038: 'Зайнаас ажиллах хүсэлтийг устгах боломжгүй',
    AE000040: 'Алсын зайнаас ажиллах боломжгүй',
    AE000044: 'Зайнаас ажиллах хүсэлтийг идэвхгүй болгох өдөр давхцаж байна',
    AE000045: 'Зайнаас ажиллах хүсэлт дор хаяж 1 өдөртэй байх ёстой',
    AE000046: 'Бичээгүй тайлангийн хязгаар хэтэрсэн байна.',
    AE000047:
      'Энэ тайлан засах боломжтой хугацаанаас хэтэрсэн тул өөрчлөх боломжгүй байна.',
    AE000048:
      'Хугацаа хамгийн бага болон хамгийн их хэмжээнээс хэтэрсэн байна.',
    AE000052: 'Формат буруу',
    AE000050: 'Дуусах огноо их байх ёстой',
    AE000051: 'Sync хийхэд алдаа гарлаа',
    AE000071: 'Хүчинтэй хугацааны хязгаарт биш',
    AE000053: 'Энэ алхмыг аль хэдийн нэмсэн байна.',
    AE000054: 'Нөхөн амрах эрхийн ашиглах хугацаа дууссан.',
    ABE000004: 'Утга оруулах шаардлагатай',
    AE100034: 'Оруулсан 2 нууц үг ижил байх ёстой',
    ABE100014: 'Хуучин нууц үгээс өөр нууц үг оруулна уу',
    AE000056: 'Энэ ажлын төрлийг ашигласан өдөр тутмын тайлан байна.',
    AE000057: 'Эхлэх цаг шаардлагатай',
    AE000024: 'Цаг засварлах хүсэлтийн загвар олдсонгүй.',
  },
}
