// Japanese
export default {
  SYSCOMMON: {
    number_no: '№',
    yes: 'はい',
    no: 'いいえ',
    apply: '適用',
    confirm: 'OK',
    cancel: 'キャンセル',
    save: '保存',
    dashboard: 'ダッシュボード',
    project_management: 'プロジェクト管理',
    task_management: 'タスク管理',
    projects: 'プロジェクト',
    gantt_chart: 'ガントチャート',
    sprint: 'スプリント',
    tasks: 'タスク',
    kanban_board: 'カンバンボード',
    time_management: '勤怠管理',
    time_requests: '時間リクエスト',
    human_resource: 'リソース管理',
    resource_management: '人材管理',
    skill_management: 'スキル管理',
    welcome: 'ようこそ',
    milestone: 'マイルストーン',
    timesheet: '勤務管理表',
    teamwork: 'チームワーク',
    members: 'メンバー',
    employees: '社員',
    notifications: '通知',
    view_all: 'すべて表示',
    im_not_robot: '私はロボットではありません',
    partner_company: 'パートナーカンパニー',
    create: '作成',
    name: '名前',
    description: '説明',
    back: '戻る',
    search: '検索',
    delete: '削除',
    add: '追加',
    edit: '編集',
    manage: '管理',
    project_list: 'プロジェクト一覧',
    start_date: '開始日',
    end_date: '終了日',
    image: '画像',
    role: '役割',
    member: 'メンバー',
    settings: '設定',
    update: '更新',
    actions: 'アクション',
    delete_modal_title: '本当に削除してもよろしいですか？',
    write: '書く',
    read: '読む',
    no_data: '結果が見つかりません',
    something_went_wrong: '何かがうまくいかなかった',
    additional_confirm_label:
      'このアクションを確認するには、{{confirm}}を入力してください',

    search_keyword: '検索',
    filter_confirm: '確認',
    id: 'ID',
    back_to_list: '一覧に戻る',
    no_resources: '{{resources}} はありません',
    no_matches: '一致するものがありません',
    loading_resource: '{{resources}} を読み込んでいます',
    data: 'データ',
    clear: 'クリア',
    cant_find: '一致するものが見つかりません。',
    copyright: 'Copyright TeamSync® ©',
    copy: 'コピー',
    copied: 'コピー済み',
    date: '日付',
    company: '会社',
    gender_male: '男性',
    gender_female: '女性',
    gender_other: 'その他',
    back_confirm_title: '編集中のものがあるようです。',
    back_confirm_desc: '保存せずに出ると、変更内容が失われます。',
    user_data: 'ユーザーデータが壊れています。管理者に連絡してください。',
    contact_admin: 'Adminに連絡',
    failed_to_init_app: 'アプリケーションの初期化に失敗しました',
    sign_out_to_home: 'サインアウトしてホームに移動',
    not_found_code: '404',
    permission_denied_code: '403',
    denied_title: '権限が拒否されました',
    denied_desc: 'このページにアクセスする権限がありません。',
    not_found_title: 'ページが見つかりません',
    not_found_desc: '探しているページは存在しないか移動されています。',
    not_available_code: '503',
    not_available_title: 'サービスは現在利用できません',
    go_to_home: 'ホームへ移動',
    details: '詳細',
    calendar: 'カレンダー',
    events: 'イベント',
    list: '一覧',
    categories: 'カテゴリ',
    event_list: 'イベント',
    event: 'イベント',
    download: 'ダウンロード',
    home: 'ホーム',
    skill_report: 'スキルレポート',
    order_direction: '順序の方向',
    order_by: '順序',
    order_by_desc: '列を選択して一覧の順序を変更します。',
    created_date: '作成日',
    confirmed_date: '確認日',
    status: 'ステータス',
    division_lead: '部門リーダー',
    filter: 'フィルタ',
    reports: 'レポート',
    skill_list: 'スキル',
    skill_categories: 'スキルカテゴリ',
    back_to_login: 'ログイン画面に戻る',
    page_size: 'ページサイズ',
    order_asc: '昇順',
    order_desc: '降順',
    default: 'デフォルト',
    change_order_page_title: '{{item}}の表示順の修正',
    change_order_breadcrumb_title: '表示順の修正',
    update_order_modal_title: '本当に表示順を修正しますか？',
    update_order_modal_description: 'この操作は元に戻せません。',
    select_file: 'ファイルの選択',
    reset: 'リセット',
    day: '日',
    other: 'その他',
    send: '送信',
    duration_day: '日',
    duration_hour: '時間',
    duration_days: '日',
    duration_hours: '時間',
    duration_min: '分',
    duration_mins: '分',
    duration_am: '午前',
    duration_pm: '午後',
    next: '次へ',
    template_settings: 'テンプレート設定',
    time_off: '休暇',
    hr_related: '人事関連',
    configuration: '設定',
    me: '自分',
    more: 'もっと',
    remote_work: 'リモートワーク',
    reason: 'Reason',
    close: 'Close',
    tag: '業務の種類',
  },
  MODULES: {
    partner_company: 'パートナー企業',
    projects: 'プロジェクト',
    report: 'レポート',
    resource: 'リソース',
    settings: '設定',
    email: 'メール',
    time: '休暇管理',
    time_settings: '休暇管理設定',
    remote: 'リモートワーク',
    resource_planning: 'リソース計画',
  },
  HOME: {
    home: 'ホーム',
    features: '機能',
    tel: 'Tel',
    pricing: '価格',
    contact: 'お問い合わせ',
    faq: 'よくある質問 (FAQ)',
    sign_in: 'サインイン',
    go_to_dashboard: 'ダッシュボードへ',
    additional_content: '追加コンテンツ',
    intro_title: '時は金なり、賢く管理しよう',
    intro_subtitle: 'Teamsyncでチームの生産性向上へ',
    intro_description1:
      'モバイル対応の最新技術を用いたウェブプラットフォームで、労働時間、プロジェクト、リソース、休暇申請の管理が可能。10年以上の社内使用を通じて改良されてきました。',
    book_now: '今すぐ使用する',
    try_now: 'Try now',
    learn_more: '詳細を見る',
    why: 'なぜ当社を選ぶべきか',
    why_choose_us_title:
      'なぜTeamsyncを選ぶのか？なぜなら、時間を機会と捉えるからです。',
    why_choose_us_description: `私たちのウェブプラットフォームは、ビジネス運営を効率化するための完璧なソリューションです。従業員の勤怠管理、プロジェクト管理、リソース配分、休暇申請、日々の報告など、すべてを一か所で管理できるワンストップショップです。

これらの機能すべてに、スマートフォン、タブレット、コンピューターなど、どのデバイスからでもアクセスでき、追加のハードウェアは必要ありません。当社のウェブサイトは完全にレスポンシブで、あらゆる画面サイズで快適な体験を提供します。このプラットフォームは、当社での10年以上の使用を通じて微調整されており、お客様のビジネスニーズすべてに対応できる準備が整っています。`,
    features_title: 'すべての機能',
    hr: '人事',
    employee: '社員',
    privacy_policy: 'プライバシーポリシー',
    terms_of_condition: '利用規約',
    support: 'サポート',
    about: '会社概要',
    contact_us: 'お問い合わせ',
    email: 'メール',
    teamsync: 'Teamsyncプラットフォーム',
    help: 'ヘルプ',
    more: 'もっと見る',
  },
  DASHBOARD: {
    hr: '人事管理',
    se: 'ソフトウェアエンジニアマネージャー',
    operator: 'オペレーター',
    intern: 'インターンシップ',
    developer: '開発者',
    ceo: '最高経営責任者（CEO）',
    spg: 'シニアソフトウェアエンジニア',
    designer: 'デザイナー/フロントエンド',
    total: '合計',
    employees: '社員',
    projects: 'プロジェクト',
    company_overview: '会社の概要',
    upcoming_events: '予定されているイベント',
    events_btn: 'イベント',
    no_events: '予定されているイベントはありません...',
    vacation_status: '有給休暇状況',
    employees_status: '勤務状況',
    daily_report_overview: '労働時間利用概要',
    non_time_spent: '労働時間ないプロジェクト',
    no_data: 'データなし',
    from_office: 'オフィスから',
    from_remote: 'リモートから',
  },
  LOGIN: {
    title: 'サインイン',
    slogan: 'チームとプロジェクトのためのオールインワンソリューション',
    email: 'メールアドレス',
    password: 'パスワード',
    remember_me: 'ログイン情報を記憶',
    forgot_password: 'パスワードを忘れた場合',
    login: 'ログイン',
    or_sign_in_with: 'または以下でサインイン',
    ERROR: {
      E000001: 'ログインに失敗しました',
    },
  },
  REQUIRED_CHANGE_PASSWORD: {
    title: '一時パスワードの変更',
    description:
      'プラットフォームへようこそ！アカウントのセキュリティを確保するために、一時パスワードを変更する必要があります。',
  },
  CALENDAR: {
    allDay: '終日',
    prev: '前へ',
    next: '次へ',
    today: '今日',
    month: '月',
    week: '週',
    day: '日',
    agenda: 'リスト',
    no_events: 'この範囲にイベントはありません。',
  },
  EVENT: {
    holiday: '休日',
    event: 'イベント',
    birthday: '誕生日',
    happy_birthday: 'お誕生日おめでとう',
    name_placeholder: 'イベント名',
    description_placeholder: 'イベントの詳細',
    start: '開始日',
    end: '終了日',
    details: '詳細',
    today: '今日',
    remove: 'イベントを削除',
    delete_modal_title: 'イベントを削除',
    delete_modal_description: 'このイベントを削除してもよろしいですか？',
    create: 'イベントを作成',
    create_success_title: '新しいイベントが追加されました',
    create_success_desc:
      'ID: {{id}} および名前: {{name}} を持つ新しいイベントが追加されました',
    show_holiday: '祝祭日表示',
  },
  FORGOT_PASSWORD: {
    title: 'パスワードをお忘れですか？',
    description: 'コードを受け取るためにメールアドレスを入力してください',
    email: 'メールアドレス',
    back: 'サインインに戻る',
    send: '送信',
  },
  VERIFY_CODE: {
    title: '認証',
    description: 'メールを確認してコードを入力してください',
    no_code_received: 'コードが受信されない場合',
    resend: '再送信',
    back: 'サインインに戻る',
    send: '送信',
    code: 'コード',
  },
  RESET_PASSWORD: {
    title: '新しいパスワードの設定',
    description:
      '新しいパスワードは以前に使用したパスワードと異なる必要があります',
    new_password: '新しいパスワード',
    confirm_new_password: '新しいパスワードの確認',
    reset_password: 'パスワードをリセット',
    password: 'パスワード',
  },
  USER_MENU: {
    profile: 'プロフィール',
    skill: 'スキル',
    logout: 'ログアウト',
  },
  PROFILE: {
    fullname: 'フルネーム',
    position: '役職',
    department: '部署',
    my_phone_number: '電話番号',
    phone_number: '電話',
    relation: '関係',
    email: 'メールアドレス',
    birthday: '誕生日',
    edit_profile: 'プロフィールを編集',
    projects_tab: 'アクティブプロジェクト',
    project_history_tab: 'プロジェクト履歴',
    skills_tab: 'スキル',
    settings_tab: '設定',
    picture: 'プロフィール画像',
    additional_phone_numbers: '追加の電話番号',
    add_phone: '電話番号を追加',
    user_code: 'ユーザーコード',
    no_projects: 'プロジェクトなし',
    total_projects: '合計プロジェクト: ',
    total_cost: '合計工数: ',
    home_address: '自宅住所',
    system_role: 'System role',
  },
  SETTINGS: {
    password: {
      title: 'パスワード',
      change_password: 'パスワードの変更',
      old_password: '古いパスワード',
      new_password: '新しいパスワード',
      confirm_password: 'パスワードの確認',
      confirmation_dialog_title: '本当に変更しますか？',
      confirmation_dialog_description: 'パスワードが変更されます。',
      confirmation_dialog_confirmation_text: 'はい',
      confirmation_dialog_cancellation_text: 'いいえ',
    },
    clear: {
      confirmation_dialog_title:
        'すべてのフィルターをクリアしてもよろしいですか？',
      confirmation_dialog_description:
        'すべてのリストページでフィルター設定がデフォルト値に戻ります。',
    },
    filter_options: 'フィルター設定',
    filter_desc:
      '以下の「すべてのフィルターをリセット」ボタンをクリックすると、すべてのリストページでのフィルター設定がデフォルト値に戻ります。この操作は、並び替え設定やページサイズなどのフィルターオプションへの変更をクリアします。',
    clear_filters: 'すべてのフィルターをリセット',

    allow_location_title: '位置情報の許可',
    my_location: '私の位置座標は次のとおりです：',
    latitude: '緯度：',
    longitude: '経度：',
    denied_instruction_first:
      '以前に位置情報の許可を拒否しています。ブラウザーの権限をリセットしてください。',
    denied_instruction_second:
      "次回ブラウザーが許可を要求するときは、'すべての訪問で許可'または'許可'をクリックしてください。",
    undefined_instruction_first:
      "アドレスバーの右上にあるアイコンをクリックして、画像に示すように'許可'をクリックしてください。",
    undefined_instruction_second:
      "次回ブラウザーが許可を要求するときは、'すべての訪問で許可'または'許可'をクリックしてください。",
    permission_instruction:
      '私たちは、主要オフィスまたはリモート地点での作業を開始または終了したかどうかを確認する以外の目的で、位置情報を保存または使用しません。あなたのプライバシーは重要ですし、あなたのデータを尊重します。',
  },
  CONFIRM: {
    logout_desc: 'ログアウトしてもよろしいですか？',
    logout_title: 'ログアウト確認',
  },
  COMPANY: {
    companies: 'パートナー企業',
    create_company: '企業を作成',
    name_placeholder: '企業名',
    description_placeholder: '企業の説明',
    list_page_title: 'パートナー企業一覧',
    create: '新しいパートナー企業',
    title: 'パートナー企業',
    no_column: 'いいえ',
    name_column: '名前',
    description_column: '説明',
    divisions_column: '部門',
    projects_column: 'プロジェクト',
    actions_column: 'アクション',
    delete_modal_title: '本当に削除しますか？',
    delete_modal_description:
      '選択したパートナー企業を本当に削除しますか？この操作は元に戻せません。',
    overview_page_title: '概要',
    you_have: '企業には',
    divisions: '部門',
    projects: 'プロジェクトがあります。',
    create_success_title: '新しい企業が追加されました',
    create_success_desc: '新しい企業が名前: {{name}} で追加されました。',
    no_division: 'この企業にはまだ部門がありません。',
    save_confirm_title: 'パートナー企業の詳細を保存することを確認します',
    save_confirm_desc:
      '進行しますか？保存する前に、すべての詳細が正確であることを確認してください。',
  },
  PARTNER_DIVISION: {
    list_page_title: 'パートナー部門一覧',
    name_column: '名前',
    no_column: 'いいえ',
    projects_column: 'プロジェクト',
    description_column: '説明',
    actions_column: 'アクション',
    lead_column: 'リーダー',
    division_name: '部門名',
    delete_modal_title: '本当に削除しますか？',
    delete_modal_description:
      '選択した部門を本当に削除しますか？この操作は元に戻せません。',
    company_name_column: '企業名',
    division_create: '部門作成',
    lead: '部門リーダー',
    company_name: '企業名',
    divisions: 'パートナー部門',
    divisions_edit: '部門編集',
    name_placeholder: '部門名',
    description_placeholder: '部門の説明',
    lead_placeholder: 'リーダー名',
    create_success_title: '新しい部門が追加されました',
    create_success_desc:
      '新しい部門がID: {{id}} と名前: {{name}} で追加されました',
    save_confirm_title: 'パートナー部門の詳細の保存を確認',
    save_confirm_desc:
      '進めますか？保存する前にすべての詳細が正確であることを確認してください。',
    display_order: '表示順',
    order: '表示順',
  },
  PROJECT: {
    title: 'プロジェクト',
    resource: 'プロジェクト',
    list_title: 'プロジェクト',
    project_name: 'プロジェクト名',
    description_placeholder: 'プロジェクトの説明',
    description: '説明',
    members: 'メンバー',
    member_management: 'メンバー管理',
    add_member: 'メンバーの追加',
    manage_access: 'アクセス管理',
    status: 'ステータス',
    project_status: 'プロジェクトステータス',
    member_remove: 'メンバー削除',
    cost_start: '工数開始',
    cost_end: '工数終了',
    partner_company: '提携会社',
    partner_division: 'パートナー部門',
    cost: '工数',
    expire: '有効期限',
    created_at: '作成日',
    search_with_name_email: '名前またはメールで検索',
    member_delete_title: 'プロジェクトメンバーの削除',
    member_delete_desc: '本当にこのメンバーを削除しますか？',
    member_add_title: 'プロジェクトメンバーの追加',
    member_add_desc: '{{name}} が追加されました',
    edit_member: 'メンバー編集',
    report_title: 'レポート',
    cost_report_title: '工数レポート',
    role: '役割',
    edit_help_title: 'プロジェクト編集',
    member_help_title: 'プロジェクト編集',
    report_help_title: 'レポート',
    featured_image: '特集画像',
    settings: 'プロジェクト設定',
    delete: 'プロジェクト削除',
    delete_modal_title: '本当にこのプロジェクトを削除しますか？',
    delete_modal_description: 'この操作は元に戻せません。進行しますか？',
    cost_plan: '工数計画',
    overview: '概要',
    no_description:
      'このエキサイティングなプロジェクトへようこそ！詳細な説明がまだありませんが、',
    total: '合計: ',
    total_members: '総メンバー数:',
    project: 'プロジェクト',
    total_cost: '合計工数',
    count: 'カウント',
    create_title: 'プロジェクト作成',
    create_success_title: '新しいプロジェクトが追加されました',
    create_success_desc:
      '新しいプロジェクトがID: {{id}} と名前: {{name}} で追加されました',
    owner: 'オーナー',
    role_member: 'メンバー',
    role_manager: 'マネージャー',
    history: '活動履歴',
    quit_status: '退出',
    project_type: 'プロジェクトタイプ',
    updated_at: '更新日',
    status_on_hold: '保留中',
    status_new: '新規',
    status_in_progress: '進行中',
    status_completed: '完了',
    type_research: 'リサーチ',
    type_new_project: '新規プロジェクト',
    type_maintenance: 'メンテナンス',
    type_renewal: '更新',
    read_owner_warning_title: '編集できません',
    read_owner_warning_msg:
      'プロジェクトオーナーですが、編集する許可がありません。',
    read_owner_warning_desc:
      '権限の更新についてはシステム管理者にお問い合わせてください。',
    member_add_pre_dialog_title: '追加されるメンバーの詳細',
    member_update_pre_dialog_title: '編集されるメンバーの詳細',
    member_upsert_dialog: {
      project_name: 'プロジェクト名：',
      name: '名前：',
      role: '役割：',
      position: '役職：',
      cost: '工数：',
      cost_start_date: '工数開始日：',
      expire_date: '有効期限日：',
      start_date: '開始日:',
      end_date: '終了日:',
    },
    save_confirm_title: 'プロジェクトの詳細を保存することを確認してください',
    save_confirm_desc:
      '進めますか？保存する前に、すべての詳細が正確であることを確認してください。',
    joined: '参加しました',
    cost_changed: '工数が変更されました',
    role_changed: '役割が変更されました',
    left: '退会しました',
    project_started: 'プロジェクトが開始されました',
    cost_started: '工数が開始されました',
    cost_ended: '工数が終了しました',
    project_ended: 'プロジェクトが終了しました',
    project_created: 'プロジェクトが作成されました',
    cost_start_at_changed: '工数の開始日が変更されました',
    project_name_changed: 'プロジェクト名が変更されました',
    history_dialog_title: '工数履歴の削除確認',
    history_dialog_desc:
      '不要なコスト履歴を削除します。続行する前に確認してください',
    partner_purchase_order: 'パートナー購買注文',
    filter_project_dialog_title: 'プロジェクトをフィルターする',
    employee_filter: '社員',
    division_filter: '部門',
    still_active: 'Still active',
    start_at_changed: '開始時刻が変更されました',
    member_start_at: '開始日',
    member_expire_at: '有効期限',
  },
  PROJECT_ACTIVITY: {
    joined: 'が役割{{role}}で参加し',
    something_changed: '{{what}}が{{from}}から{{to}}に変更されました。',
    left: 'がプロジェクトを離れました。',
    project_started: 'プロジェクト開始日',
    cost_started: '工数開始日',
    cost_ended: '工数終了日',
    project_ended: 'プロジェクト終了日',
    project_created: 'プロジェクトが作成されました',
    cost_changed: 'メンバーの工数が変更されました',
    performed_by: '実行者',
    role_changed: '役割',
    cost_start_at_changed: '工数開始日が変更されました',
    unspecified: '指定なし',
    project_name_changed: 'プロジェクト名が変更されました',
    start_at_changed: '開始時刻が変更されました',
    member_filter_placeholder: 'メンバーでフィルター',
    action_filter_placeholder: 'アクションでフィルター',
  },
  PROJECT_PARTNER_PURCHASE_ORDER: {
    title: 'パートナー部門注文',
    add_title: 'パートナー部門注文の追加',
    position: '役職',
    create_success_title: '新しいパートナー部門注文が追加されました',
    create_success_desc:
      '役職: {{position}} で新しいパートナー部門注文が追加されました',
    save_confirm_title: 'パートナー部門注文の詳細の保存を確認',
    save_confirm_desc:
      '続行しますか？保存前にすべての詳細が正確であることを確認してください。',
    delete_modal_title: 'このパートナー部門注文を削除してもよろしいですか？',
    delete_modal_description:
      '続行しますか？このプロセスは元に戻すことができません。',
  },
  POSITION: {
    menu_title: '役職',
    page_title: '役職',
    list_title: '役職一覧',
    position_name: '役職名',
    no_column: 'いいえ',
    name_column: '名前',
    visibility_column: '表示フラグ',
    hidden: '非表示',
    shown: '表示',
    members: 'メンバー',
    create_title: '役職作成',
    delete_modal_title: 'この役職を削除してもよろしいですか？',
    delete_modal_description:
      '選択した役職を本当に削除しますか？この操作は元に戻せません。',
    create_success_title: '新しい役職が追加されました',
    create_success_desc:
      '新しい役職がID: {{id}} と名前: {{name}} で追加されました',
    save_confirm_title: '役職の詳細を確認',
    save_confirm_desc:
      '進めることを希望しますか？保存する前にすべての詳細が正確であることを確認してください。',
    change_order: '表示順の修正',
    order_title: '表示順の修正',
    display_order: '表示順',
    order: '表示順',
    short_name: '略称',
    is_default_filter: 'リソースプランからのフィルター',
    is_default_filter_desc:
      'このボックスにチェックを入れた場合、この職種のユーザーは自動的にリソースプラン一覧に含まれません。',
  },
  ROLE: {
    menu_title: '役割',
    list: '役割一覧',
    create_role: '役割の作成',
    no_column: 'いいえ',
    name_column: '名前',
    actions_column: 'アクション',
    delete_modal_title: 'この役割を削除してもよろしいですか？',
    delete_modal_description:
      '選択した役割を本当に削除しますか？この操作は元に戻せません。',
    name_placeholder: '役割名',
    resource_management: 'リソース管理',
    time_management: '勤怠管理',
    division: '部門',
    project_management: 'プロジェクト管理',
    task_management: 'タスク管理',
    create_success_title: '新しい役割が追加されました',
    create_success_desc:
      '新しい役割がID: {{id}} と名前: {{name}} で追加されました',
    create_duplicate_title: '作成前にこの役割をコピーしますか？',
    create_duplicate_desc: '作成前にすべての役割の権限がコピーされます。',
    copy_of: 'これは{{name}}のコピーです。',
    copy_text: 'これはのコピーです ',
    save_confirm_title: '役割の詳細の保存を確認',
    save_confirm_desc:
      '進めますか？保存する前にすべての詳細が正確であることを確認してください。',
    write: 'ライト',
    read: 'リード',
    admin: 'アドミン',
  },
  EMPLOYEE: {
    list: '社員',
    create_page_title: '社員作成',
    edit_page_title: '社員情報を編集する',
    firstname: '名',
    firstname_placeholder: '社員の名',
    firstname_en: '名（英語）',
    firstname_en_placeholder: '社員の英語の名',
    lastname: '姓',
    lastname_placeholder: '社員の姓',
    lastname_en: '姓（英語）',
    lastname_en_placeholder: '社員の英語の姓',
    employee_name: '社員名',
    email: 'メールアドレス',
    email_placeholder: '社員のメールアドレス',
    user_code: 'ユーザーコード',
    user_code_placeholder: '社員のユーザーコード',
    gender: '性別',
    position: '役職',
    department: '部署',
    name_placeholder: '社員の名前',
    phone_placeholder: '社員の電話',
    create_success_title: '新しい社員が追加されました',
    create_success_desc: '名前: {{name}} の新しい社員が追加されました',
    edit_success_title: '社員情報を更新しました',
    edit_success_desc: '名前: {{name}} の社員が更新されました',
    resource: '社員',
    name_column: '名前',
    user_code_column: 'ユーザーコード',
    phone_column: '電話',
    gender_column: '性別',
    status_column: 'ステータス',
    role_column: '役割',
    position_column: '役職',
    department_column: '部署',
    actions_column: 'アクション',
    status: 'ステータス',
    status_deleted: '削除済み',
    status_active: 'アクティブ',
    status_maternity_leave: '産休中',
    status_quit: '退職',
    status_superadmin: 'スーパーアドミン',
    role: '役割',
    relation: '関連',
    delete_modal_title: '本当に削除しますか？',
    delete_modal_description:
      '選択した社員を本当に削除しますか？この操作は元に戻せません。',
    skills_tab: 'スキル',
    project_history_tab: 'プロジェクト履歴',
    projects_tab: 'アクティブプロジェクト',
    project_history_empty: `このユーザーはまだプロジェクトに参加していません。`,
    ndsh_months: 'NDSH月',
    hired_date: '雇用日',
    quit_date: '退職日',
    filter_employees_dialog_title: '社員フィルタ',
    quit_modal_title: '退職フォームを提出しますか？',
    quit_modal_desc:
      '後で退職日を変更するか、アクティブユーザに戻すこともできます。',
    personal_details: '個人詳細',
    company_related: '企業関連',
    contact_details: '連絡先詳細',
    status_settings_dialog_title: 'ステータス設定',
    ndsh_total: 'NDSH トータル',
    ndsh_placeholder: '月間合計',
    save_confirm_title: '社員の詳細を保存の確認',
    save_confirm_desc:
      '進めることを希望しますか？保存する前にすべての詳細が正確であることを確認してください。',
    home_address: '自宅住所',
    home_address_placeholder: '社員の自宅住所',
    quit_reason: '退職理由',
    current_status_deleted: '削除済み',
    current_status_active: 'アクティブ',
    current_status_maternity_leave: '産休中',
    current_status_quit: '退職',
    current_status_long_term_leave: '長期休暇中',
    current_status_day: '休暇中',
    current_status_day_am: '午前半休中',
    current_status_day_pm: '午後半休中',
    current_status_compensatory_day: '代休暇中',
    current_status_holiday_work: '休日勤務中',
    current_status_workation: '有給休暇に勤務中',
    current_status_compensatory_request: '代休暇中',
    current_status_special: '特別有給休暇中',
    back_to_work_from_long_term_leave: '長期休暇を終了',
    back_to_work_date: '仕事に戻る日',
    long_term_start_date: '長期休暇開始：',
    impairment: '障害者ですか？',
    impaired_desc:
      'このボックスをチェックすると、身体の障害があることを示します。',
    time_required: 'Time register required',
    import: 'インポート',
    template: 'テンプレート',
    row_errors: '{{error}} 行の次の列でエラーが発生しました。',
    column_errors: ' 列エラー',
    success: '追加されたすべてのユーザーの初期パスワードは Teamsync@123 です。',
  },
  EMPLOYEE_CURRENT_DAY_STATUS: {
    current_not_started_work: '作業未開始',
    current_working: '作業中',
    current_work_done: '作業完了',
    current_vacation: '休暇中',
    current_holiday: '休暇中',
  },
  DEPARTMENT: {
    menu_title: '部署',
    title: '部署',
    list_title: '部署一覧',
    name_hint: '名前を入力',
    name_column: '部署名',
    lead_column: '部署責任者',
    delete_modal_title: '本当に削除しますか？',
    delete_modal_description:
      '選択した部署を本当に削除しますか？この操作は元に戻せません。',
    create_success_title: '新しい部署が追加されました',
    create_success_desc: '名前: {{name}} の新しい部署が追加されました',
    create_title: '部署作成',
    save_confirm_title: '部署の詳細を保存の確認',
    save_confirm_desc:
      '進行しますか？保存する前にすべての詳細が正確であることを確認してください。',
    order: '表示順',
  },
  SKILL_CATEGORY: {
    title: 'スキルカテゴリ',
    create_success_title: '新しいスキルカテゴリが追加されました',
    create_success_desc: '{{name}} が正常に追加されました',
    delete_modal_title: 'このカテゴリの下のすべてのスキルが削除されます！',
    delete_modal_description:
      '選択したカテゴリを本当に削除しますか？この操作は元に戻せません。',
    name_placeholder: 'カテゴリ名',
    description_placeholder: 'カテゴリの説明',
    list_page_title: 'スキルカテゴリ',
    create: 'スキルカテゴリ作成',
    save_order: '順序を保存',
    save_confirm_title: '保存の確認：スキルカテゴリの詳細',
    save_confirm_desc:
      '進めることを希望しますか？保存する前に、すべての詳細が正確であることを確認してください。',
    display_order_column: '表示順',
    name_column: '名前',
    change_order: '表示順の修正',
    order: '表示順',
    target_placeholder: '対象',
    target_all: '全て',
    target_specific: '特定',
    target_positions: '対象役職',
  },
  SKILL: {
    title: 'スキル',
    create_success_title: '新しいスキルが追加されました',
    create_success_desc: '{{name}} が正常に追加されました',
    delete_modal_title: '本当にこのスキルを削除しますか？',
    delete_modal_description: '続行しますか？この操作は元に戻せません',
    name_placeholder: 'スキル名',
    description_placeholder: 'スキルの説明',
    list_page_title: 'スキル',
    create: 'スキルを作成',
    filter: 'フィルタ',
    skill_report: 'スキルレポート',
    save_confirm_title: 'スキルの詳細保存の確認',
    save_confirm_desc:
      '進むことを希望しますか？保存する前に、すべての詳細が正確であることを確認してください。',
    menu_title: 'スキル',
  },
  TAG: {
    title: '業務の種類',
    menu_title: '業務の種類',
    create_success_title: '新しい作業タイプが追加されました',
    create_success_desc: '{{name}} が正常に追加されました。',
    delete_modal_title: 'この作業タイプを削除してもよろしいですか？',
    delete_modal_description: '実行しますか？この操作は元に戻せません。',
    name_placeholder: '作業タイプの名前',
    description_placeholder: '作業タイプの説明',
    list_page_title: '作業タイプのリスト',
    create: '作業タイプを作成',
    filter: 'フィルター',
    save_confirm_title: '作業タイプの詳細を保存する確認',
    save_confirm_desc:
      '実行しますか？保存する前にすべての詳細が正確であることを確認してください。',
    is_all: '全ての役職を選択',
  },
  USER_SKILL: {
    title: 'スキルの追加または編集',
    add_button: 'スキルを追加',
    skill: 'スキル',
    create_success_title: '新しいスキルが追加されました',
    create_success_desc: 'スキルが正常に追加されました。',
    confirm_edit_modal_title: '変更を保存しますか？',
    confirm_edit_modal_desc: 'この操作は元に戻せません。',
    delete_modal_title: '本当に削除しますか？',
    delete_modal_desc: 'この操作は元に戻せません。',
    add_title: 'スキルの追加',
    skill_empty: 'このユーザーはまだスキルを追加していません。',
    save_confirm_title: 'スキルの詳細を保存を確認',
    save_confirm_desc:
      '進むことを希望しますか？保存する前に、すべての詳細が正確であることを確認してください。',
  },
  SKILL_REPORT: {
    position_filter_hint: '社員を役職でフィルタリングする',
  },
  RESOURCE_PLAN: {
    list_title: 'リソースプラン一覧',
    title: 'リソースプラン',
    menu_title: 'リソースプラン一覧',
    name: 'プラン名',
    create: '新しいプランを作成',
    name_placeholder: '名前',
    name_column: '名前',
    confirmed_column: '確認済み',
    created_column: '作成済み',
    status_column: 'ステータス',
    actions_column: 'アクション',
    create_success_title: '新しいリソースプランが追加されました',
    create_success_desc: 'リソースプランが正常に追加されました。',
    status_planning: '計画中',
    status_confirmed: '確認済み',
    confirm: '確認',
    unconfirm: '確認解除',
    copy_modal_title: 'このプランをコピーしますか？',
    copy_modal_description:
      'この操作により、選択したプランのコピーが作成されます',
    copy_success_title: '正常にコピーされました',
    copy_success_description: 'コピーされたプランが正常に追加されました。',
    delete_modal_title: '本当に削除しますか？',
    delete_modal_description: 'この操作は元に戻せません。',
    confirm_plan_modal_title: 'プランを確認しますか？',
    confirm_plan_modal_description:
      '確認済みのプランは編集できませんが、後で確認解除することもできます。',
    unconfirm_modal_title: 'プランの確認を解除しますか？',
    unconfirm_modal_description: '後で確認することもできます。',
    filter_employees_dialog_title: '社員をフィルタ',
    filter_employees_dialog_desc:
      'リストで表示したくない役職を選択してください:',
    total: '合計',
    not_include: '合計社員コスト（すべての役職が含まれていません）',
    total_employee_cost: '合計社員コスト',
    target_month: '対象月',
    copy_dialog_title: '新しいコピーの対象月を入力してください',
    source: 'ソース: ',
    confirm_error_modal_title: '確認できませんでした',
    empty_project_alert_title: 'プロジェクト一覧が空です',
    empty_project_alert_desc:
      'プロジェクト一覧が空です。グリッドデータを表示できません。',
    error_alert_title: 'エラー',
    error_alert_desc:
      'データグリッドの初期データをロードする際にエラーが発生しました。',
    rename_title: 'タイトルの名前を変更',
    calculate: '計算する',
    recalculate: '再計算',
    calculate_modal_title: 'このリソースプランを計算してもよろしいですか？',
    calculate_modal_description:
      '計算済みのリソースプランでは編集は不可能ですが、後で設定を変更できます。',
    modify_button: '修正',
    new_employee_cell:
      '2〜3ヶ月。この社員のためにすぐに工数を調整することを検討してください。',
    activity_log_button: '活動履歴',
    activity_history_title: '活動履歴',
    calculated_column: '計算済み',
    confirm_add_user_title: 'Add User',
    confirm_add_user_desc: 'Are you sure to add user to resource plan?',
    confirm_remove_user_title:
      'リソース プランからユーザーを削除してもよろしいですか?',
    completed: '完了しました',
  },
  RESOURCE_PLAN_DATAGRID: {
    employees: '社員',
    custom_position: 'リソース役職',
    total: '合計',
  },
  RESOURCE_ACTIVITY: {
    created: '作成済み',
    rename: '名前の変更あり',
    confirm: '確認済み',
    unconfirm: '未確認',
    calculated: '計算済み',
    unknown: '',
    updated: 'Updated',
  },
  COST_REPORT: {
    filter_by_divisions: 'パートナー部門で絞り込む',
    start_date: '開始日',
    end_date: '終了日',
    month: '月',
    date_range_label: '期間指定',
    total_partner_division: 'パートナー部門別合計',
    purchased_count: '購入数',
    position_by_division: '部署別の役職',
    total_count: '合計数',
    total_cost: '合計工数',
    purchase_total_count: '予定数',
    purchase_total_cost: '予定工数',
    ordered_cost: '注文工数',
    partner_division: 'パートナー部門',
    division_name: '部門名',
    actual_cost: '実際のコスト',
    total_ordered_cost: '合計注文コスト',
    list_title: 'Cost Reports',
  },
  ACTUAL_COST_REPORT: {
    title: '実際の工数レポート',
    employee: '社員',
    planned: '計画',
    actual: '実際',
    ordered: '注文済み',
    positions: 'ポジション',
    other: 'その他',
    total: '合計',
    timeoff_days: '休暇日',
    work_dates: '勤務日: {{days}}',
    holidays: '休日: {{days}}',
    within_invoice: '請求有プロジェクト(休暇除く)',
    within_invoice_timeoff: '請求有プロジェクト(休暇含む)',
    actual_work_date: '作業日報(休暇除く)',
    projects: '全プロジェクト(休暇含む)',
    all_worked: '作業日報(休暇含む)',
    detail_title: '実際のコストレポートの詳細',
  },
  MEETING_ROOM_ORDER: {
    title: '会議室',
    side_content_title: '会議',
    start_time: '開始時間',
    end_time: '終了時間',
    create_title: '会議室を予約する',
    order_button: '予約する',
    delete: '会議を削除',
    delete_modal_title: '会議室の予約を削除',
    delete_modal_description: 'この会議室の予約を削除してもよろしいですか？',
    subject: '件名',
    meeting_room: '会議室',
    create_success_title: '会議室の予約が成功しました',
    create_desc: '件名: {{subject}} で会議室を予約しました。',
    create_fail_title: '時間が重複しています',
    create_fail_desc:
      'この部屋には別の会議が {{startTime}} から {{endTime}} に予定されています。',
    ordered_by: '予約者',
    no_meeting_room: '会議室が見つかりませんでした。',
    none: '繰り返しなし',
    daily: '毎日',
    weekly: '毎週',
    monthly: '毎月',
    mon: '月',
    tue: '火',
    wed: '水',
    thu: '木',
    fri: '金',
    sat: '土',
    sun: '日',
    week: '週',
    month: '月',
    repeat_every: '繰り返し',
    repeat_on: '繰り返し日',
    days_of_month: '月の日',
    ends: '終了',
    expire_date: '終了日',
    frequency: '頻度',
    is_recurring: 'Repeatable',
    delete_all_title: '全て削除しますか？',
    delete_all_description: '会議室の予約をすべて削除しますか？',
    only_this: 'この項目のみ',
    change_all_title: 'すべて変更しますか？',
    change_all_description:
      '会議室の予約をすべて変更しますか？このイベントのみをクリックすると定期的ではなくなります',
    delete_all: '全て削除',
    change_all: '全て変更',
    help_title: '繰り返し頻度の設定方法',
    help_text: '1. 繰り返す回数を入力\n2. 時間単位を選択',
    example: '例：2週間 = 繰り返し回数 [2] 頻度 [週間]',
    expire_required: 'デフォルト：1年',
    repeat_type: 'Frequency',
    change_recurrence_title: '繰り返しルールの変更',
    change_recurrence_description:
      'このイベントの繰り返しルールを変更すると、このイベントと後続のイベントに影響します。',
  },
  MEETING_ROOM: {
    meeting_room: '会議室',
    list_title: '会議室一覧',
    order: '表示順',
    name_column: '名前',
    description_column: '説明',
    name_placeholder: '会議室の名前',
    description_placeholder: '会議室の説明',
    change_order: '順序を変更する',
    save_order: '順序を保存する',
    delete_modal_title: '本当によろしいですか？',
    delete_modal_description:
      '選択した会議室を削除しますか？このプロセスは元に戻せません。',
    create_success_title: '新しい会議室が追加されました',
    create_success_desc: '名前: {{name}} の新しい会議室が追加されました',
    create_title: '会議室の作成',
    save_confirm_title: '会議室の詳細を保存しますか？',
    save_confirm_desc:
      '続行しますか？保存する前にすべての詳細が正確であることを確認してください。',
  },
  SYSTEM_SETTINGS: {
    system_settings: 'システム設定',
    name: '名前',
    value: '値',
    value_type: '値のタイプ',
    number: '数字',
    string: '文字列',
    time: '時間',
    date: '日付',
    json: 'JSON',
    save_confirm_title: 'システムの保存を確認',
    save_confirm_desc:
      '続行しますか？保存する前にすべての詳細が正確であることを確認してください。',
    cancel_confirm_title: '編集中の内容がありそうです。',
    cancel_confirm_desc: '保存せずに出ると、変更内容は失われます。',
  },
  APPROVAL_TEMPLATES: {
    title: '承認テンプレート',
    name: '名前',
    name_placeholder: '承認テンプレート名',
    status: 'ステータス',
    active: 'アクティブ',
    inactive: '非アクティブ',
    details_title: '承認テンプレートの詳細',
    change_step: 'ステップの変更',
    step: 'ステップ',
    is_manual: '手動',
    positions: 'ポジション',
    order_title: 'ステップ順の変更',
    save_order: '順序を保存',
    is_manual_placeholder: '手動である',
    positions_placeholder: '承認するポジション',
    save_confirm_title: '承認テンプレートの詳細を確認',
    create: '承認テンプレートを作成',
    create_step: '承認テンプレートのステップを作成',
    save_title: '承認テンプレートを確認',
    save_desc:
      '続行しますか？保存する前に承認テンプレートの名前を確認してください。',
    save_confirm_desc:
      '続行しますか？保存する前にすべての詳細を確認してください。',
    cancel_confirm_title: '編集中の内容があります。',
    cancel_confirm_desc: '保存せずに離れると、変更内容が失われます。',
    create_success_title: '新しい承認テンプレートが作成されました',
    create_success_desc:
      '名前: {{name}} で新しい承認テンプレートが作成されました。',
    create_detail_success_title: '新しいステップが追加されました',
    create_detail_success_desc:
      'ID: {{id}} とステップ: {{step}} で新しいステップが追加されました。',
    delete_modal_title: '承認テンプレートを削除',
    delete_modal_description:
      'この承認テンプレートを削除してもよろしいですか？',
    allow_reason: 'Allow deny reason',
  },
  VACATION_BALANCES: {
    title: '有給休暇状況',
    employees: '社員',
    hired_date: '採用日',
    ndsh_before: '前NDSH',
    ndsh_total: '合計NDSH',
    days: '日数',
    remaining_days: '残り日数',
    used_days: '使用日数',
    create: '追加',
    active: '有効',
    status: 'ステータス',
    inactive: '無効',
    year_placeholder: '休暇年',
    user_placeholder: '社員',
    days_placeholder: '合計日数',
    remaining_days_placeholder: '残り日数',
    start_at_placeholder: '開始日',
    end_at_placeholder: '終了日',
    create_success_title: '新しい社員の休暇残高が追加されました',
    create_success_desc:
      '年：{{year}}で日数：{{days}}を持つ新しい社員が追加されました',
    calculate: '計算する',
    save_confirm_title: '社員の休暇を保存しますか？',
    save_confirm_desc:
      '続行しますか？保存する前にすべての詳細が正確であることを確認してください。',
    add_user: '社員を追加',
    dialog_title: '社員全員で計算する',
    all_employee: 'すべての社員',
    new_employee: 'この社員の有給休暇残高は計算されるべきです',
    remaining_days_total: '残り日数の合計',
    user_history_dialog_title: '{{year}}の休暇詳細',
    start_date: '開始日',
    end_date: '終了日',
    timeoff_type: '休暇の種類',
    duration: '期間',
    total_vacation_days: '合計休暇日数',
    table_vacation_days: '休暇日数',
    table_remaining_days: '休暇残高',
  },
  VACATION_REPORT: {
    report_title: '従業員の休暇報告書',
    report_summary: '総休暇',
    pre_paid: '前払い',
    post_paid: '後払い',
    vacation_report: '従業員の休暇概要',
    employee: '社員',
    remaining_days: '残り日数',
    used_days: '使用日数',
    year: ' 年',
    more: '詳細',
    type: '休暇の種類',
    nodata: 'レコードが見つかりません',
  },
  VACATION_INFO: {
    show: '休暇情報を見る',
    hide: '休暇情報を隠す',
    employee: '社員コード／名前：',
    hired_date: '雇用日：',
    ndsh_months_before: 'NDSH月／入社前／：',
    ndsh_months_total: 'NDSH月／合計／：',
    vacation_days: '有給休暇日：',
    remaining_days: '有給休暇残高：',
    info: '通常の労働条件での勤務年数に応じて、基本休暇に以下の通り休暇日が追加されます。基本休暇　ー　{{day}}日。',
    yearly: '{{year}}年以上：{{day}}日',
    month: '{{month}}月',
    days_in_year: '{{day}}日（{{year}}）、',
    compensatory_balance: '補償残高：',
    year: '年',
    table_vacation_days: '有給休暇日数',
    table_remaining_days: '有給休暇残高',
  },
  TOASTS: {
    forgot_password_fulfilled: '確認コードがメールに送信されました。',
    forgot_password_rejected: 'リクエストの送信に失敗しました。',
    password_reset_fulfilled: 'パスワードがリセットされました',
    password_reset_rejected: 'パスワードのリセットに失敗しました',
    change_password_fulfilled: 'パスワードが正常に変更されました。',
    change_password_rejected: 'パスワードの変更に失敗しました',
    switch_lang_rejected:
      'ユーザープロファイルへのデフォルト言語の保存に失敗しました',
    // Company
    create_company_rejected: '会社の追加に失敗しました',
    update_company_fulfilled: '会社が正常に更新されました',
    update_company_rejected: '会社の更新に失敗しました',
    delete_company_fulfilled: '会社を削除しました',
    delete_company_rejected: '会社の削除に失敗しました',
    // Division
    update_division_fulfilled: '部署が正常に更新されました。',
    update_division_rejected: '現在の部署の更新に失敗しました。',
    create_division_fulfilled: '部署が正常に追加されました。',
    create_division_rejected: '部署の追加に失敗しました。',
    delete_division_fulfilled: '部署を正常に削除しました。',
    delete_division_rejected: '部署の削除に失敗しました。',
    update_division_order_fulfilled: '部門の注文が更新されました',
    update_division_order_rejected: '注文の更新に失敗しました',
    // Position
    update_position_fulfilled: '役職が正常に更新されました。',
    update_position_rejected: '役職の更新に失敗しました。',
    delete_position_fulfilled: '役職を正常に削除しました。',
    delete_position_rejected: '役職の削除に失敗しました。',
    update_position_order_fulfilled: '役職の注文が更新されました',
    update_position_order_rejected: '注文の更新に失敗しました',
    // Department
    create_department_fulfilled: '部門が正常に追加されました。',
    create_department_rejected: '部門の追加に失敗しました。',
    update_department_fulfilled: '部門が正常に更新されました。',
    update_department_rejected: '部門の更新に失敗しました。',
    delete_department_fulfilled: '部門を正常に削除しました。',
    delete_department_rejected: '部門の削除に失敗しました。',
    // Project
    delete_project_fulfilled: 'プロジェクトが正常に削除されました。',
    delete_project_rejected: 'プロジェクトの削除に失敗しました。',
    create_user_rejected: 'ユーザーの追加に失敗しました。',
    create_user_fulfilled: 'ユーザーが正常に追加されました。',
    update_user_rejected: 'ユーザーの更新に失敗しました。',
    update_user_fulfilled: 'ユーザーが正常に更新されました。',
    delete_project_user_rejected: 'プロジェクトユーザーの削除に失敗しました。',
    delete_project_user_fulfilled:
      'プロジェクトユーザーが正常に削除されました。',
    update_project_fulfilled: 'プロジェクトが正常に更新されました。',
    update_project_rejected: 'プロジェクトの更新に失敗しました。',
    update_project_image_fulfilled:
      'プロジェクト画像が正常にアップロードされました。',
    update_project_image_rejected:
      'プロジェクト画像のアップロードに失敗しました。',
    //Partner purchase order
    create_partner_purchase_order_fulfilled:
      'パートナー部門注文が正常に追加されました。',
    create_partner_purchase_order_rejected:
      'パートナー部門注文の追加に失敗しました。',
    update_partner_purchase_order_fulfilled:
      'パートナー部門注文が正常に更新されました。',
    update_partner_purchase_order_rejected:
      'パートナー部門注文の更新に失敗しました。',
    delete_partner_purchase_order_fulfilled:
      'パートナー部門注文が正常に削除されました。',
    delete_partner_purchase_order_rejected:
      'パートナー部門注文の削除に失敗しました。',
    // Role
    delete_role_rejected: '役割の削除に失敗しました。',
    delete_role_fulfilled: '役割が正常に削除されました。',
    update_role_rejected: '役割の更新に失敗しました。',
    update_role_fulfilled: '役割が正常に更新されました。',
    // Event
    event_update_fulfilled: 'イベントが更新されました',
    event_update_rejected: '更新に失敗しました',
    event_delete_rejected: '削除に失敗しました',
    event_delete_fulfilled: 'イベントが削除されました',
    // Skill
    update_skill_rejected: '更新に失敗しました',
    update_skill_fulfilled: 'スキルが更新されました',
    delete_skill_rejected: '削除に失敗しました',
    delete_skill_fulfilled: 'スキルが削除されました',
    // Skill category
    update_skill_category_rejected: '更新に失敗しました',
    update_skill_category_fulfilled: 'スキルカテゴリが更新されました',
    update_skill_category_order_rejected: '順序の更新に失敗しました',
    update_skill_category_order_fulfilled:
      'スキルカテゴリの順序が更新されました',
    delete_skill_category_rejected: '削除に失敗しました',
    delete_skill_category_fulfilled: 'スキルカテゴリが削除されました',
    // User skill
    delete_user_skill_by_id_rejected: '削除に失敗しました',
    delete_user_skill_by_id_fulfilled: 'スキルが削除されました',
    update_user_skills_rejected: '更新に失敗しました',
    update_user_skills_fulfilled: 'スキルが更新されました',
    update_single_skill_rejected: '更新に失敗しました',
    update_single_skill_fulfilled: 'スキルが更新されました',
    // Resource Plan
    create_resource_plan_rejected: '新しいプランの作成に失敗しました',
    rename_resource_plan_fulfilled: '正常に名前変更されました',
    rename_resource_plan_rejected: 'プランの名前変更に失敗しました',
    confirm_resource_plan_fulfilled: '正常に確認されました',
    confirm_resource_plan_rejected: 'プランの確認に失敗しました',
    refuse_resource_plan_fulfilled: '正常に確認解除されました',
    refuse_resource_plan_rejected: 'プランの確認解除に失敗しました',
    delete_resource_plan_fulfilled: '正常に削除されました',
    delete_resource_plan_rejected: 'プランの削除に失',
    // Employee
    update_employee_rejected: '更新に失敗しました',
    update_employee_fulfilled: '社員が更新されました',
    delete_employee_rejected: '削除に失敗しました',
    delete_employee_fulfilled: '社員が削除されました',

    quit_employee_rejected: '退職フォームの提出に失敗しました',
    quit_employee_fulfilled: '退職フォームが提出されました',
    cell_update_failed: 'セルの更新に失敗しました',
    calculate_resouse_plan_fulfilled: 'リソースプランが正常に計算されました',
    update_quit_reason_rejected: '更新に失敗しました',
    update_quit_reason_fulfilled: '退職理由を更新しました',

    reset_password_employee_fulfilled:
      '従業員のパスワードが正常にリセットされました',
    reset_password_employee_rejected:
      ' 従業員のパスワードをリセットできませんでした',
    // Meeting room
    create_meeting_room_fulfilled: '会議室を追加しました。',
    create_meeting_room_rejected: '会議室の追加に失敗しました。',
    update_meeting_room_fulfilled: '会議室を更新しました。',
    update_meeting_room_rejected: '会議室の更新に失敗しました。',
    delete_meeting_room_fulfilled: '会議室を削除しました。',
    delete_meeting_room_rejected: '会議室の削除に失敗しました。',
    update_system_settings_fulfilled: 'システム設定が更新されました。',
    update_system_settings_rejected: 'システム設定の更新に失敗しました。',
    meeting_room_update_order_fulfilled: '会議室の注文が更新されました',
    meeting_room_update_order_rejected: '注文の更新に失敗しました',
    // Book meeting room
    create_meeting_room_order_rejected: '会議室の予約に失敗しました',
    update_meeting_room_order_fulfilled: '会議室の予約が更新されました',
    update_meeting_room_order_rejected: '会議室の予約の更新に失敗しました',
    delete_meeting_room_order_fulfilled: '会議室の予約が削除されました',
    delete_meeting_room_order_rejected: '会議室の予約の削除に失敗しました',
    //Email campaign
    create_email_campaign_fulfilled: 'メールキャンペーンの追加に成功しました。',
    create_email_campaign_rejected: 'メールキャンペーンの追加に失敗しました。',
    update_email_campaign_fulfilled: 'メールキャンペーンの更新に成功しました。',
    update_email_campaign_rejected: 'メールキャンペーンの更新に失敗しました。',
    delete_email_campaign_fulfilled: 'メールキャンペーンの削除に成功しました。',
    delete_email_campaign_rejected: 'メールキャンペーンの削除に失敗しました。',
    send_bulk_failed: '送信に失敗しました',
    send_bulk_success: '送信に成功しました',
    //Email template
    create_email_template_fulfilled: 'メールテンプレートの追加に成功しました。',
    create_email_template_rejected: 'メールテンプレートの追加に失敗しました。',
    update_email_template_fulfilled: 'メールテンプレートの更新に成功しました。',
    update_email_template_rejected: 'メールテンプレートの更新に失敗しました。',
    delete_email_template_fulfilled: 'メールテンプレートの削除に成功しました。',
    delete_email_template_rejected: 'メールテンプレートの削除に失敗しました。',
    unknown_rejected: 'Something went wrong',
    // Timeoff template
    update_timeoff_template_rejected: '休暇テンプレートの更新に失敗しました。',
    update_timeoff_template_fulfilled: '休暇テンプレートの更新に成功しました。',
    delete_timeoff_template_rejected: '休暇テンプレートの削除に失敗しました。',
    delete_timeoff_template_fulfilled: '休暇テンプレートの削除に成功しました。',
    //Approval template
    update_approval_template_fulfilled: '承認テンプレートを更新しました。',
    update_approval_template_rejected: '承認テンプレートの更新に失敗しました。',
    delete_approval_template_fulfilled: '承認テンプレートを削除しました。',
    delete_approval_template_rejected: '承認テンプレートの削除に失敗しました。',
    //Approval template details
    update_approval_template_details_fulfilled:
      '承認テンプレートの詳細を更新しました。',
    update_approval_template_details_rejected:
      '承認テンプレートの詳細の更新に失敗しました。',
    delete_approval_template_details_fulfilled:
      '承認テンプレートの詳細を削除しました。',
    delete_approval_template_details_rejected:
      '承認テンプレートの詳細の削除に失敗しました。',
    update_approval_template_step_fulfilled:
      '承認テンプレートステップの更新に成功しました。',
    update_approval_template_step_rejected:
      '承認テンプレートステップの更新に失敗しました。',
    create_approval_template_step_rejected:
      '承認テンプレートステップの作成に失敗しました。',

    // Timeoff request
    delete_timeoff_request_fulfilled: '休暇申請を削除しました。',
    delete_timeoff_request_rejected: '休暇申請の削除に失敗しました。',
    time_off_request_failed: '休暇申請に失敗しました。',
    //Vacation Balances
    calculate_vacation_balance_fulfilled: '休暇残高の計算に成功しました',
    calculate_vacation_balance_rejected: '休暇残高の計算に失敗しました',
    // daily report
    daily_report_update_fulfilled: 'デイリーレポートが更新されました',
    daily_report_update_rejected: 'デイリーレポートの更新に失敗しました',
    daily_report_create_rejected: 'デイリーレポートの作成に失敗しました',
    //
    time_correction_create_rejected: 'リクエストが失敗しました',
    time_correction_update_rejected: 'リクエストの編集に失敗しました',
    approve_time_correction_rejected: '時間修正の承認に失敗しました',
    deny_time_correction_rejected: '時間修正の拒否に失敗しました',
    time_correction_template_fulfulled:
      '時間補正テンプレートが正常に更新されました',
    time_correction_template_rejected:
      '時間補正テンプレートの更新に失敗しました',
    company_rules_update_fulfulled: '会社の規則を更新しました',
    company_rules_update_rejected: '会社の規則の更新に失敗しました',
    // Veryify workspace
    workspace_verify_fulfulled: 'ワークスペースが正常に変更されました',
    workspace_verify_rejected: 'ワークスペースの変更に失敗しました',
    regenerate_workspace_token_fulfilled:
      'ワークスペース UID が正常に生成されました',
    regenerate_workspace_token_rejected:
      'ワークスペース UID の生成に失敗しました',
    update_workspace_fulfilled: 'ワークスペースが正常に更新されました',
    update_workspace_rejected: 'ワークスペースの更新に失敗しました',
    delete_workspace_fulfilled: 'Successfully deleted workspace',
    update_remote_work_settings_fulfilled:
      'リモートワーク設定の更新に成功しました',
    update_remote_work_settings_rejected:
      'リモートワーク設定の更新に失敗しました',
    delete_remote_work_settings_fulfilled:
      'リモートワーク設定の削除に成功しました',
    delete_remote_work_settings_rejected:
      'リモートワーク設定の削除に失敗しました',
    timeqr_start_fulfilled: 'Successfully work started from office',
    timeqr_end_fulfilled: 'Successfully work ended from office',
    delete_remote_work_request_fulfilled:
      'リモートワークリクエストの削除に成功しました',
    delete_remote_work_request_rejected:
      'リモートワークリクエストの削除に失敗しました',
    update_remote_work_request_fulfilled:
      'リモート作業リクエストが正常に更新されました',
    update_remote_work_request_rejected:
      'リモート作業リクエストの更新に失敗しました',
    create_remote_work_settings_rejected:
      'リモート作業要求を無効にできませんでした',
    update_user_compensatory_days_fulfilled:
      'ユーザー補償日数の更新に成功しました',
    update_user_compensatory_days_rejected:
      'ユーザー補償日数の更新に失敗しました',
    update_tag_rejected: '更新に失敗しました',
    update_tag_fulfilled: '作業タイプが更新されました',
    delete_tag_rejected: '削除に失敗しました',
    delete_tag_fulfilled: '作業タイプが削除されました',
    create_tag_rejected: '作業タイプの作成に失敗しました',
  },
  UPLOAD: {
    title: '画像のアップロード',
    upload: 'アップロード',
    file: 'ファイルアップロード',
  },
  EMAIL: {
    templates: 'テンプレート',
    campaigns: 'キャンペーン',
    email_settings: 'メール設定',
    edit_design: 'デザインを編集',
    title: 'メール',
    send_email: 'メールを送信',
    send_email_title: '開始するテンプレートを選択',
    create_template: 'テンプレートを作成',
    use_template: 'テンプレートを使用',
    template_name: 'テンプレート名',
    subject: 'メールの件名',
    template_type: 'テンプレートの種類',
    bulk: '一括',
    salary: '給与',
    recipient: 'メールの受信者',
    content: 'メールの内容',
    send_warning_title: '送信できません',
    send_warning_msg: 'メールを送信する権限がありません。',
    desktop: 'デスクトップ',
    mobile: 'モバイル',
    unsaved_title: '保存されていない変更があります',
    unsaved_desc: '保存する前に戻りますか？',
    back: '設定に戻る',
    save: 'デザインを保存',
    empty_content: 'コンテンツが空です',
    step_one: 'テンプレートを選択',
    step_settings: '設定を編集',
    step_import_data: '給与データをインポート',
    step_sent: '完了',
    send_salary: '給与を送信する',
    desktop_version: 'デスクトップバージョン',
    mobile_version: 'モバイルバージョン',
    import_data_dialog: {
      row_span_config: '行スパン設定',
      col_span_config: '列スパン設定',
      add_row_span_config: '行スパン設定を追加',
      add_col_span_config: '列スパン設定を追加',
      row: '行',
      column: '列',
      row_span: '行スパン',
      col_span: '列スパン',
      other: 'その他',
      other_than: 'その他の条件',
      data_index: 'データインデックス',
      data_slice: 'データスライス',
      user_code_index: 'ユーザーコードインデックス',
    },
    completed_title: 'このキャンペーンは完了しました',
    completed_desc:
      'もう一度メールを送信する場合は、新しいキャンペーンを作成してください',
    completed_create: '新しいキャンペーンを作成する',
    recipients: '受取人',
    sent: '送信済み',
    failed: '失敗しました',
  },
  EMAIL_CAMPAIGN: {
    campaign_create_title: 'キャンペーン作成',
    display_order: '表示順序',
    status_draft: '下書き',
    status_sent: '送信済み',
    status_failed: '失敗',
    status_all: 'すべて',
    status_pending: '未処理',
    name_column: '名前',
    created_at_column: '作成日時',
    status_column: 'ステータス',
    template_name_column: 'テンプレート名',
    template_type: 'メールの種類',
    delete: 'メールキャンペーンを削除',
    delete_modal_title: 'メールキャンペーンを削除',
    delete_modal_description:
      'このメールキャンペーンを削除してもよろしいですか？',
    save_confirm_title: 'メールキャンペーン名を確認してください',
    save_confirm_desc:
      '続行しますか? 保存する前に、すべての詳細が正確であることを確認してください。',
    create_success_title: '新しいメールキャンペーンが追加されました',
    create_success_desc:
      'ID: {{id}}、名前: {{name}} の新しいメールキャンペーンが追加されました',
    name_placeholder: 'メールキャンペーン名',
    send: 'メールを送る',
    import_data: 'データの取り込み',
    settings: 'キャンペーン設定',
    campaign_name: 'キャンペーン名',
    send_confirm_title: 'メールを送信してもよろしいですか',
    send_confirm_desc:
      '続行しますか？ {{count}}人の受取人に送信します。送信前にすべての詳細が正確であることを確認してください。',
    fail_log_title: 'キャンペーンの送信失敗受信者ログ',
    send_confirm_bulk_desc:
      '続行しますか？{{recipient}}さんに送信しています。送信する前にすべての詳細を確認してください。',
    mark_as_complete: '完了としてマークする',
    complete_title: '完了としてマークを確認する',
    complete_desc:
      'このメールキャンペーンを完了としてマークしますか？このアクションによりキャンペーンが閉じられ、その状態が「完了」に変更されます。一旦完了としてマークされると、このキャンペーンでのさらなる編集や追加の通信はできなくなります。',
  },
  EMAIL_TEMPLATE: {
    thumbnail_column: 'サムネイル',
    name_column: '名前',
    created_at_column: '作成日時',
    updated_at_column: '更新日時',
    created_by_column: '作成者',
    delete: 'メールテンプレートを削除',
    delete_modal_title: 'メールテンプレートを削除',
    delete_modal_description:
      'このメールテンプレートを削除してもよろしいですか？',
    save_confirm_title: 'メールテンプレートの詳細を保存してもよろしいですか？',
    save_confirm_desc:
      '続行しますか？保存する前にすべての詳細が正確であることを確認してください。',
  },
  EMAIL_SETTINGS: {
    name: '名前',
    edit_name_button: '名前の編集',
    template_name: 'テンプレート名',
    template_type: 'テンプレートの種類',
    to: '宛先',
    add_recipient_button: '受信者の追加',
    empty_recipient_text: 'このメールを送信する相手は誰ですか？',
    recipients: '受信者',
    send_to_all: 'すべてに送信',
    type: 'タイプ',
    edit_type_button: 'タイプの編集',
    subject: '件名',
    edit_subject_button: '件名を編集',
    not_value_text: 'このメールの件名は何ですか？',
    subject_placeholder: '件名を入力',
    subject_word_warning: '可能な限り9言葉以内を使用してください',
    subject_char_warning: '60文字を超えないようにしてください',
    content: 'コンテンツ',
    edit_content_button: 'デザインを編集',
    view_content_button: 'デザインを表示',
    content_placeholder: 'メールのコンテンツをデザインしてください。',
    campaign_name: 'キャンペーン名',
    all_employee: '全社員',
    position_select_all:
      '各グループのすべての社員を選択するには、役職を確認できます。',
    thumbnail: 'サムネイル',
    thumbnail_placeholder:
      'テンプレートのサムネイルをアップロードしてください。',
    view_thumbnail_button: 'サムネイルを表示',
    edit_thumbnail_button: 'サムネイルを編集',
    thumbnail_succesfully_changed:
      'テンプレートのサムネイルが正常に変更されました。',
    preview: 'プレビュー',
  },
  EMAIL_DESIGN: {
    content: 'コンテンツ',
    dynamic_content: 'ダイナミックコンテンツ',
    salary_table: '給与表',
    layout: 'レイアウト',
    save_confirm_title: 'データが保存されました',
    save_confirm_desc: '設定ページに戻りますか？',
    confirmation_text: '続行',
    cancellation_text: '編集を続ける',
    mobile_edit_warning: 'エディターはデスクトップでのみ動作します',
  },
  TIMEOFF_TEMPLATE: {
    special: '特別有給休暇',
    menu_title: '休暇テンプレート',
    list_title: '休暇テンプレート',
    name: '名前',
    create_title: '休暇テンプレートの作成',
    approval_template: '承認者テンプレート',
    description: '説明',
    pay_status: '給与状態',
    range_type: '時間の範囲',
    identifier_label: 'テンプレート種類',
    identifier_other: 'デフォルト',
    identifier_mytime: 'マイタイム',
    identifier_holidaywork: '休日に勤務',
    identifier_workation: '有給休暇中に勤務',
    identifier_compensatory_request: '補償休暇',
    is_request_send_later: '後でリクエストの送信を許可する',
    is_long_term_leave: '長期休暇',
    is_deduct_from_vacation: '有給休暇から差し引く',
    is_attachment: '添付ファイルを許可する',
    is_reason_allow: '理由の入力を許可する',
    pay_status_pre: '前払い',
    pay_status_post: '後払い',
    pay_status_non_paid: '払いなし',
    range_type_time: '時間ごと',
    range_type_am: '午前半休',
    range_type_pm: '午後半休',
    range_type_day: '日ごと',
    delete_modal_title: '休暇テンプレートの削除',
    delete_modal_description:
      'この休暇テンプレートを削除してもよろしいですか？',
    create_success_title: '新しい休暇テンプレートが追加されました',
    create_success_desc:
      '名前: {{name}} で新しい休暇テンプレートが追加されました。',
    save_confirm_title: '休暇テンプレートの詳細の保存を確認',
    save_confirm_desc:
      '次へ進みますか？保存する前にすべての詳細が正確であることを確認してください。',
    is_show_time_report: '時間レポートに記録を保存する',
    duration_max: '最大期間',
    duration_min: '最小期間',
  },
  TIMEOFF_REQUEST: {
    current_status: '現在ステータス：',
    duration_hour: '時間',
    duration_min: '分',
    half_day_am_duration: '半日午前',
    half_day_pm_duration: '半日午後',
    total_remaining_comp_balance: '合計残高：',
    total_remaining_comp_balance_desc: '合計補償休暇残高／合計獲得日',
    selected_day_info: '使用しようとしている日：',
    selected_day_info_desc: 'カレンダーで選択した日／一覧で選択した日',
    estimated_remaining_compdays: 'この後残る保証休暇残高：{{days}}日',
    selected_compday_remainder: '一覧で選択した日：{{days}}日',
    not_specified: '指定なし',
    not_enough_balance: '（残高が足りません）',
    not_enough_compday:
      '（残高が足りません。一覧でアイテムを増やすかカレンダーで選択した日を短くしてください。）',
    special: '特別有給休暇',
    any_position: 'すべて',
    remainder: '残高日：',
    compday_list_label:
      '以下は、休日または週末に働いた日の一覧です。 補償を受ける日を選択してください。',
    step: '段階',
    list_title: '休暇申請',
    menu_title: '休暇申請',
    choose_template: '休暇の種類を選択してください',
    create_title: '休暇申請のフォーム',
    status: 'ステータス',
    request_type: '申請タイプ',
    template_type: '休暇一覧',
    is_break_morning: '朝から休暇',
    start_at: '開始',
    end_at: '終了',
    current_step: '現段階',
    approver: '承認者',
    reason: '理由',
    pay_status: '支払い方法：',
    range_type: '期間タイプ：',
    is_deduct_from_vacation: '有給休暇から差し引く：',
    selected_duration: '合計期間：',
    duration: '期間',
    no_template: '休暇種類の一覧が見つかりません。。',
    status_pending: '承認待ち',
    status_rejected: '拒否',
    status_accepted: '承認',
    status_deleted: '削除',
    create_success_title: '新しい申請が追加されました',
    create_success_desc:
      '{{start_at}}から{{end_at}}までの申請が追加されました。',
    delete_modal_title: '本当に削除してもよろしいですか？',
    delete_modal_description: 'この申請を削除してもよろしいですか？',
    save_confirm_title: '申請の詳細を改めて確認してください',
    save_confirm_desc:
      '進行しますか？保存する前に、すべての詳細が正確であることを確認してください。',
    employee: '社員',
    approve_list: '休暇承認一覧',
    approve_list_menu: '休暇承認一覧',
    approve_modal_title: '許可する',
    approve_modal_description: 'この休暇申請を許可しますか?',
    approve_success_title: '許可しました',
    approve_success_description:
      '{{employee}}さんによる休暇申請を許可しました。',
    deny_modal_title: '拒否する',
    deny_modal_description: 'この休暇申請を拒否しますか?',
    deny_success_title: '拒否しました',
    deny_success_description: '{{employee}}さんによる休暇申請を拒否しました。',
    current_total_step: '現段階／合計段階',
    no_step_approver_data: '。。。',
    attachment: '添付ファイル',
    detail_btn: '詳細',
    delete_btn: '削除',
    approve_btn: '承認',
    deny_btn: '拒否',
    created_at: '作成日',
    compensatory_earned_days: '{{day}}日の代休日を獲得しています。',
    compensatory_remaining_days: '{{day}}日の代休日が残っています。',
    filter_title: '一覧をフィルターする',
    eligible_positions: '承認可能な役職：　',
    use_compday_alert:
      '{{day}}日の代休残高があります。先にそれを使用してください。',
    approve: '承認',
    approver_dialog_warning: 'このアクションは元に戻せません。',
    next_approver_placeholder: '次の承認者',
    next_approver_label: 'このリクエストの次の承認者を選択してください',
    detail_step_by: 'by ',
    your_approval: 'あなたの承認を待っています',
    year: '年',
    vacation_days: '休暇日',
    action_by_me: '私によるアクション',
    remainig_days: '残りの日数',
    comp_expire_at: '補填休暇の利用期限: ',
  },
  TIMEOFF_TOTAL_REPORT: {
    title: '月次休暇報告書',
    submit: '検索',
    select_year: '年を選択',
    select_months: '月を選択',
    select_vacation: '休暇タイプを選択',
    month: '月',
    year: '年',
    vacations: '休暇',
  },
  DAILY_REPORT: {
    my_daily_report: '業務日報',
    team_daily_report: 'チーム業務日報',
    all_daily_report: '全チーム業務日報',
    create: 'レポートを追加',
    menu_title: '日報',
    page_title: '日報',
    list_title: '日報一覧',
    no_daily_report: '日報が見つかりません',
    report: '日報の説明',
    report_placeholder: '何をしましたか？',
    project_label: '関連プロジェクト',
    project_placeholder: 'プロジェクトで検索',
    yesterday: '昨日',
    today: '今日',
    list_view: 'リスト表示',
    calendar_view: '日表示',
    project: 'プロジェクト',
    view_label: '時間エントリー表示',
    list_view_btn: 'リスト',
    calendar_view_btn: '日',
    not_within_range: '日報の日付は作業日の範囲内にある必要があります。',
    in_progress: '進行中',
    total_works_label: '総労働時間',
    other: 'その他',
    report_entries: 'レポートエントリー',
    projects: 'プロジェクト',
    not_within_rang: '範囲外',
    delete_modal_title: 'レポートの削除',
    delete_modal_description: '本当に削除しますか？',
    no_time_card: '勤務時間がありません',
    // entry_chart_desc:
    //   '上記の円グラフは、日報エントリーを視覚的に表したもので、時間に基づくエントリーの割合を示しています。',
    entry_chart_desc:
      '上の円グラフは、業務タイプ別の日報エントリーの分布を視覚的に表しており、時間に基づくエントリーの割合を示しています',
    widget_title: '日次アクティビティサマリー',
    end_at: '終了時間',
    start_at: '開始時間',
    report_detail: 'レポートの詳細',
    select_project: 'プロジェクト',
    page_title_team: 'チーム業務日報',
    page_title_hr: '全プロジェクトの日報',
    very_low: '非常に低い',
    low: '低い',
    moderate: '普通',
    high: '高い',
    very_high: '非常に高い',
    very_flexible: '非常に柔軟',
    flexible: '柔軟',
    firm: '確定的',
    very_firm: '非常に確定的',
    complexity: '複雑さ',
    deadline: '締切',
    tags: '業務の種類',
    tag_placeholder: '業務の種別を選んでください',
    help_complexity:
      '複雑さ：タスクの難易度を1から5まで評価します。低い数字は単純なタスクを表し、高い数字はより挑戦的なものを示します。',
    help_deadline:
      '締切：タスクの緊急度を1から5で示します。低い数字は柔軟な締切を意味し、高い数字はより厳格なタイムラインを示します。',
    may_include_break_time: 'この期間には休憩時間が含まれる場合があります。',
    team_widget_title: '週のアクティビティの概要',
    work_load_stress_title: '仕事の負荷とストレス',
    low_stress: '低ストレス',
    moderate_stress: '中ストレス',
    high_stress: '高ストレス',
    all_members: '全メンバー',
    no_report: 'レポートなし',
    stress_desc:
      'この指標は個人の感情的または個人的なストレスレベルを反映するものではありません。代わりに、仕事量、気が散る要因などの客観的データに基づいています。',
    time_spent: '使った時間',
    score: 'スコア:',
    missing_title: 'Missing Daily Reports Notification',
    go_to_date: 'Write report',
    write: 'Write report',
    missing_limit: 'Missing Daily Report Limit',
    editable_period: 'Editable Time Period',
    missing_limit_desc:
      ' If a person fails to submit their daily report for more than {{number}} work days, they will not be able to start work the next day.',
    editable_period_desc:
      'Daily report entries can be edited within {{number}} work days from today.',
    report_warning:
      'Please submit your daily reports on time. Failure to do so will result in you being unable to start work the next day.Thank you.',
    complete_desc: 'All daily report entries are completed. ',
  },
  TIME_RECORDS: {
    work_start: '出勤時間',
    work_end: '退勤時間',
    shift_start: 'シフト開始',
    shift_end: 'シフト終了',
    time_off: '休暇',
    work_time_info: '情報',
    work_done: '作業完了',
    end_confirm_title: '作業を終了してもよろしいですか',
    end_confirm_desc: '進行中の作業時間を確認してください',
    break_start_confirm_title: 'シフト時間を開始してもよろしいですか',
    break_start_confirm_desc: '進行中の作業時間を確認してください',
    break_end_confirm_title: 'シフト時間を終了してもよろしいですか',
    break_end_confirm_desc: '進行中の作業時間を確認してください',
    attendance: '出勤簿',
    work_hours: '勤務時間',
    shift_hours: 'シフト記録',
    pending: '承認待ち',
    change_date_fields: '提出前に時間フィールドを変更してください',
    shift_limit: '制限値になりました',
    permission_undefined: 'Location permission unavailable',
    permission_denied: 'Location permission denied',
    permission_allowed: 'Location permission allowed',
  },
  TIMESHEET: {
    menu_title: '勤務管理表',
    date: '日付',
    start_time: '開始時間',
    end_time: '終了時間',
    break_start: '休憩開始',
    break_end: '休憩終了',
    worked_hours: '労働時間',
    overtime: '残業',
    early_leave: '早退',
    late_arrival: '遅刻',
    absense: '欠勤',
    total_deduct: '合計控除',
    monday: '月',
    tuesday: '火',
    wednesday: '水',
    thursday: '木',
    friday: '金',
    saturday: '土',
    sunday: '日',
    total: '合計',
    dialog_title: '{{date}} 時間修正',
    timeoff: '休暇',
    reason: '理由',
    break_records: '休憩記録',
    add_break_record: '休憩記録を追加',
    work_start_placeholder: '仕事開始',
    work_end_placeholder: '仕事終了',
    break_start_placeholder: '休憩開始',
    break_end_placeholder: '休憩終了',
    save_confirm_title: '時間補正リクエストを保存する確認',
    save_confirm_desc:
      '続行しますか？保存する前にすべての詳細が正確であることを確認してください。',
    create_detail_success_title: '時間補正リクエストが作成されました。',
    create_detail_success_desc: '{{date}} に時間補正リクエストが作成されました',
    weekend: '週末',
    holiday: '休日',
    invalid_record: '登録無し',
    employee: '社員',
    deduction_days: '控除日数：{{days}} 日',
    holiday_work_days: '休日勤務日数：{{days}} 日',
    deduction_hours: '控除時間：{{hours}} 時間',
    holiday_overtime: '休日残業：{{hours}} 時間',
    delete_request: 'タイムレコードを削除',
    edit_request: 'タイムレコードを編集',
    time_correction_disable_placeholder:
      'このリクエストの 1 つ以上のステップはすでに承認されています。詳細については、私の時間修正から参照してください。',
    remote: 'リモート',
    no_location: '場所不明',
    workspace: 'ワークスペースのステータス',
    work_days: '勤務日数: {{days}} 日',
  },
  TIME_REPORT: {
    menu_title: '勤怠管理レポート',
    employee: '社員',
    late_arrival: '遅刻',
    early_leave: '早退',
    absence: '欠勤',
    time_off: '休暇',
    total_deduct: '合計控除',
    worked_hours: '労働時間',
    overtime: '残業',
    overtime_holiday: '休日残業',
    overtime_weekend: '週末の残業',
    actual_overtime: '実際の残業',
    employee_placeholder: '社員を選択',
    filter_placeholder: '残業で絞り込む',
    year_month: '年と月',
    total: '合計',
    deduction_days: '控除日数',
    lunch_count: 'ランチカウント',
    timeoff_report_title: 'タイムオフレポート',
    work_status_report: '作業状況報告書 ',
    remote_work: 'リモートで働いた',
    office_work: '事務',
    insufficient_office: 'オフィスが不足している',
    insufficient_office_explanation:
      'オフィスで働くべきだったが、未達成の日を表します',
    remote_without_approve_explanation:
      'リモート勤務の申請が承認されなかった際に働いた日を表します',
    remote_without_approve: '承認なしでリモート勤務した日',
    timeOff: '休暇',
    days_off_work: '仕事の休みの日',
    report: '報告',
    work_status_summary: '従業員のステータスの概要',
    accepted_remote_work: '承認されたリモート勤務日',
    summary: '従業員の勤務場所の概要',
    total_work_days: '総労働日',
    summary_report: '概要',
    searched_date:
      '{{start}}から{{end}}までの{{week}}週間データを表示しています',
    unrecorded_day: '未記録の日',
    no_data: 'データが見つかりません',
  },
  TIME_CORRECTION: {
    menu_title: '勤務時間承認',
    title: '時間修正承認リスト',
    work_date: '作業日',
    status: 'ステータス',
    reason: '理由',
    created_at: '作成日時',
    pending: '承認待ち',
    rejected: '拒否',
    accepted: '承認',
    deleted: '削除',
    approve_modal_title: 'リクエストの承認',
    approve_modal_description: 'このリクエストを承認してもよろしいですか？',
    approve_success_title: '承認済み',
    approve_success_description: '{{employee}} によるリクエストが承認',
    deny_modal_title: 'リクエストの拒否',
    deny_modal_description: 'このリクエストの拒否してもよろしいですか？',
    deny_success_title: '拒否',
    deny_success_description: '{{employee}} によるリクエストが拒否',
    employee: '社員',
    time_record: '時間記録',
    filter_title: '時間修正リクエストをフィルタリング',
    my_list_title: '勤務時間修正',
    delete_modal_title: 'タイム補正リクエストの削除',
    delete_modal_description: 'このリクエストを削除してもよろしいですか？',
    delete_success_title: '削除しました',
    delete_success_description: '{{date}}にリクエストが正常に削除',
    user_code: 'ユーザーコード',
    current_status: '現在のステータス',
  },
  TIME_CORRECTION_TEMPLATE: {
    title: '勤務時間修正テンプレート',
    name: '名前',
    template_name: 'テンプレート名',
    approval_template: '承認テンプレート',
    save_confirm_title: '時間補正テンプレートの詳細を保存しますか？',
    save_confirm_desc:
      '続行しますか？保存する前にすべての詳細が正確であることを確認してください。',
  },
  REMOTE_WORK_TEMPLATE: {
    title: 'リモート作業テンプレート',
    menu_title: 'テンプレート設定',
    name: '名前',
    authorization_template: '承認テンプレート',
    template_name: 'テンプレート名',
    save_confirm_title: 'リモート作業テンプレートの詳細の保存を確認',
    save_confirm_desc:
      '続行しますか? 保存する前に、すべての詳細が正確であることを確認してください。',
    approval_template: '承認テンプレート',
    limit_exit_template: '追加の承認テンプレート',
  },
  REMOTE_WORK_REQUEST: {
    title: 'リモート作業リクエスト',
    menu_title: 'リクエスト',
    create_success_title: 'リモート作業リクエストが作成されました',
    description: '説明',
    description_by_date: '{{date}}の説明',
    work_date: '作業日',
    create_title: 'リモート作業リクエストの作成',
    add_date: '追加日',
    approve_title: '申請を承認',
    apply_title: 'リモート作業リクエストの承認',
    approve_all: 'すべて承認',
    all_same_description: 'すべての日付が同じ説明',
    update_title: 'リモート作業リクエストを更新',
    limit: '制限',
    delete_modal_title:
      'このリモートワークリクエストを削除してもよろしいですか？',
    delete_modal_description: 'この操作は取り消せません。続行しますか？',
    remote_dates: 'リモート作業のリクエスト日',
    filter_title: 'リモートワークが無効になっているユーザーをフィルター',
    deleted_by: '削除者',
    denied_by: '拒否者',
    possible_my_time: 'My timeを取ることができる合計期間',
    total_time: '合計期間',
    start_work: '仕事を始めてください',
    started_work: 'もう仕事を始めています.',
    reason_required_desc:
      'リモートワークの日が承認されない場合は理由を記入してください。',
  },
  REMOTE_WORK_SETTINGS: {
    title: 'リモートワークの無効化',
    menu_title: '無効化',
    name: '従業員名',
    start_date: '無効化開始日',
    end_date: '無効化終了日',
    reason: '理由',
    create_title: 'リモートワーク申請の無効化',
    info: '指定した日付で以下の日のリモートワーク申請が作成されました。',
    force_create: '強制作成',
    force_update: '強制更新',
    save_confirm_title: 'リモートワーク無効化の保存確認',
    save_confirm_desc:
      '続行しますか？ 保存する前にすべての詳細が正しいことを確認してください。',
    delete_modal_title: 'リモートワークの有効化',
    delete_modal_description: 'リモートワーク申請を有効にしますか？',
    create_success_title: 'リモートワークリクエスト無効化',
    create_success_desc:
      '{{start}} から {{end}} のリモートワークリクエストが無効化されました。',
    disabled_by: '無効化した人',
    disabled_date: '無効化した日付',
    disabled_log_title: 'リモートワーク無効化ログ',
    filter_title: 'リモートワーク無効日フィルター',
    disabled_log_menu: '無効化ログ',
  },
  NOTIFICATIONS: {
    mark_all_as_read: 'すべてを既読にする',
    mark_as_read: '既読にする',
  },
  MESSAGES: {
    NT000001: '{{name}}プロジェクトに追加されました',
    NT000002: '{{name}}プロジェクトから削除されました',
    NT000003: 'あなたの役割が{{name}}プロジェクトに最近変更されました',
    NT000004: 'が最近{{name}}をリクエストしました',
    NT000005: 'が最近{{name}}のリクエストを承認しました',
    NT000006: 'が最近{{name}}のリクエストの拒否しました',
    NT000007: 'が{{date}}にタイム補正をリクエストしました',
    NT000008: 'が{{date}}にあなたのタイム補正リクエストを承認しました',
    NT000009: 'が{{date}}にあなたのタイム補正リクエストの拒否しました',
    NT000010: 'Remote work request received',
    NT000011: 'Remote work request accepted',
    NT000012: 'Remote work request rejected',
    NT000013: 'Remote work request permission disabled. Between {{date}}',
  },
  QUIT_USERS: {
    title: '退職者',
    employee_name: '名前',
    quit_date: '退職日',
    reason: '退職理由',
    start_date: '開始日',
    end_date: '終了日',
    filter_title: '日付でフィルターする退職者',
    save_confirm_title: 'アップデートを確認する 終了の理由',
    save_confirm_desc:
      '進行しますか？保存する前にすべての詳細が正確であることを確認してください。',
  },
  INFO_LOG: {
    title: '社員情報の変更',
    menu_title: '社員情報の変更',
    count: '{{number}}の変更',
    firstname: '名',
    lastname: '姓',
    firstname_en: '名（英語）',
    lastname_en: '姓（英語）',
    birthday: '誕生日',
    gender: '性別',
    home_address: '自宅住所',
    phone: '電話番号',
    email: 'email',
    phone_secondary: '補助電話番号',
    user_code: 'ユーザーコード',
    role_id: '役割',
    position_id: '役職',
    division_id: '部署',
    hired_date: '採用日',
    ndsh_months: 'NDSH月数',
    ndsh_total: 'NDSH合計',
    status: 'ステータス',
    empty: '空白',
    deleted: '削除済み',
    created: '作成済み',
    something_changed: '{{what}} が変更されました。',
    employee_label: '社員',
    performed_by: '実行者',
    performed_on: '日付',
  },
  COMPANY_RULE: {
    title: '会社の規則',
    back_confirm_title: '何かを編集中のようです。',
    back_confirm_desc: '保存する前にこのページを離れると、変更が失われます。',
    updated_by: '最終更新日 {{date}}、更新者 {{employee}}',
    save_confirm_title: '会社の規則を保存することを確認',
    save_confirm_desc:
      '進行しますか？保存する前にすべての詳細が正確であることを確認してください。',
  },
  WORKSPACE: {
    title: 'ワークスペース',
    name: '名前',
    description: '説明',
    location: '場所',
    ips: 'IPs',
    status: '状態',
    create_title: 'ワークスペースを作成',
    create_success_title: '新しいワークスペースが追加されました',
    create_success_desc:
      'ID: {{id}} および名前: {{name}} を持つ新しいワークスペースが追加されました',
    save_confirm_title: 'ワークスペースの保存を確認',
    save_confirm_desc:
      '続行しますか？ 保存する前にすべての詳細が正確であることを確認してください。',
    delete_modal_title: 'ワークスペースを削除',
    delete_modal_description: 'このワークスペースを削除してもよろしいですか？',
    change_status_modal_title: '{{action}} ワークスペース',
    change_status_modal_description:
      'このワークスペースを{{action}}してもよろしいですか？',
    activate: '有効にする',
    deactivate: '無効にする',
    deactivated_date: '無効化日時',
    active: '有効',
    in_active: '無効',
    map_breadcrumb: 'ワークスペースの場所',
    map_title: '{{workspace}}の場所',
    inactive_at: '非アクティブ化日',
    ips_placeholder: '1.1.1.1、2.2.2.2など',
    work_start_location: '作業開始場所',
    work_end_location: '作業終了場所',
    remote_placeholder: 'あなたをリモート勤務者として登録しました',
    office_placeholder: 'あなたを {{office}} で勤務するものとして登録しました',
    verification_code: '検証コード',
    change_location: '場所を変更',
    uid: 'UID',
    no_location_placeholder:
      '位置情報へのアクセスを許可しなかったため、あなたの位置を特定できません。',
    no_camera: 'デバイスが検出されないか、許可が付与されていません',
    invalid_qr: 'スキャンされた QR コードは無効です',
    qr_title: 'QR Scanner',
  },
  COMPENSATORY_DAYS: {
    title: '補償日数',
    expire_date: '有効期限',
    earned_days: '獲得日数',
    remaining_days: '残り日数',
    comment: 'コメント',
    created_date: '作成日',
  },
  DEMO: {
    notification: 'アプリのデモ版をご利用中です',
    demo_users:
      'すぐにログインするには、以下のデモユーザーの中から1人を選択してください：',
    demo_desc:
      'すぐにログインするには、以下のデモユーザーの中から1人を選択してください：',
  },
  ERROR: {
    E000001: '{{field}}は必須です',
    E000002: 'フィールドは必須です',
    E000003: 'パスワードが一致しません',
    E000004: '{{field}}は未来である必要があります',
    E000005: '終了日は開始日よりも後である必要があります',
    E000006: '無効な日付です',
    E000007: '開始日時を小さくしてください',
    E000008: '30分以上の時間帯を選択してください',
    E000009: '補償リクエストに変更',
    E000010: '両方の日付は同じ日でなければなりません',
    E000046: '{{field}}は{{number}}文字以内である必要があります',
    E000047: '{{field}}は少なくとも{{number}}文字である必要があります',
    E000048: '{{field}}は有効である必要があります',
    E000049: '{{field}}には無効な文字が含まれています',
    E000050: '{{field}}は{{number}}文字である必要があります',
    E000051: '{{field}}には数字が含まれている必要があります',
    E000052: '{{field}}には小文字の文字が含まれている必要があります',
    E000053: '{{field}}には大文字の文字が含まれている必要があります',
    E000054:
      '{{field}}にはこれらのいずれかの文字が少なくとも1つ含まれている必要があります：!@#$%^&*()-+',
    E000055: 'フィールドの値が空です',
    E000056: '有効な電話番号を入力してください',
    E000057: '{{field}}には文字が含まれている必要があります',
    E000058: '古いパスワードとは異なるパスワードを入力してください',
    E000060: '開始日は必須です',
    E000061: '開始日と終了日は同じ月である必要があります',
    E000062: 'アイテムの削除に失敗しました',
    E000063: '数字を入力してください',
    E000064: '工数開始日は有効期限日よりも前である必要があります',
    E000065: '前の月以前のに日が選べせん',
    E000066: 'アップロードに失敗しました',
    E000067: '終了時間は開始時間より後でなければなりません。',
    E000068: '開始時間は未来でなければなりません。',
    E000069: '{{field}}は午前7時以降でなければなりません。',
    E000070: '少なくとも1つのメール受信者を選択する必要があります',
    E000071: '年は現在の年または翌年である必要があります',
    E000072: '開始日と終了日は選択した年と同じ年である必要があります',
    E000073: '終了時間は 15 分後でなければなりません。',
    E000074: '休憩は勤務時間内でなければなりません。',
    BE000001: '空欄にできません',
    BE000002: 'フィールドは必須です',
    BE000003: 'フィールドは必須です',
    BE000021: '存在しません',
    BE000022: 'フィールドの値は既に使用されています',
    BE000046: 'フィールドの値が長すぎます',
    BE000047: 'フィールドの値が短すぎます',
    BE000048: '日付の検証に失敗しました',
    BE000049: '日付の検証に失敗しました',
    BE000050: '値が範囲外です',
    BE000052: '年は数値である必要があります',
    BE000059: '有効なタイプではありません',
    BE000060: '開始時間は未来でなければなりません。',
    BE000061: '選択された日付は同じ日でなければなりません。',
    BE000062: 'この日時に重なりがあります。',
    BE000063: 'アクションを実行できません。作業がまだ開始されていません。',
    BE000065: '所有者のみが自分のレコードを削除できます',
    BE000066: '作業はすでに開始されています',
    BE000067: '提出する前に休憩時間を終了してください',
    BE000068: '最初に休憩を開始してください',
    BE000073: 'シフト時間制限に達しました',
    BE000078: '代休日数が足りません',
    BE000079: '私の時間制限が超過しています',
    BE000080: '休暇残高が存在しません',
    BE000081: '休暇残高が足りません',
    BE000082: '無効な IPs',
    BE000054: '選択されたオプションが無効です',
    BE000055: '文字列でなければなりません',
    BE000056: '整数でなければなりません',
    BE000057: '有効な日付でなければなりません',
    BE000058: '有効なJSONでなければなりません',
    BE000064: '指定された値以上でなければなりません',
    BE000069: 'どちらか一方のみが必要です',
    BE000070: 'このプロジェクトはあなたのものではありません',
    BE000072: '少なくとも1つのレコードが存在する必要があります',
    BE000074: '指定された値と等しくなければなりません',
    BE000075: '代休日がすでに存在します',
    BE000076: '次の間隔でなければなりません',
    BE000077: '計算を実行できません',
    BE000083: '有効な時間でなければなりません',
    BE000051:
      'このアイテムは他のデータに関連付けられているため削除できません。',
    AE000001:
      'このアイテムは他のデータに関連付けられているため削除できません。',
    AE000002: '{{date}}に既に確認済みのプランがあるため、確認できません。',
    AE000003:
      '所有権を削除または更新することができません。プロジェクトは常に少なくとも1人のオーナーを持っている必要があります。',
    AE000004: '削除しようとしているリソース プランは確認済みで削除できません。',
    AE000005: 'リソースプランの対象月は間違っています。',
    AE000006: 'リソースプランは確認される必要があります。',
    AE000007: '開始日と終了日の間に、その役職で工数が発生しています。',
    AE000008: 'その部屋で会議が予約されています',
    AE000009: '休暇残高が足りませんでした。',
    AE000010: '補償日残高はありません。',
    AE000011: 'MyTime 残高がありません。',
    AE000012: '承認者が見つかりませんでした。',
    AE000014:
      '"ワーケーションの申請は、休暇期間外であるか、または重複した申請であるため、失敗しました。',
    AE0000016: 'A社員の休暇残高はすでに作成されています。',
    AE000020: 'このテンプレートを使用する保留中のリクエストがあります。',
    AE000021: '承認テンプレートの詳細の更新に失敗しました',
    AE000029: '過去の日付を選択できません。',
    AE000028: '長期休暇中に他の休暇申請は受け付けません。',
    AE100033:
      '入力されたパスワードは古いパスワードと同じです。異なるパスワードを入力してください。',
    AE000030: '時刻修正リクエストの送信期限が過ぎました',
    AE000031: '確認コードが間違っています。もう一度お試しください',
    AE000032: '過去の注文は削除できません。',
    AE000033: 'リクエストを拒否する理由が必要です',
    AE000042: 'パラメータが不足しています',
    AE000043: 'ワークスペースが見つかりません',
    AE000039: 'リモートワークの日付が重複しています',
    AE000034: 'リモート作業要求が存在します',
    AE000035: 'リモートワーク要求はすでに受け入れられています',
    AE000036: 'リモート ワーク テンプレートが見つかりません',
    AE000038: 'リモート作業リクエストは削除できません',
    AE000040: 'リモートワークは無効です',
    AE000041: 'このリモート作業テンプレートを使用する保留中の要求があります。',
    AE000044: 'リモートワークは日付が重複しています',
    AE000045: 'リモート作業のリクエストには少なくとも 1 日の期間が必要です',
    AE000046: '日報編集の制限に達しました',
    AE000047: 'この報告書は編集可能な期間外のため、変更できません。',
    AE000048: 'The duration is not within the minimum and maximum range.',
    AE000049: 'Mytime リクエスト時間が超過しました。',
    AE000050: '終了日は開始日よりも後である必要があります',
    AE000051: '同期エラー',
    AE000052: '無効な形式',
    AE000053: 'このステップはすでに追加されています',
    AE000054: '補償休暇の使用期間が終了しました',
    AE000071: '有効な時間範囲外です',
    ABE000004: 'フィールドは必須です',
    AE100034: 'パスワードが一致しません',
    ABE100014: '有効なJSONでなければなりません',
    AE000056: 'この作業タイプを使用している日報があります。',
    AE000057: '開始日は必須です',
    AE000024: '時刻修正テンプレートが見つかりません.',
  },
}
