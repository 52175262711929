import HelpContent from '@components/HelpContent'
import { HelpPage, lang } from '@constants/help.constants'
import { MODULES } from '@constants/modules.constants'
import { ROUTE } from '@constants/route.constants'
import useEmployeeReport from '@containers/@modules/Time/useEmployeeReport'
import PermissionWrapper from '@containers/PermissionWrapper'
import DetailPageLayout from '@layouts/ContentLayout/DetailPageLayout'
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography,
} from '@mui/material'
import _ from 'lodash'

import i18next from 'i18next'
import React, { useContext, useEffect, useState } from 'react'
import { use100vh } from 'react-div-100vh'
import { useTranslation } from 'react-i18next'
import RemoteOfficeDataGrid from '@components/@data-grid/RemoteOfficeDataGrid'
import RemoteOfficeFilter from '@pages/time_report/remote_office_report/RemoteOfficeFilter'
import dayjs from 'dayjs'
import {
  remoteOfficeReportDownloadService,
  remoteReportParams,
} from '@services/time.services'
import { RemoteReportDefaultFilter } from '@constants/time.constants'
import { LayoutContext } from '@layouts/MainLayout/LayoutProvider'
import RemoteWidget from '@components/@modules/Time/RemoteWidget'
import useEmployee from '@containers/@modules/User/useEmployee'
import { InfoOutlined } from '@mui/icons-material'
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  LegendProps,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from 'recharts'
import ReportDownloader from '@components/ReportDownloader'
import { generateFileName } from '@utils/helper/common.helper'
import saveAs from 'file-saver'
import DataLoading from '@components/DataLoading'
const CustomTooltip = ({
  active,
  payload,
}: {
  active?: boolean
  payload?: any[]
}) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: 'white',
          border: '1px solid #ccc',
          padding: '5px',
          borderRadius: '4px',
        }}
      >
        {payload.map((data, index) => (
          <p key={index} style={{ color: 'black', fontSize: '10px' }}>
            <span
              style={{
                marginRight: 10,
                backgroundColor: data.color,
                display: 'inline-block',
                width: 10,
                height: 10,
              }}
            ></span>
            {`${data.name}: ${data.value}`}
          </p>
        ))}
      </div>
    )
  }
  return null
}

// Custom Legend Component
const CustomLegend: React.FC<LegendProps> = ({ payload }) => {
  return (
    <ul
      style={{
        listStyleType: 'none',
        marginBottom: '10px',
        padding: 0,
        textAlign: 'left',
      }}
    >
      {payload?.map((entry, index) => (
        <li key={`item-${index}`} style={{ color: 'black', fontSize: '12px' }}>
          <span
            style={{
              marginRight: 10,
              backgroundColor: entry.color,
              display: 'inline-block',
              width: 10,
              height: 10,
            }}
          ></span>
          {entry.value}
        </li>
      ))}
    </ul>
  )
}

const RemoteOfficeReportPage: React.FC = () => {
  const {
    fetchRemoteOfficeReportList,
    remoteReportList,
    remoteOfficeSomeData,
    meta,
  } = useEmployeeReport()
  const { t } = useTranslation()

  const breadcrumbs = [
    { to: ROUTE.ROOT, title: t('SYSCOMMON.dashboard') },
    { title: t('TIME_REPORT.work_status_report') },
  ]

  const height = use100vh()
  const maxHeight = height ? height : 350
  const { employeeActivePlusQuit, activePlusQuitMeta } = useEmployee()
  const [filter, setFilter] = useState<remoteReportParams>({
    ...RemoteReportDefaultFilter,
    start_date: RemoteReportDefaultFilter.start_date,
    end_date: RemoteReportDefaultFilter.end_date,
    user_id: RemoteReportDefaultFilter.user_id,
  })
  const { isSidebarOpen } = useContext(LayoutContext)
  const { fetchActivePlusQuitList, fetchAll } = useEmployee()
  const SimpleBarChart: React.FC<{ data: any }> = () => {
    const not_approve = _.sumBy(
      remoteReportList,
      'attributes.remote_without_approve'
    )
    const insufficient_office = _.sumBy(
      remoteReportList,
      'attributes.insufficient_office_count'
    )
    const accepted_remote = _.sumBy(
      remoteReportList,
      'attributes.accepted_remote_count'
    )
    const chartData = [
      {
        name: `${t('TIME_REPORT.summary_report')}`,
        not_approve: not_approve,
        insufficient_office: insufficient_office,
        accepted_remote: accepted_remote,
      },
    ]

    return (
      <ResponsiveContainer width="100%" height={280}>
        <BarChart
          data={chartData}
          layout="horizontal"
          margin={{ top: 0, right: 20, left: 20, bottom: 1 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <Tooltip content={CustomTooltip} />
          <Bar
            dataKey="not_approve"
            fill="#8884d8"
            name={t('TIME_REPORT.remote_without_approve')}
          >
            <LabelList dataKey="not_approve" position="top" />
          </Bar>
          <Bar
            dataKey="insufficient_office"
            fill="#82ca9d"
            name={t('TIME_REPORT.insufficient_office')}
          >
            <LabelList dataKey="insufficient_office" position="top" />
          </Bar>
          <Bar
            dataKey="accepted_remote"
            fill="#ffc658"
            name={t('TIME_REPORT.accepted_remote_work')}
          >
            <LabelList dataKey="accepted_remote" position="top" />
          </Bar>
          <Legend content={CustomLegend as any} verticalAlign="top" />
        </BarChart>
      </ResponsiveContainer>
    )
  }
  useEffect(() => {
    fetchRemoteOfficeReportList({
      ...filter,
      start_date: dayjs(filter.start_date).startOf('day').toDate(),
      end_date: dayjs(filter.end_date).endOf('day').toDate(),
      user_id: filter.user_id,
    })
      .unwrap()
      .catch(() => {
        console.error('Error fetching report:')
      })
  }, [filter])
  useEffect(() => {
    fetchActivePlusQuitList()
    fetchAll()
  }, [])
  const fetchCSV = async () => {
    try {
      const blob = await remoteOfficeReportDownloadService(filter)
      const fileName = generateFileName('remote-office-report', 'xlsx')
      saveAs(blob, fileName)
    } catch (error) {
      console.error('ダウンロードに失敗しました。:', error)
    }
  }
  const totalDays =
    dayjs(remoteOfficeSomeData?.end_date).diff(
      dayjs(remoteOfficeSomeData?.start_date),
      'day'
    ) + 1
  return (
    <PermissionWrapper
      module={MODULES.RESOURCE}
      readBehavior="disabled"
      render={() => (
        <DetailPageLayout
          breadcrumbs={breadcrumbs}
          title={t('TIME_REPORT.work_status_report')}
          helpTitle={t('TIME_REPORT.work_status_report')}
          actions={
            <>
              <ReportDownloader fetchCSV={fetchCSV} />
            </>
          }
          helpContent={
            <HelpContent
              id={HelpPage.remote_office_report[`${i18next.language as lang}`]}
            />
          }
        >
          <Grid container spacing={2} sx={{ height: '100%' }}>
            {/* Filter Section */}
            <Grid item xs={12} md={isSidebarOpen ? 12 : 8} lg={7} xl={9}>
              <Paper sx={{ px: 2, mb: 2, pt: 1, pb: 0.2 }}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      backgroundColor: '#ffffff',
                      borderRadius: 1,
                      width: '100%',
                    }}
                  >
                    <RemoteOfficeFilter
                      allEmployeeMeta={activePlusQuitMeta}
                      employeeAll={employeeActivePlusQuit}
                      onFilter={setFilter}
                    />
                  </Box>
                </Grid>
              </Paper>

              {remoteReportList && (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    pb: 0.5,
                  }}
                >
                  <InfoOutlined sx={{ fontSize: 15, mt: 0.3, mx: 0.6 }} />
                  <Typography>
                    {t('TIME_REPORT.searched_date', {
                      start: dayjs(remoteOfficeSomeData?.start_date).format(
                        'YYYY-MM-DD'
                      ),
                      end: dayjs(remoteOfficeSomeData?.end_date).format(
                        'YYYY-MM-DD'
                      ),
                      week: totalDays / 7,
                    })}
                  </Typography>
                </Box>
              )}
              {/* DataGrid and Loading Section */}
              <Paper
                id="time-grid"
                className="scrollbar-container"
                style={{
                  width: '100%',
                  maxHeight: maxHeight,
                  overflow: 'auto',
                  minHeight: '120px',
                }}
              >
                {meta.pending && (
                  <Box
                    sx={{
                      position: 'absolute',
                      background: 'rgba(255,255,255,0.6)',
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'top',
                      zIndex: 100,
                      paddingTop: '50px',
                    }}
                  >
                    <CircularProgress size={20} />
                  </Box>
                )}
                <React.Suspense
                  fallback={
                    <Box
                      sx={{
                        position: 'absolute',
                        display: 'flex',
                        left: 0,
                        right: 0,
                        top: 20,
                        bottom: 0,
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <CircularProgress size={20} />
                    </Box>
                  }
                >
                  {/* DataGrid if report is fetched */}
                  {remoteReportList && !_.isEmpty(remoteReportList) && (
                    <RemoteOfficeDataGrid
                      remoteReportList={remoteReportList}
                      totalDays={remoteOfficeSomeData.total_work_days}
                    />
                  )}
                  {!meta.pending && _.isEmpty(remoteReportList) && (
                    <Paper
                      sx={{
                        height: '120px',
                        justifyContent: 'center',
                        alignContent: 'center',
                      }}
                    >
                      <DataLoading
                        sx={{
                          textAlign: 'center',
                        }}
                        isLoading={false}
                        isEmptyData={
                          remoteReportList && _.isEmpty(remoteReportList)
                        }
                        emptyDataSx={{ py: 2 }}
                      />
                    </Paper>
                  )}
                </React.Suspense>
              </Paper>
            </Grid>
            <Grid item xs={12} md={isSidebarOpen ? 12 : 4} lg={4} xl={3}>
              <Paper sx={{ minHeight: 100, position: 'relative' }}>
                {meta.pending && (
                  <Box
                    sx={{
                      position: 'absolute',
                      background: 'rgba(255,255,255,0.6)',
                      left: 0,
                      right: 0,
                      top: 16,
                      bottom: 0,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      zIndex: 100,
                    }}
                  >
                    <CircularProgress size={20} />
                  </Box>
                )}
                {remoteReportList && (
                  <RemoteWidget
                    remoteReportList={remoteReportList}
                    totalDays={remoteOfficeSomeData.total_work_days}
                  />
                )}
              </Paper>
              <Paper
                sx={{
                  minHeight: 100,
                  p: '5px',
                  mt: '7px',
                  position: 'relative',
                }}
              >
                <Box sx={{ minHeight: 50, alignContent: 'center', pl: '4px' }}>
                  {t('TIME_REPORT.summary')}
                </Box>
                <Divider />
                {meta.pending && (
                  <Box
                    sx={{
                      position: 'absolute',
                      background: 'rgba(255,255,255,0.6)',
                      left: 0,
                      right: 0,
                      top: 16,
                      bottom: 0,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      zIndex: 100,
                    }}
                  >
                    <CircularProgress size={20} />
                  </Box>
                )}
                {remoteReportList && <SimpleBarChart data={remoteReportList} />}
                <Box sx={{ display: 'flex' }}>
                  <InfoOutlined
                    sx={{ fontSize: 11, position: 'relative', m: '5px' }}
                  />
                  <Typography sx={{ fontSize: 10, mt: '7px', m: '5px' }}>
                    <strong>{t('TIME_REPORT.remote_without_approve')} </strong>{' '}
                    - {t('TIME_REPORT.remote_without_approve_explanation')}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <InfoOutlined
                    sx={{ fontSize: 11, position: 'relative', m: '5px' }}
                  />
                  <Typography
                    sx={{
                      fontSize: 10,
                      m: '5px',
                    }}
                  >
                    <strong>{t('TIME_REPORT.insufficient_office')}</strong> -{' '}
                    {t('TIME_REPORT.insufficient_office_explanation')}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </DetailPageLayout>
      )}
    />
  )
}

export default RemoteOfficeReportPage
