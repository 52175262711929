import HelpContent from '@components/HelpContent'
import { HelpPage, lang } from '@constants/help.constants'
import { MODULES } from '@constants/modules.constants'
import useEmployeeReport from '@containers/@modules/Time/useEmployeeReport'
import PermissionWrapper from '@containers/PermissionWrapper'
import DetailPageLayout from '@layouts/ContentLayout/DetailPageLayout'
import { Box, CircularProgress, Paper } from '@mui/material'
import i18next from 'i18next'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TimeoffTotalReportFilter from './TimeoffTotalReportFilter'
import TimeOffReportGrid from '@components/@data-grid/TimeOffReportGrid'
import {
  timeOffReportTotalDownloadService,
  TimeOffReportTotalParam,
} from '@services/timeoff.services'
import { ROUTE } from '@constants/route.constants'
import { generateFileName } from '@utils/helper/common.helper'
import saveAs from 'file-saver'
import ReportDownloader from '@components/ReportDownloader'
import _ from 'lodash'
import React from 'react'

export type monthType = {
  id: number
  month: string
}

export const months: monthType[] = [
  { id: 1, month: 'Jan' },
  { id: 2, month: 'Feb' },
  { id: 3, month: 'Mar' },
  { id: 4, month: 'Apr' },
  { id: 5, month: 'May' },
  { id: 6, month: 'Jun' },
  { id: 7, month: 'Jul' },
  { id: 8, month: 'Aug' },
  { id: 9, month: 'Sep' },
  { id: 10, month: 'Oct' },
  { id: 11, month: 'Nov' },
  { id: 12, month: 'Dec' },
]

const TimeOffTotalReportPage: React.FC = () => {
  const { t } = useTranslation()
  const { fetchTimeOffReportTotal, timeOffReportTotalList, metaTimeOff } =
    useEmployeeReport()
  const currentyear = new Date().getFullYear()
  const [filter, setFilter] = useState<TimeOffReportTotalParam>({
    months: [`${currentyear}-01-01`],
    timeoff_templates: [],
  })
  const [selectedTimeOff, setSelectedTimeOff] = useState<number[]>([])
  const breadcrumbs = [
    { to: ROUTE.ROOT, title: t('SYSCOMMON.dashboard') },
    { title: t('TIMEOFF_TOTAL_REPORT.title') },
  ]
  const fetchCSV = async () => {
    try {
      const blob = await timeOffReportTotalDownloadService(filter)
      const fileName = generateFileName('timeoff-total-report', 'xlsx')
      saveAs(blob, fileName)
    } catch (error) {
      console.error('ダウンロードに失敗しました。:', error)
    }
  }
  useEffect(() => {
    if (filter) {
      setSelectedTimeOff(filter.timeoff_templates)
      fetchTimeOffReportTotal({
        timeoff_templates: [],
        months: filter.months,
      })
    }
  }, [filter])
  return (
    <PermissionWrapper
      module={MODULES.RESOURCE}
      readBehavior="disabled"
      render={() => (
        <DetailPageLayout
          title={t('TIMEOFF_TOTAL_REPORT.title')}
          breadcrumbs={breadcrumbs}
          helpTitle={t('TIMEOFF_TOTAL_REPORT.title')}
          actions={
            <>
              <ReportDownloader fetchCSV={fetchCSV} />
            </>
          }
          helpContent={
            <HelpContent
              id={
                HelpPage.monthly_timeoff_report[`${i18next.language as lang}`]
              }
            />
          }
        >
          <Paper sx={{ px: 1, mb: 2, pt: 1, width: '100%' }}>
            <Box
              sx={{
                pb: 1,
                backgroundColor: '#ffffff',
                borderRadius: 1,
                width: '100%',
              }}
            >
              <TimeoffTotalReportFilter
                months={months}
                timeOffReportTotalList={timeOffReportTotalList}
                onFilter={(values) => {
                  setFilter({
                    ...filter,
                    ...values,
                  })
                }}
              />
            </Box>
          </Paper>
          <Paper
            id="time-grid"
            className="scrollbar-container"
            style={{
              width: '100%',
              overflow: 'auto',
              position: 'relative',
              minHeight: 70,
            }}
          >
            <React.Suspense
              fallback={
                <Box
                  sx={{
                    position: 'absolute',
                    display: 'flex',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <CircularProgress size={20} />
                </Box>
              }
            >
              {metaTimeOff.pending && (
                <Box
                  sx={{
                    position: 'absolute',
                    background: 'rgba(255,255,255,0.6)',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'top',
                    paddingTop: '30px',
                    zIndex: 100,
                  }}
                >
                  <CircularProgress size={20} />
                </Box>
              )}
              {timeOffReportTotalList && !_.isEmpty(timeOffReportTotalList) && (
                <TimeOffReportGrid
                  timeOffReportTotalList={timeOffReportTotalList}
                  selectedTimeOff={selectedTimeOff}
                  metaTimeOff={metaTimeOff}
                />
              )}
            </React.Suspense>
          </Paper>
        </DetailPageLayout>
      )}
    />
  )
}

export default TimeOffTotalReportPage
