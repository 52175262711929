import { Box, Stack, Typography } from '@mui/material'
import { RemoteOfficeAttributes } from '@services/time.services'
import React from 'react'
import {
  Cell,
  Label,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'
import _ from 'lodash'
import { truncateDecimals } from '@utils/helper/common.helper'
import { useTranslation } from 'react-i18next'
import { generateColor } from '@marko19907/string-to-color'
import { BgColorOptions } from '@constants/common.constants'
import PlaceholderPieChart from '@components/EmptyPieChar'

interface StatWidgetProps {
  remoteReportList: RemoteOfficeAttributes[]
  totalDays: number
}

const RemoteWidget: React.FC<StatWidgetProps> = ({
  remoteReportList,
  totalDays,
}) => {
  const { t } = useTranslation()

  const remotePercentage = _.sumBy(remoteReportList, 'attributes.remote_count')
  const officePercentage = _.sumBy(remoteReportList, 'attributes.office_count')
  const timeoffPercentage = _.sumBy(remoteReportList, 'attributes.timeoff_days')
  const missPercentage = _.sumBy(
    remoteReportList,
    'attributes.unauthorized_absence'
  )
  const unrecordedday =
    totalDays * remoteReportList.length -
    (missPercentage + timeoffPercentage + officePercentage + remotePercentage)
  const projectsChartData = [
    {
      name: `${t('TIME_REPORT.remote_work')}`,
      count: remotePercentage,
      bgColor: generateColor('remote_count', BgColorOptions),
    },
    {
      name: `${t('TIME_REPORT.days_off_work')}`,
      count: missPercentage,
      bgColor: 'rgb(224, 82, 103)',
    },
    {
      name: `${t('TIME_REPORT.office_work')}`,
      count: officePercentage,
      bgColor: generateColor('office_count', BgColorOptions),
    },
    {
      name: `${t('TIME_REPORT.timeOff')}`,
      count: timeoffPercentage,
      bgColor: generateColor('timeoff_count', BgColorOptions),
    },
    {
      name: `${t('TIME_REPORT.unrecorded_day')}`,
      count: unrecordedday,
      bgColor: '#D3D3D3',
    },
  ]

  const filteredProjectsChartData = projectsChartData.filter(
    (data) => data.count > 0
  )

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const { name, count } = payload[0].payload
      const total = _.sumBy(filteredProjectsChartData, 'count')
      const displayPercent =
        (count / total) * 100 > 0.5
          ? `${truncateDecimals((count / total) * 100, 0)}%`
          : `${truncateDecimals((count / total) * 100, 2)}%`
      return (
        <Box
          className="custom-tooltip"
          sx={{
            background: '#fff',
            borderRadius: '6px',
            boxShadow: 1,
            py: 0.5,
            px: 1,
          }}
        >
          <Typography
            className="intro"
            noWrap
            sx={{ fontWeight: 500, fontSize: 11 }}
          >
            {t('TIME_REPORT.report')}
          </Typography>
          <Typography className="label" sx={{ mb: 0.5 }}>
            <Box
              component={'span'}
              sx={{
                background: payload[0].payload.bgColor,
                width: 10,
                height: 10,
                display: 'inline-block',
                mr: 1,
              }}
            ></Box>
            {`${name}: ${displayPercent}`}
          </Typography>
        </Box>
      )
    }

    return null
  }

  const emptyChartData = [{ name: 'No data', count: 100, bgColor: '#efefef' }]
  return (
    <>
      <Box
        sx={{
          p: 1,
          pl: 2,
          borderBottom: '1px solid #eee',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h5" sx={{ pt: 1 }}>
          {t('TIME_REPORT.work_status_summary')}
        </Typography>
      </Box>
      <Box className="stat-widget" sx={{ p: 2 }}>
        {filteredProjectsChartData ? (
          <Box sx={{ maxWidth: '240px', margin: '0 auto', mb: 2 }}>
            <ResponsiveContainer width="100%" height={220}>
              <PieChart>
                <Pie
                  data={filteredProjectsChartData ?? []}
                  dataKey="count"
                  nameKey="name"
                  cx="50%"
                  stroke="transparent"
                  cy="50%"
                  innerRadius={57}
                  outerRadius={86}
                  fill="#8884d8"
                  labelLine={false}
                  label={({ x, y, percent }) => {
                    const displayPercent =
                      percent * 100 > 0.5
                        ? `${truncateDecimals(percent * 100, 0)}%`
                        : `${truncateDecimals(percent * 100, 2)}%`
                    return (
                      <text
                        x={x}
                        y={y}
                        textAnchor={'middle'}
                        dominantBaseline="central"
                        fontSize={11}
                        fill="#000000"
                      >
                        {displayPercent}
                      </text>
                    )
                  }}
                >
                  {filteredProjectsChartData &&
                    filteredProjectsChartData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        style={{
                          outline: 'none',
                          filter: `drop-shadow(rgb(0, 0, 0, .1) 0px 0px 6px)`,
                        }}
                        fill={entry.bgColor}
                      />
                    ))}
                  <Label
                    value={t('TIME_REPORT.total_work_days')}
                    position="center"
                    style={{
                      fontSize: '12px',
                      fill: '#111',
                    }}
                  />
                  <Label
                    value={totalDays}
                    position="center"
                    dy={16} // This moves the second label down by 16px (adjust as needed)
                    style={{
                      fontSize: '12px',
                      fill: '#111',
                    }}
                  />
                </Pie>
                <Tooltip content={CustomTooltip} />
              </PieChart>
            </ResponsiveContainer>
            {filteredProjectsChartData &&
              filteredProjectsChartData.map((entry, index) => (
                <Box key={index}>
                  <Box
                    component={'span'}
                    sx={{
                      width: 10,
                      height: 10,
                      backgroundColor: entry.bgColor,
                      display: 'inline-block',
                      mr: 1,
                    }}
                  ></Box>
                  {entry.name}
                </Box>
              ))}
          </Box>
        ) : (
          <Stack
            justifyContent={'center'}
            alignItems={'center'}
            direction={{ sm: 'column', lg: 'column', xl: 'row' }}
          >
            <PlaceholderPieChart
              width={140}
              height={197}
              innerRadius={44}
              outerRadius={60}
              emptyChartData={emptyChartData}
              placeholderText={t('DASHBOARD.no_data')}
            />
            <Box sx={{ maxWidth: '250px' }} />
          </Stack>
        )}
      </Box>
    </>
  )
}

export default RemoteWidget
